// frontend-teach/src/components/Lesson/Grid.js
import React from 'react';
import './Grid.css';

const Grid = ({ children, isDashboard }) => {
    const gridStyle = {
        columnGap: isDashboard ? '35px' : '75px',
        gridTemplateColumns: isDashboard ? 'repeat(auto-fill, 300px)' : 'repeat(auto-fill, 357px)'
    };

    return <div className="grid" style={gridStyle}>{children}</div>;
};

export default Grid;
