export function sortDataArray(dataArray) {
    const compareData = (a, b) => {
      const ageGroupNameComparison = a.age_group_entity.name.localeCompare(b.age_group_entity.name, "he");
      if (ageGroupNameComparison !== 0) {
        return ageGroupNameComparison;
      } else {
        return a.number - b.number;
      }
    };
  
    return dataArray.slice().sort(compareData);
  }
  const dayOrder = {
    Sunday: 1,
    Monday: 2,
    Tuesday: 3,
    Wednesday: 4,
    Thursday: 5,
    Friday: 6,
    Saturday: 7,
};

  export function sortStudyPeriodsByTime(arr) {
    return arr.slice().sort((a, b) => {
 
          return a.start_time.localeCompare(b.start_time);
      
  });
  }