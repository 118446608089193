
import './App.css';
import './css/box-style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRouter from './AppRouter';
import { GlobalProvider } from './components/store';
import { GoogleLogin, GoogleOAuthProvider ,useGoogleLogin } from '@react-oauth/google';

function App() {


  return (
    <div className=''>
      <GlobalProvider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENTID}>

        <AppRouter />
        </GoogleOAuthProvider>

      </GlobalProvider>

    </div>
  );
}

export default App;

