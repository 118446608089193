import React, { useState, useEffect } from "react";


const FormGenerator = ({ inputs, onSubmit }) => {
    const [fields, setFields] = useState([]);

    useEffect(() => {
        setFields(inputs.map((input, i) => {
            return (
                <div key={i} className="form-group">
                    <label htmlFor={input.name}>{input.name}:</label>
                    <input
                        className=" form-control"
                        type={input.type}
                        name={input.name}
                        placeholder={input.placeholder}
                        value={input.value}
                        onChange={(e) => {
                            input.onChange(e);
                        }}
                        required={input.required}
                    />
                </div>
            );
        }));
    }, [inputs]);

    return (
        <form onSubmit={onSubmit} className="mx-auto w-50 ">
            {fields}

            <div className="">
                <button type="submit" className="btn btn-primary mt-3  w-100">Submit</button>
            </div>
        </form>
    );
};

export default FormGenerator;