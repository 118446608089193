import React, { useState } from "react";
import CubeDetails from "./CubeDetails";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Rating from './Rating';
// import EmojiComponent from '../EmojiComponent ';
import "./CubeDetails.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EmptyCube from '../Cube/emptyCube';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, FreeMode, Mousewheel } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const CubePreview = (params) => {
  const nav = useNavigate();
  const {
    CubeAr,
    setPage,
    page,
    doApi,
    type,
    setAr,
    total_size,
    cubeIds,
    setCubeIds,
    setLoading,
    loading,
    priorityQube,
    sysLessonId,
  } = params;

  const [flag, setFlag] = useState([]);
  useEffect(() => {  //why we need it ?(if we need it- fix the doApi with priritycubeid param )
    // if (page * 4 < total_size + 4 || page >= 2)
    if (priorityQube) {

      const priorityItem = CubeAr.find(item => item.id === priorityQube.cube_id);
      const index = cubeIds.findIndex(c => c.Cube.id == priorityQube.cube_id)
      if (index == -1) {
        cubeIds.push({ Cube: priorityItem })
      }
    }
    if (sysLessonId)
      doApi(type, sysLessonId, setAr, page, 10000, setLoading, priorityQube);
  }, [page])


  const handleNavigate = () => {
    nav(`/user/create_new_cube/1/${type}`, {
      state: { title: "הוספת קוביה" },
    });
  };

  return (
    // <div className='container-fluid'>
    //     {/* <div className='d-flex align-items-center'>

    //         <button
    //             onClick={() => {
    //                 setPage(prev => prev - 1 >= 0 ? prev - 1 : 0);
    //             }}
    //             className='col float-start btn'

    //         ><svg xmlns="http://www.w3.org/2000/svg" width="17" height="32" viewBox="0 0 17 32" fillRule="none">
    //                 <path d="M1.15291 28.524C0.813039 28.8847 0.789049 29.4402 1.09653 29.8296L1.39231 30.2041C1.77088 30.6835 2.48647 30.7153 2.90491 30.2714L15.8728 16.5125C16.2318 16.1316 16.2357 15.5377 15.8817 15.1512L3.09528 1.19131C2.68269 0.740854 1.96676 0.761485 1.58195 1.23492L1.2813 1.60482C0.968749 1.98936 0.985463 2.54521 1.32057 2.91109L12.5074 15.1249C12.8614 15.5114 12.8575 16.1052 12.4985 16.4862L1.15291 28.524Z" fill="#4255A1" />
    //             </svg> </button>
    //     </div> */}
    //     <div>
    //         {!loading ?
    //             <div className='a container' style={{ maxWidth: "90vw" }}>

    //                 {/* <div className='row cube_scroll' style={{overflow: "scroll", flexWrap:"nowrap", width:"90vw", margin:"auto"}}> */}
    //                 <Slider {...settings}>

    //                     {CubeAr.map((cube, index) => ( 
    //                         <div>{index}</div>                                
    //                         // <CubeDetails key={index} Cube={cube} cubeIds={cubeIds} setCubeIds={setCubeIds} flag={flag} setFlag={setFlag} page={page} priorityQube={priorityQube}/>
    //                     ))}
    //                 </Slider>
    //                 {/* </div> */}
    //             </div>
    //             :
    //             <h1>loading...</h1>}

    //     </div>
    //     {/* <div className='d-flex align-items-center '>

    //         <button
    //             onClick={() => {
    //                 setPage((page + 1) * 4 <= total_size + 3 ? page + 1 : page);
    //             }}
    //             className='col float-end btn'
    //         // style={{
    //         //     backgroundImage: 'url(https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-left-01-512.png)',
    //         //     backgroundRepeat: "no-repeat",
    //         //     backgroundSize: "",
    //         //     backgroundPosition: "center",
    //         //     // height: "150px",


    //         // }}
    //         ><svg xmlns="http://www.w3.org/2000/svg" width="17" height="31" viewBox="0 0 17 31" fillRule="none">
    //                 <path d="M15.8982 28.2549C16.2426 28.6166 16.2669 29.1773 15.9551 29.5681L15.6653 29.9312C15.287 30.4054 14.5774 30.437 14.1594 29.9981L1.19797 16.3877C0.834185 16.0057 0.830221 15.4065 1.18892 15.0189L13.969 1.20958C14.3811 0.764317 15.091 0.784869 15.4756 1.2532L15.7701 1.61179C16.0871 1.99776 16.0703 2.5588 15.7306 2.92576L4.56319 14.9928C4.20451 15.3804 4.20847 15.9797 4.57225 16.3617L15.8982 28.2549Z" fill="#4255A1" />
    //             </svg>
    //         </button>
    //     </div> */}

    // </div>
    <div>
      <div className='d-flex justify-content-end mx-2'>

        {/* <p className='mx-3 lead fw-bold'>{page * 4 < total_size ? page * 4 : total_size}/{total_size}</p> */}
        <Link className="nav-link " to={`/user/create_new_cube/${sysLessonId}/${type}`}>
          <span style={
            {
              fontFamily: " Noto Sans Hebrew",
              fontSize: "17px",
              fontStyle: "normal",
              fontWeight: 300,
              color: "#4255A1",
              lineHeight: "172%",/* 22.36px */
              paddingLeft: "10px"

            }

          }>הוספת קוביה</span>
          {/* <EmojiComponent emojiName={"heavy_plus_sign"} /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fillRule="none">
            <path d="M6.74902 13.1776C3.43926 13.1776 0.749023 10.2698 0.749023 6.69234C0.749023 3.11486 3.43926 0.207031 6.74902 0.207031C10.0588 0.207031 12.749 3.11486 12.749 6.69234C12.749 10.2698 10.0588 13.1776 6.74902 13.1776ZM6.74902 1.11196C3.90251 1.11196 1.58623 3.61559 1.58623 6.69234C1.58623 9.76909 3.90251 12.2727 6.74902 12.2727C9.59554 12.2727 11.9118 9.76909 11.9118 6.69234C11.9118 3.61559 9.59554 1.11196 6.74902 1.11196Z" fill="#4255A1" />
            <path d="M8.98138 7.14321H4.51626C4.28742 7.14321 4.09766 6.93809 4.09766 6.69074C4.09766 6.4434 4.28742 6.23828 4.51626 6.23828H8.98138C9.21021 6.23828 9.39998 6.4434 9.39998 6.69074C9.39998 6.93809 9.21021 7.14321 8.98138 7.14321Z" fill="#4255A1" />
            <path d="M6.7472 9.55933C6.5181 9.55933 6.32812 9.35421 6.32812 9.10686V4.28059C6.32812 4.03324 6.5181 3.82812 6.7472 3.82812C6.97629 3.82812 7.16627 4.03324 7.16627 4.28059V9.10686C7.16627 9.35421 6.97629 9.55933 6.7472 9.55933Z" fill="#4255A1" />
          </svg>
        </Link>
      </div>
      <div className="pb-4">
      {CubeAr.length < 1 ? (<div>
        <EmptyCube systemLesson={sysLessonId} type={type}></EmptyCube>
      </div>) : ("")}
      {/* {CubeAr.length > 0 && <Swiper
        dir='rtl'
        slidesPerView={5}
        spaceBetween={15}
        freeMode={true}
        navigation={true}
        mousewheel={true}
        modules={[Navigation, Pagination, FreeMode, Mousewheel]}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          800: {
            slidesPerView: 2,
            spaceBetween: 20,
          },

          1030: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1400: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1680: {
            slidesPerView: 5,
            spaceBetween: 20,
          }
        }}
        className="mySwiper"
      > */}
        {CubeAr.map((cube, index) => (
          // <SwiperSlide key={index}>
            <CubeDetails key={index} Cube={cube} cubeIds={cubeIds} setCubeIds={setCubeIds} flag={flag} setFlag={setFlag} page={page} priorityQube={priorityQube} />
          // </SwiperSlide>
        ))}

      {/* </Swiper> */}

      </div>
    </div>
  )
}

export default CubePreview
