//frontend-teach\src\Task\Tasks.jsx
import React, { useState, useEffect } from 'react';
import TodoItem from './TodoItem';
import AddTodo from './AddTodo';
import useTodoApi from './useTasks';
import './Tasks.css';
import taskSquare from '../images/task square.svg';

function TasksLesson({ classLesson }) {
  console.log('\n\n\n classLesson', classLesson, '\n\n\n ');
  const user = ""
  const { tasks, createTask, updateTask, deleteTask, fetchTasks } = useTodoApi();

  useEffect(() => {
    if (classLesson) {
      fetchTasks({ classLesson });
    }
  }, [classLesson]);

  const handleAddTodo = task => {
    console.log('\n\n\n  tasks[0]', tasks[0].classLesson.user.id, '\n\n\n ');

    fetchTasks({ classLesson });
    createTask(task, tasks[0].classLesson.user.id, classLesson);
    console.log('\n\n\n task', task, '\n\n\n ');

  }

  const handleEditTodo = (id, newtask, done) => {
    updateTask(id);
  }

  const handleDeleteTodo = id => {
    deleteTask(id);
  }

  const handleAllTasksClick = () => {
    fetchTasks();
  }



  return ( // Moved inside the function scope
    <div className="taskContainer2">
      <div class="item-1">
        <img className="img" src={taskSquare}></img>
        <span className="title-1">משימות</span>
      </div>
      <div class="item-2" >
        <span className='title-2'>בהקדם</span>
        {tasks.map((todo, index) => {
          return (
            <TodoItem key={todo.id} todo={todo} onEdit={handleEditTodo} onDelete={handleDeleteTodo} />
          );
        })}


        <div className='Addtodo' >
          <AddTodo onAdd={handleAddTodo} className="form" />
        </div>
        <div className='line'><svg width="195" height="2" viewBox="0 0 195 2" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path id="Line 32" d="M194.476 1.07422L0.514038 1.07422" stroke="#000000" stroke-opacity="0.4" />
        </svg>
        </div>
      </div>





      {/* <div class="item-4" >
        <span className='title-2'>אפשר לחכות עם זה</span>
        {tasks.map((todo, index) => {
          return (
            <TodoItem key={todo.id} todo={todo} onEdit={handleEditTodo} onDelete={handleDeleteTodo} />
          );
        })}
        <div className='Addtodo'>
          <AddTodo onAdd={handleAddTodo} className="form" />
        </div>

      </div> */}
    </div>
  );
}

export default TasksLesson;
