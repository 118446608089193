import Popup from 'reactjs-popup';
import { ClipLoader } from "react-spinners";

const PopupLoader = ({color, loading, size, text}) => {

   return(
       <Popup open={true} modal={true} position="center" overlayStyle={{ background: '#263343da' }}>
            <div className=''>
                <p><b className='text-white'>{text}</b></p>
                <ClipLoader
                    color={color}
                    loading={loading}
                    size={size}
                />
            </div>
        </Popup>
   )
};

export default PopupLoader;