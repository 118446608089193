import React, { useState } from 'react';
import FormGenerator from './FormGenerator';
import { BASE_URL, makeRequest } from '../api_service/api_func';
import { useNavigate } from 'react-router-dom';
// import useTokenValidation from '../hooks/useToken';
// import { toast } from 'react-toastify';
import AuthComponent from './Auth';

const SignUpComponent = () => {
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();
  // const validToken = useTokenValidation();


  const handleRole = (e) => {

    setRole(e.target.value);

  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const user = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      roles: role === "" ? "user" : role,
    }
    try {
  
      await makeRequest('POST', `${BASE_URL}/user/register`, user);
      alert("משתמש הוסף בהצלחה");
      navigate("/");
    
    } catch (error) {
    
      alert(error);
    }


    setFirst_name('');
    setLast_name('');
    setEmail('');

  };

  const inputs = [
    {
      type: "text",
      name: "שם פרטי",
      placeholder: "Your first name",
      value: first_name,
      onChange: (event) => {
        setFirst_name(event.target.value);
      },
      required: true
    },
    {
      type: "text",
      name: "שם משפחה",
      placeholder: "Your Last name",
      value: last_name,
      onChange: (event) => {
        setLast_name(event.target.value);
      },
      required: true
    },
    {
      type: "email",
      name: "כתובת מייל",
      placeholder: "Your Email",
      value: email,
      onChange: (event) => {
        setEmail(event.target.value);
      },
      required: true
    },

  ];

  return (
    <div className='container vh-100'>
      <AuthComponent />
      <h2 className='text-center'>רישום למערכת</h2>
      <div className='w-50 mx-auto'>

        <label className=' '  >תפקיד:</label>
        <select
          onChange={handleRole}
          className='form-control '>
          <option value="super_admin">מנהל מערכת</option>
          <option value="admin">מנהל מוסד</option>
          <option value="user">מורה</option>
        </select>

      </div>
      <FormGenerator inputs={inputs} onSubmit={handleSubmit} />

    </div>
  );
};

export default SignUpComponent;