//frontend-teach\src\Task\Tasks.jsx
import React, { useState, useEffect } from 'react';
import TodoItem from './TodoItem';
import AddTodo from './AddTodo';
import useTodoApi from './useTasks';
import './Tasks.css';
import taskSquare from '../images/task square.svg';

function TasksUser({ userId }) {
  const { tasks, createTask, updateTask, deleteTask, fetchTasks } = useTodoApi();
  useEffect(() => {
    if (userId) {
      fetchTasks({ userId });
      console.log('\n\n\n tasks0', tasks, '\n\n\n ');

    }
  }, [userId]);

  const handleAddTodo = task => {
    createTask(task);

  }

  const handleEditTodo = (id, task, isDone) => {
    updateTask(id, task, isDone);
  }


  const handleDeleteTodo = id => {
    deleteTask(id);
  }

  const handleAllTasksClick = () => {
    fetchTasks();
  }
  const firstFourTasks = tasks.slice(0, 4);
  const secondFourTasks = tasks.slice(4, 8);
  console.log('\n\n\n firstFourTasks', firstFourTasks, '\n\n\n ');

  return ( // Moved inside the function scope
    <div className="taskContainer">
      <div class="item-1">
        <img src={taskSquare}></img>
        <span className="title-1">משימות</span>
      </div>
      <div class="item-2" >
        <span className='title-2'>בהקדם</span>
        {firstFourTasks.map((todo, index) => {


          return (
            <TodoItem key={todo.id} todo={todo} onEdit={handleEditTodo} onDelete={handleDeleteTodo} />
          );
        })}



      </div>





      <div class="item-4" >
        <span className='title-2'>אפשר לחכות עם זה</span>
        {secondFourTasks.map((todo, index) => {
          return (
            <TodoItem key={todo.id} todo={todo} onEdit={handleEditTodo} onDelete={handleDeleteTodo} />
          );
        })}
        {/* <div className='Addtodo'>
          <AddTodo onAdd={handleAddTodo} className="form" />
        </div> */}

      </div>
    </div>
  );
}

export default TasksUser;

