import axios from 'axios';
import { EMAIL_USER, USER, Instituion_id } from '../components/localstorage/store_name';
import { sortDataArray, sortStudyPeriodsByTime } from '../general/sotrDataArray';
import { useNavigate } from 'react-router-dom';
import { setValuesFromObject } from '../general/setValuesFromObject';

// export const BASE_URL = 'http://localhost:3002';
export const BASE_URL = 'https://teach.org.il/api';
// import axios from "axios";
// import {
//   EMAIL_USER,
//   USER,
//   Instituion_id,
// } from "../components/localstorage/store_name";
// import {
//   sortDataArray,
//   sortStudyPeriodsByTime,
// } from "../general/sotrDataArray";
// import { useNavigate } from "react-router-dom";
// import { setValuesFromObject } from "../general/setValuesFromObject";

// export const BASE_URL = "http://localhost:3002";
// export const BASE_URL = 'http://backend:3002';

export const makeRequest = async (
  method,
  url,
  data,
  contentType = "application/json"
) => {
  const YOUR_TOKEN =
    localStorage.getItem("access_token")?.split('"').join("") || null;

  const headers = {
    "Content-Type": contentType,
    Authorization: `Bearer ${YOUR_TOKEN}`,
  };

  try {
    const response = await axios({
      method,
      url,
      headers,
      data,
    });

    return response.data;
  } catch (error) {
    console.error("Error making request:", error);
    throw error;
  }
};

export const doApiFetchAzureToken = async (token) => {
  try {
    const requestBody = {
      token,
      id: localStorage.user,
    };
    console.log("localStorage", localStorage.user);
    const res = await makeRequest("POST", `${BASE_URL}/microsoft`, requestBody);
    console.log("Response from azure endpoint:", res);
    return res; // Return the response
  } catch (error) {
    console.error("Error fetching data from azure endpoint:", error);
    throw error; // Throw the error to handle it in the calling function
  }
};

export const doApiFetchUser = async (setUser) => {
  try {
    const email = localStorage.getItem(EMAIL_USER)?.replace(/"/g, "") || null;
    if (email) {
      const res = await makeRequest("GET", `${BASE_URL}/user/${email}`, {});
      setUser({ ...res });
      localStorage.setItem(USER, res.id);
    } else {
      console.log("No email found in localStorage");
    }
  } catch (error) {
    // If the token is invalid or the request fails, setTokenValid to false
    console.error(error);
  }
};
// Function to update user details
export const doApiUpdateUser = async (id, userData) => {
  try {
    const filteredDto = Object.keys(userData)
      .filter(
        (key) =>
          userData[key] !== undefined &&
          userData[key] !== null &&
          userData[key] !== ""
      )
      .reduce((obj, key) => {
        obj[key] = userData[key];
        return obj;
      }, {});

    // Replace with your API endpoint and the appropriate method to update user
    if (Object.keys(filteredDto).length !== 0) {
      const res = await makeRequest(
        "PUT",
        `${BASE_URL}/user/${id}`,
        filteredDto
      );

      return await res.json();
    }
  } catch (error) {
    console.error("Error updating user:", error);
  }
};

export const doApiChangePassword = async (userId, oldPassword, newPassword) => {
  try {
    const body = { oldPassword, newPassword };

    return await makeRequest(
      "PUT",
      `${BASE_URL}/user/change-password/${userId}`,
      body
    );
  } catch (error) {
    console.error("Error changing password:", error);
    throw error;
  }
};

// function extractNamesFromArray(arrayOfObjects) {
//   return arrayOfObjects.map(item => item.name);
// }
// const compareHebrew = (a, b) => a.localeCompare(b, "he");

export const doApiGetLayersByInstitution = async (
  institution_id,
  setInstitution
) => {
  const res = await makeRequest(
    "GET",
    `${BASE_URL}/ageGroup/${institution_id}`,
    {}
  );

  // const only_names_age_group = extractNamesFromArray(res);
  // Custom comparison function for sorting Hebrew characters
  // const sortedHebrewArray = only_names_age_group.slice().sort(compareHebrew);
  setInstitution([...res]);
};

function extractClassNamesFromArray(arrayOfObjects) {
  return arrayOfObjects.map((item) => {
    const newData = {
      id: item.id,
      name: item.age_group_entity.name,
      number: item.number,
    };
    return newData;
  });
}

export const doApiGetClassesByInstitution = async (
  institution_id,
  setClasses
) => {
  const res = await makeRequest(
    "GET",
    `${BASE_URL}/classes/by-instituion/${institution_id}`,
    {}
  );
  const sort_data = sortDataArray(res);

  const onlyNamseAndNUmbers = extractClassNamesFromArray(sort_data);
  setClasses([...onlyNamseAndNUmbers]);
};

export const doApiGetClassesByInstitutionAndLayers = async (
  institution_id,
  layerId,
  setClasses
) => {
  const res = await makeRequest(
    "GET",
    `${BASE_URL}/classes/by-instituion/${institution_id}/byAgeGroupId/${layerId}`,
    {}
  );
  const sort_data = sortDataArray(res);

  const onlyNamseAndNUmbers = extractClassNamesFromArray(sort_data);
  setClasses([...onlyNamseAndNUmbers]);
  // console.log(res);
  // setClasses([[...res]])
};

export const doApiGetAllUsers = async (setUsers) => {
  const res = await makeRequest("GET", `${BASE_URL}/user/allUsers`, {});
  setUsers([...res]);
};
function removeDuplicateTimes(data) {
  const uniqueTimes = {};
  const result = [];

  data.forEach((item) => {
    const timeKey = `${item.start_time}-${item.end_time}`;
    if (!uniqueTimes[timeKey]) {
      uniqueTimes[timeKey] = true;
      result.push({
        id: item.id,
        start_time: item.start_time,
        end_time: item.end_time,
      });
    }
  });
  let data_sorted = result.sort((a, b) =>
    a.start_time.localeCompare(b.start_time)
  );
  return data_sorted;
}

export const doApiGetSchedulerByInstitution = async (
  institution_id,
  setScheduler,
  setScheduleTime,
  setTableData
) => {
  const res = await makeRequest(
    "GET",
    `${BASE_URL}/study-periods/${institution_id}`,
    {}
  );
  const sortedStudyPeriods = sortStudyPeriodsByTime(res);

  setScheduler([...sortedStudyPeriods]);

  setScheduleTime(removeDuplicateTimes(res));
};

export const getAllStudyPeriods = async (allStudy, setAllStudyPeriod) => {
  try {
    const res = await makeRequest("GET", `${BASE_URL}/study-periods`, {});
    setAllStudyPeriod([...res]);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const doApiGetStudyPeriodByUser = async (user_id, setStudyPeriod) => {
  const res = await makeRequest(
    "GET",
    `${BASE_URL}/study-period-class/${user_id}`,
    {}
  );
  setStudyPeriod([...res]);
};

export const doApiUpdateStudyPeriodByUser = async (
  study_period_class,
  body
) => {
  // console.log(body);
  const res = await makeRequest(
    "PUT",
    `${BASE_URL}/study-period-class/${study_period_class}`,
    body
  );
  // console.log(res);
  return res;
};

export const doApiSetScheduler = async (data_body) => {
  try {
    await makeRequest("POST", `${BASE_URL}/study-period-class/`, data_body);

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const doApiGetSystemLessonGoalBySyslessonId = async (
  sysLessonId,
  set_system_lesson_Golas
) => {
  try {
    const res = await makeRequest(
      "GET",
      `${BASE_URL}/system-lesson-goals/by-system-lesson/${sysLessonId}`,
      {}
    );
    set_system_lesson_Golas([...res]);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const doApiGetBooksByInstitution = async (institustion_id, setBooks) => {
  try {
    const res = await makeRequest(
      "GET",
      `${BASE_URL}/books-institution/ByInstitustion/${institustion_id}`,
      {}
    );
    // console.log(res);
    setBooks([...res]);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const doApiGetField_of_knowledge_instituionByInstitution = async (
  institution_id,
  setFieldOfKnowledge
) => {
  const res = await makeRequest(
    "GET",
    `${BASE_URL}/field-of-knowledge-instituion/by-instituion/${institution_id}`,
    {}
  );
  // console.log(res);
  setFieldOfKnowledge([...res]);
};

export const doApiGetCubeById = async (cube_id, setValuesForUpdate) => {
  const res = await makeRequest("GET", `${BASE_URL}/cube/${cube_id}`, {});
  console.log(res);
  setValuesFromObject(res, setValuesForUpdate);
};
export const doApiGetCubeByIdRead = async (cube_id) => {
  return await makeRequest("GET", `${BASE_URL}/cube/${cube_id}`, {});
};

export const doApiGetCubeByType = async (
  type_id,
  setCubeAr,
  page,
  pageSize,
  setLoading,
  priorityCubeId
) => {
  try {
    const priorityCube = priorityCubeId ? priorityCubeId.cube_id : "";

    setLoading(true);
    const res = await makeRequest(
      "GET",
      `${BASE_URL}/cube/by_type/${type_id}?page=${
        page || 1
      }&pageSize=${pageSize}&priorityCubeId=${priorityCube}`,
      {}
    );
    // ar.length

    function sortByCreatedAt(a, b) {
      const createdAtA = new Date(a.created_at);
      const createdAtB = new Date(b.created_at);

      return createdAtB - createdAtA;
    }

    if (res.length > 0) {
      // Keep the first element separate
      const firstElement = res[0];

      // Extract the range from index 1 to 3
      let rangeToSort = res.slice(1, 4);

      // Sort the extracted range
      rangeToSort.sort(sortByCreatedAt);

      // Combine the sorted range with the rest of the array
      const sortedRes = [firstElement, ...rangeToSort, ...res.slice(4)];

      setCubeAr([...sortedRes]);
    }

    setLoading(false);
  } catch (error) {
    console.log(error);
  }
};

export const doApiGetCubeByTypeAndSysLesson = async (
  type_id,
  sysLessonId,
  setCubeAr,
  page,
  pageSize,
  setLoading,
  priorityCubeId
) => {
  try {
    const priorityCube = priorityCubeId ? priorityCubeId.cube_id : "";

    setLoading(true);
    const res = await makeRequest(
      "GET",
      `${BASE_URL}/cube/by_type/${type_id}/bySystemLesson/${sysLessonId}?page=${
        page || 1
      }&pageSize=${pageSize}&priorityCubeId=${priorityCube}`,
      {}
    );
    // ar.length

    function sortByCreatedAt(a, b) {
      const createdAtA = new Date(a.created_at);
      const createdAtB = new Date(b.created_at);

      return createdAtB - createdAtA;
    }

    if (res.length > 0) {
      // Keep the first element separate
      const firstElement = res[0];

      // Extract the range from index 1 to 3
      let rangeToSort = res.slice(1, 4);

      // Sort the extracted range
      rangeToSort.sort(sortByCreatedAt);

      // Combine the sorted range with the rest of the array
      const sortedRes = [firstElement, ...rangeToSort, ...res.slice(4)];

      setCubeAr([...sortedRes]);
    }

    setLoading(false);
  } catch (error) {
    console.log(error);
  }
};

export const doApiGetAmountCubeByType = async (type_id, setCount) => {
  try {
    const res = await makeRequest(
      "GET",
      `${BASE_URL}/cube/countByType/${type_id}`,
      {}
    );

    setCount(res.count);
  } catch (error) {
    console.log(error);
  }
};

export const doApiSetCubeGoal = async (cubeGoalBody, id_cube, sysLessonId) => {
  try {
    for (const key in cubeGoalBody) {
      if (cubeGoalBody.hasOwnProperty(key)) {
        const cubeGoal = {
          systemLessonGoal: key,
          cube: id_cube,
          systemLesson: sysLessonId,
        };
        if (cubeGoalBody[key]) {
          const res = await makeRequest("POST", `${BASE_URL}/cube/cubeGoal`, {
            ...cubeGoal,
          });
          console.log(res);
        }
      }
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const doApiSetCube = async (data_body, cubeGoalBody) => {
  try {
    // console.log(data_body,data);
    const res = await makeRequest("POST", `${BASE_URL}/cube/`, data_body);
    console.log(cubeGoalBody);
    doApiSetCubeGoal(cubeGoalBody, res.id, res.id_system_lesson);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const doApiSetCLassLesson = async (data_body) => {
  try {
    // console.log(data_body,data);
    const res = await makeRequest(
      "POST",
      `${BASE_URL}/class-lesson/`,
      data_body
    );
    console.log(res);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const doApiGetAllCLassLesson = async (
  start,
  end,
  setAllLesson,
  setAllAbsence
) => {
  const startDateStr = new Date(start).toLocaleString();
  const endDateStr = new Date(end).toLocaleString();
  try {
    const instituion_id = localStorage.getItem(Instituion_id);
    const user_id = localStorage.getItem(USER);
    // console.log(data_body,data);
    const res = await makeRequest(
      "POST",
      `${BASE_URL}/class-lesson/toCurrentUser`,
      {
        startDate: startDateStr,
        endDate: endDateStr,
        instituion_id: instituion_id,
      }
    );
    setAllAbsence([...res.absence]);
    setAllLesson([...res.allLessons]);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const doApiGetCLassLesson = async (id) => {
  try {
    // console.log('\n\n\n id1', id, '\n\n\n ');

    const res = await makeRequest("GET", `${BASE_URL}/class-lesson/${id}`);
    // console.log('\n\n\n xxx', res, '\n\n\n ');

    return res;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const addEnrichmentLessonToClass = async (body) => {
  try {
    const res = await makeRequest(
      "POST",
      `${BASE_URL}/class-lesson/addEnrichmentLessonToClass`,
      body
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteEnrichmentLessonOfClass = async (classLessonId) => {
  // try {
  return await makeRequest(
    "DELETE",
    `${BASE_URL}/class-lesson/deleteEnrichmentLessonOfClass/${classLessonId}`
  );
  // } catch (error) {

  // }
};

export const doApiGetCubeGoalByCubeId = async (cube_id, setCheckedItems) => {
  try {
    // console.log(data_body,data);
    const res = await makeRequest(
      "GET",
      `http://${BASE_URL}/cube/cubeGoal/${cube_id}`,
      {}
    );
    let resultObject = {};
    for (const item of res) {
      const systemLessonGoalId = item.systemLessonGoal.id;
      resultObject[systemLessonGoalId] = true;
    }
    setCheckedItems(resultObject);

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const doApiGetCubeByCubeGoalId = async (cube_goal_id) => {
  try {
    // console.log(data_body,data);
    const res = await makeRequest(
      "GET",
      `http://${BASE_URL}/cube/cubeGoal/byCubeGoal/${cube_goal_id}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const doApiDeleteStudyPeriodClassByID = async (StudyPeriodClassID) => {
  try {
    const res = await makeRequest(
      "DELETE",
      `${BASE_URL}/study-period-class/${StudyPeriodClassID}`,
      {}
    );
    console.log(res);
    alert("Delete Item Success");
    window.location.reload();
  } catch (error) {
    console.log(error);
  }
};

export const UploadFiles = async (formData) => {
  try {
    console.log("UploadFiles");
    const res = await makeRequest(
      "POST",
      `${BASE_URL}/upload`,
      formData,
      "file.type"
    );
    return res;
  } catch (error) {
    console.log("error");
    return error;
  }
};

export const createMessage = async (message) => {
  try {
    return await makeRequest("POST", `${BASE_URL}/chat`, { message: message });
  } catch (error) {
    console.log("error");
    return error;
  }
};

export const getUserMessages = async () => {
  const res = await makeRequest("GET", `${BASE_URL}/chat/user_messages`);
  console.log(res);
};

export const reOrderClassLesson = async (classLessonOrderUpdate) => {
  return await makeRequest(
    "POST",
    `${BASE_URL}/class-lesson/updateOrder`,
    classLessonOrderUpdate
  );
};

export const rOrderGroup = async (classLessonOrderUpdate) => {
  return await makeRequest(
    "POST",
    `${BASE_URL}/class-lesson/updateGroupOrder`,
    classLessonOrderUpdate
  );
};

export const createSchedulerLessons = async (userId, date) => {
  return await makeRequest("POST", `${BASE_URL}/yearly-plan/user`, {
    userId,
    date,
  });
};

export const reOrderClassLessonByReOrderSkills = async (
  classLessonOrderUpdate
) => {
  return await makeRequest(
    "POST",
    `${BASE_URL}/class-lesson/updateOrderBySkillOrder`,
    classLessonOrderUpdate
  );
};

export const getClasesByUser = async (userId, setClases) => {
  const res = await makeRequest(
    "GET",
    `${BASE_URL}/class-users/byUserId/${userId}`
  );
  return setClases(res);
};

export const getClassLessinByClass = async (body, setClassLessons) => {
  try {
    const res = await makeRequest(
      "POST",
      `${BASE_URL}/class-lesson/byClassId`,
      body
    );
    return setClassLessons(res);
  } catch (error) {}
};

export const updateClassLesson = async (id, classLessonUpdate) => {
  try {
    const res = await makeRequest(
      "PUT",
      `${BASE_URL}/class-lesson/update/${id}`,
      classLessonUpdate
    );
  } catch (error) {}
};

export const updateLessonDone = async (id, done) => {
  try {
    const res = await makeRequest(
      "GET",
      `${BASE_URL}/class-lesson/lessonDone/${id}/${done}`
    );
  } catch (error) {}
};

export const addAbsenceToUserClass = async (absence) => {
  try {
    const res = await makeRequest(
      "POST",
      `${BASE_URL}/yearly-plan/create-absence`,
      absence
    );
  } catch (error) {}
};

export const addAbsenceDayToUserClass = async (absence) => {
  try {
    const res = await makeRequest(
      "POST",
      `${BASE_URL}/yearly-plan/create-absence/day`,
      absence
    );
  } catch (error) {}
};

export const doApiCreateEnrichLesson = async (enrichLesson) => {
  try {
    const res = await makeRequest(
      "POST",
      `${BASE_URL}/system-lesson`,
      enrichLesson
    );
    return res;
  } catch (error) {}
};

export const doApiCreateSystemLessonGoals = async (sysLessonGoals) => {
  try {
    const res = await makeRequest(
      "POST",
      `${BASE_URL}/system-lesson-goals/multi`,
      sysLessonGoals
    );

    return res;
  } catch (error) {}
};

export const doApiGetAllClassLessonDateInUser = async (userId) => {
  try {
    return await makeRequest("GET", `${BASE_URL}/class-lesson/allClassLessonByUserId/${userId}`,
    )
  } catch (error) {
    
  }
}
