import axios from 'axios'
import { makeRequest } from '../../api_service/api_func'
import { BASE_URL } from '../../api_service/api_func'
export const makeGoogleRequest = async (method, url, data,token) => {
      
        const headers = {
          // 'Content-Type': 'application/json',
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        };
    
        try {  
      
          const response = await axios({
            method,
            url,
            headers,
            data,
          });
      
          return response.data;
        } catch (error) { 
          console.error('Error making request:', error);
          throw error;
        }
      };

export const getCalendar = async(calendar_id) => {
    return await makeGoogleRequest('GET', `https://www.googleapis.com/calendar/v3/calendars/$key?{calendar_id}`)
}

export const getCalendarList = async(token) => {
    return await makeGoogleRequest('GET', `https://www.googleapis.com/calendar/v3/users/me/calendarList?key=${process.env.REACT_APP_API_KEY}`,null,token)
}

export const getAuth = async() => {
    return await makeGoogleRequest('GET', `https://www.googleapis.com/auth/calendar`)
}

export const sendGoogleAccessToken = (body) => {
  return makeRequest('POST', `${BASE_URL}/google-calendar/get-access-token`, body )
}

export const syncGoggleCalendar = async (body) => {
  return makeRequest('POST', `${BASE_URL}/google-calendar/sync-google-calendar`, body )
}

export const syncGoggleCalendarWithoutAuth = (userId) => {
  return makeRequest('GET', `${BASE_URL}/google-calendar/sync/${userId}` )
}

