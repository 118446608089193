import React, { useState, useEffect } from 'react';
import Grid from './Grid';
import SkillCard from './skillCard';
import { BASE_URL, makeRequest, reOrderClassLessonByReOrderSkills } from '../../api_service/api_func';
import { useLocation } from 'react-router-dom';
import { Draggable } from "react-drag-reorder";
import './skillCard.module.css'


const processData = (data, classId) => {
    return Object.entries(data).map(([key, value]) => {
        return {
            skillId: value.id,
            classId: classId,
            number: value.default_order,
            orginalSkillOrder: value.orginalOrder,
            title: key,
            lesson: [value.total, value.passed, value.prepared],
        };
    }).sort((a, b) => a.number - b.number);
};

const getClassAndFieldOfKnowledgeLessons = async (classId, fieldOfKnowledgeId) => {
    const url = `${BASE_URL}/class-lesson/lessons-count/${classId}/${fieldOfKnowledgeId}`;
    const result = await makeRequest('GET', url);
    console.log('\n\n\n result', result, '\n\n\n ');
    return result;
};

const postReOrderClassLessonBySkill = async (updateItems) => {
    try {
        return await reOrderClassLessonByReOrderSkills(updateItems)
        // Assuming res is the updated lessons array from the server
        // setLessons(res.sort((a, b) => a.order - b.order));
        // ... (other state updates)
    } catch (error) {
        alert('Error updating lessons:', error)
        console.error('Error updating lessons:', error.massage);
    }

}

const getDataInit = () => {

}

const SkillsSum = ({ classId, fieldOfKnowledgeId }) => {

    const [params, setParams] = useState()
    const location = useLocation();

    const [cardData, setCardData] = useState([]);

    const getChangedPos = (currentPos, newPos) => {
        // // ... other logic if needed
        if (currentPos == newPos) {
            return
        }

        let finish = true
        setCardData(async (prevSkills) => {
            // console.log(prevSkills);
            const updateSkills = [...prevSkills]
            const item = updateSkills.splice(currentPos, 1)[0];
            updateSkills.splice(newPos, 0, item);
            let arrayPost = []
            for (let i = 0; i < updateSkills.length; i++) {
                arrayPost.push({ id: updateSkills[i].skillId, order: i + 1 })
            }
            const updateSkill = {
                fieldOfKnolageId: params.field_of_knowledge_id,
                classId: params.classId,
                updateOrder: arrayPost
            }
            if (finish) {
                const res = await postReOrderClassLessonBySkill(updateSkill)
                finish = false
                fetchData()
            }
            finish = true
            return updateSkills;
        })

    };

    const generatListSkills = (skills) => {
        if (skills && skills.length > 0) {
            return (

                <div style={{ background: "rgba(215, 227, 248,0.2)", padding: "20px 20px" }}>
                    <div style={{ display: "flex", flexDirection: "row", minHeightheight: '375px', gap: "75px", flexWrap: "wrap", minHeight: "400px", padding: "50px", background: "white", borderRadius: '15px' }}>


                        <Draggable onPosChange={getChangedPos}>
                            {skills.map((skill, index) => (
                                <SkillCard
                                    number={skill.number}
                                    title={skill.title}
                                    lesson={skill.lesson}
                                    key={index}
                                    classId={skill.classId}
                                    skillId={skill.skillId}
                                />
                            ))}
                        </Draggable>


                    </div>
                </div >


            )
        } else { console.log('\n\n\n oooooo \n\n\n'); }
    }

    const fetchData = async () => {
        const params = location.state;

        if (params) {
            // Do something with the parameters
            classId = params.classId;
            fieldOfKnowledgeId = params.field_of_knowledge_id
            setParams(params)
            const data = await getClassAndFieldOfKnowledgeLessons(params.classId, params.field_of_knowledge_id);
            const processedData = processData(data, classId);


            setCardData(processedData);
        } else {
            console.log('No parameters received');
        }
    };

    useEffect(() => {

        fetchData();
    }, []);

    return (

        <div>

            {generatListSkills(cardData)}

        </div>
    )
};

export default SkillsSum;
