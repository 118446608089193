import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './layout.css'

const PreviewScreen = () => {
    return (
        <div className='app-container'>
   
  
        <div className='layout preview'>
     
          <div  >
  
            {/* <NavBar /> */}
            <ToastContainer
              containerId="an id"
              draggable={false}
              closeButton={true}
              newestOnTop={true}
              toastClassName="my-toast-class"
              progressClassName="my-progress-class"
              autoClose={3000}
              hideProgressBar={false}
              rtl={false}
  
            />
            <Outlet />
          </div>
        </div>
  
      </div>
    )
}
export default PreviewScreen;