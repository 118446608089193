import React, { useContext, useEffect, useState } from 'react';
import './Scheduler.css'; // You can style the Scheduler component using CSS

import { doApiFetchUser, doApiGetSchedulerByInstitution, doApiGetStudyPeriodByUser, createSchedulerLessons } from '../../api_service/api_func';
import { Link, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../store';
import { findIndexByIdStudyPeriod } from '../../general/findDataArray';
import { USER } from '../localstorage/store_name';
import EmojiComponent from '../EmojiComponent ';
import SchedulerTable from './SchedulerTable';



// const EMAIL_USER = "email_user"
const daysOfWeekHebrew = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי'];

const daysOfWeekEnglish = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",

];

const Scheduler = () => {
  // Access the global state and setGlobalData function from context
  const { globalState, setGlobalData } = useContext(GlobalContext);


  const [studyPeriod, setStudyPeriod] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [user, setUser] = useState({});
  const [userid, setUserid] = useState(localStorage.getItem(USER));
  const [instituion_id, setInstituion_id] = useState(localStorage.getItem('Instituion_id'));
  const nav = useNavigate();
  const [scheduler, setSchedule] = useState([]);
  const [scheduler_time, setScheduleTime] = useState([]);

  // let flag = false;



  useEffect(() => {
 
    // doApiFetchUser(setUser);
    doApiGetSchedulerByInstitution(instituion_id, setSchedule, setScheduleTime, setTableData);
    doApiGetStudyPeriodByUser(userid, setStudyPeriod);
    console.log(studyPeriod);

  }, []);


  return (
    <div className='container-fluid scheduler pb-3'>
      <div className='container mt-5 mb-5'>
        <div className='scheduler bg-light text-center rounded-5 shadow-lg' style={{ minHeight: "450px" }}>
          <div className='lightbox-container'>
            <img className="lightbox-logo" src="/logoTakingoff.png" alt="logo" />

          </div>
          <div style={{ paddingTop: "60px", fontSize: "18px" }}>
            שלב <span style={{ fontWeight: 'bold' }}>2</span> מתוך <span style={{ fontWeight: 'bold' }}>2</span>
          </div>

          <div >

            <h2 style={{ paddingTop: "20px" }} className='fw-bold' >בואו נבנה את המערכת שלכם</h2>
            <p style={{ fontSize: "20px" }}>לחצו על הזמנים בהם אתם מלמדים את אותו תחום דעת באותה הכיתה</p>
          </div>

          <div style={{ overflowX: "auto" }} className="d-flex justify-content-center pb-5">
            <div className="d-flex justify-content-center pb-5">

            </div>
            {scheduler.length && scheduler_time.length &&
              <SchedulerTable instituion_id={instituion_id} studyPeriod={studyPeriod} scheduler={scheduler} scheduler_time={scheduler_time} />
            }
          </div>
          <button className='btn btn-primary px-5 py-2 mb-5'
            onClick={async () => {
              const result = await createSchedulerLessons(userid, new Date().toLocaleDateString())
              nav(`/user/dashboard-teacher`)
            }}>
            סיימתם?  לחצו כאן להפקת תוכנית עבודה שנתית
          </button>
        </div>
      </div>
    </div >
  );
};

export default Scheduler;

