
export const ScoreItem = ({icon, num, text}) => {
    return(
        <div className="item d-flex justify-content-start">
            <img
                src={`/${icon}.svg`}
                alt="icon"
                className="h-7 w-7"
            />
            <div className='number'>{num}</div>
            <div className='text'>{text}</div>
        </div>
    )
}