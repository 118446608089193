import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  HeadingLevel,
  UnderlineType,
  Header,
  ImageRun,
} from "docx";

async function downloadDataAsWord(cubeArray, location) {
  if (!cubeArray || cubeArray.length === 0) {
    console.error("Cube array is empty or undefined.");
    return; // Exit the function if cubeArray is invalid
  }
  const content = [];

  const cubeTypes = ["פתיחה מסקרנת ", "הקניה", "גוף", "סיכום"];

  // Mapping of English keys to Hebrew terms
  const keyMapping = {
    title: "כותרת",
    subtitle: "כותרת משנה",
    body: "גוף",
    level_1: 'ילדי שילוב וחנ"מ',
    level_2: "רמת כיתה",
    level_3: "מצטיינים ומחוננים",
    book: "ספר וחוברת",
    start_page: "מעמוד",
    end_page: "עד עמוד",
    link: "קישור",
    file: "קובץ",
  };
  const imageBuffer = await fetchImageAsBuffer("/logoTeach.png");
  const imageRun = new ImageRun({
    data: imageBuffer,
    transformation: { width: 50, height: 25 },
  });
  const header = new Header({
    children: [
      new Paragraph({
        children: [
          imageRun,

          new TextRun({
            text: " ".repeat(200),
          }),
          new TextRun({
            text: "בס'ד", // Replace with your desired header text
            bold: true,
            size: 18,
          }),
        ],
        alignment: "both",
      }),
    ],
  });
  const title = location.state.title;
  // Add title paragraph
  content.push(
    new Paragraph({
      children: [
        new TextRun({
          text: title,
          size: 48 * 2, // Docx uses half-point measurement, so 48pt = 48*2
          color: "87cefa", // Blue color in hex format
          underline: { type: UnderlineType.SINGLE, color: "87cefa" },
        }),
      ],
      alignment: "center",
      spaceAfter: 720,
    })
  );

  //spacing
  for (let i = 0; i < 3; i++) {
    content.push(new Paragraph({}));
  }

  cubeArray.forEach((cube) => {
    if (!cube.data) {
      console.error("Cube data is undefined.");
      return; // Skip this iteration if cube data is undefined
    }
    const data = cube.data;

    // Replace the 'type' key's value with its corresponding value from cubeTypes
    data.type = cubeTypes[data.type];

    // Add cube ID
    content.push(
      new Paragraph({
        children: [new TextRun(` ${data.type}`)],
        bold: true,
        size: 32,
        heading: HeadingLevel.HEADING_1,
        thematicBreak: true,
        alignment: "end",
      })
    );
    //spacing

    // Process the keys you want
    Object.keys(keyMapping).forEach((key) => {
      const value = data[key] || " ";
      content.push(
        new Paragraph({
          children: [
            new TextRun({ text: `${keyMapping[key]}`, bold: true }),
            new TextRun({ text: `:`, bold: false }),
            new TextRun({ text: `${data[key]} `, bold: false }),
          ],
          alignment: "end",
        })
      );
    });
    for (let i = 0; i < 3; i++) {
      content.push(new Paragraph({}));
    }
  });

  const doc = new Document({
    creator: "Your App Name",
    title: "Data Export",
    description: "Exported Data from App",
    sections: [
      {
        properties: {
          rightToLeft: true,
        },
        headers: {
          default: header,
        },
        children: content,
      },
    ],
  });

  //doc.sections[0].children.push(imageParagraph);

  // Generate Word document and trigger download
  Packer.toBlob(doc).then((blob) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = location.state.title;
    a.click();
    URL.revokeObjectURL(url);
  });
}
async function fetchImageAsBuffer(imageUrl) {
  const response = await fetch(imageUrl);
  const buffer = await response.arrayBuffer();
  return buffer;
}

export { downloadDataAsWord };
