import axios from "axios";

import React, { useState } from "react";
import "./sidebar.css";
import { Link, json } from "react-router-dom";
import {
  ACCESS_TOKEN,
  EMAIL_USER,
  USERNAME,
  USER,
  ROLE,
} from "../localstorage/store_name";
import { doApiFetchAzureToken } from "../../api_service/api_func";

const Sidebar = () => {
  const [expanded, setExpanded] = useState(false);
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState(null);

  const logout = () => {
    // Clear the user's token and other user info from local storage
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(EMAIL_USER);
    localStorage.removeItem(USERNAME);
    localStorage.removeItem(USER);
    localStorage.removeItem(ROLE);

    // Use navigate to redirect to the root path
    console.log("rr", localStorage);
  };

  const toggleSidebar = () => {
    setExpanded(!expanded);
  };
  const handleMouseEnter = () => {
    setExpanded(true);
  };

  const handleMouseLeave = () => {
    setExpanded(false);
  };

  const handleIconMouseEnter = (iconId) => {
    setHoveredIcon(iconId);
  };

  const handleIconMouseLeave = () => {
    setHoveredIcon(null);
  };
  const getIconStyle = (iconId) => {
    return hoveredIcon === iconId ? "icon-hover-effect" : "";
  };

  const getSvgStrokeColor = (iconId) => {
    return hoveredIcon === iconId ? "#9100C3" : "#384459";
  };

  const handleIconClick = (iconId) => {
    setSelectedIcon(iconId);
  };

  return (
    <div className="bottomlayer">
      <div
        className={`sidebar ${expanded ? "expanded" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="toplinks">
          <div className="first-item">
            <Link className="nav-link" to="/">
              {!expanded && (
                <div className="icon">
                  {" "}
                  <img style={{ width: "30px" }} src="/logo.jpg" alt="logo" />
                </div>
              )}
              {expanded && (
                <div className="icon">
                  {" "}
                  <img
                    style={{ width: "100px" }}
                    src="/logoTeach.png"
                    alt="logo"
                  />
                </div>
              )}
            </Link>
          </div>
          <Link className="nav-link" to="/user/dashboard-teacher">
            <div
              className={`icon ${getIconStyle("icon1")} ${
                selectedIcon === "icon1" ? "icon-selected" : ""
              }`}
              onClick={() => handleIconClick("icon1")}
              onMouseEnter={() => handleIconMouseEnter("icon1")}
              onMouseLeave={handleIconMouseLeave}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 23 22"
                fill="none"
                style={{ padding: "3px", marginLeft: "3px" }}
              >
                <path
                  d="M11.3237 4.51111L11.3334 4.5"
                  stroke={getSvgStrokeColor("icon1")}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.4023 6.51111L15.4121 6.5"
                  stroke={getSvgStrokeColor("icon1")}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.24512 6.51111L7.25484 6.5"
                  stroke={getSvgStrokeColor("icon1")}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.4419 10.5111L17.4516 10.5"
                  stroke={getSvgStrokeColor("icon1")}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.20557 10.5111L5.21528 10.5"
                  stroke={getSvgStrokeColor("icon1")}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.4224 14.5111L16.4321 14.5"
                  stroke={getSvgStrokeColor("icon1")}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.22559 14.5111L6.2353 14.5"
                  stroke={getSvgStrokeColor("icon1")}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.3237 14.5L14.3828 9.96094"
                  stroke={getSvgStrokeColor("icon1")}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.75492 17.501H3.16633C1.88578 15.8295 1.12695 13.7516 1.12695 11.5C1.12695 5.97715 5.69224 1.5 11.3238 1.5C16.9553 1.5 21.5207 5.97715 21.5207 11.5C21.5207 13.7516 20.7618 15.8295 19.4813 17.501L14.8927 17.5"
                  stroke={getSvgStrokeColor("icon1")}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.3237 20.5C13.0132 20.5 14.3828 19.1569 14.3828 17.5C14.3828 15.8431 13.0132 14.5 11.3237 14.5C9.63419 14.5 8.26465 15.8431 8.26465 17.5C8.26465 19.1569 9.63419 20.5 11.3237 20.5Z"
                  stroke={getSvgStrokeColor("icon1")}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              {expanded && <span className="textspan">לוח בקרה</span>}
            </div>
          </Link>
          {/* <Link className="nav-link" to="/user/create_lesson/">
                        <div className={`icon ${getIconStyle('icon2')} ${selectedIcon === 'icon2' ? 'icon-selected' : ''}`}
                            onClick={() => handleIconClick('icon2')}
                            onMouseEnter={() => handleIconMouseEnter('icon2')}
                            onMouseLeave={handleIconMouseLeave}>  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 23 23" fill="none" style={{ paddingRight: '3px' }}>
                                <path d="M11.7183 4.28906H10.2197C6.47302 4.28906 4.97437 5.96346 4.97437 10.1495V15.1727C4.97437 19.3587 6.47302 21.0331 10.2197 21.0331H14.7156C18.4622 21.0331 19.9609 19.3587 19.9609 15.1727V13.4983" stroke={getSvgStrokeColor('icon2')} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.4948 5.13849L9.59011 11.7356C9.36531 11.9868 9.14051 12.4807 9.09555 12.8407L8.77334 15.3607C8.65345 16.2732 9.23043 16.9095 10.0472 16.7839L12.3027 16.4239C12.6174 16.3737 13.0595 16.1225 13.2918 15.8714L19.1965 9.27426C20.2156 8.13567 20.6951 6.81289 19.1965 5.13849C17.6978 3.46409 16.5139 3.9999 15.4948 5.13849Z" stroke={getSvgStrokeColor('icon2')} strokeWidth="1.3" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M14.6479 6.08594C15.15 8.08684 16.5512 9.65241 18.3496 10.2217" stroke={getSvgStrokeColor('icon2')} strokeWidth="1.3" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg> {expanded && (

                                <span className='textspan'>בניית מערך</span>

                            )}</div>
                    </Link> */}
          <Link className="nav-link" to="/user/classSum/">
            <div
              className={`icon ${getIconStyle("icon3")} ${
                selectedIcon === "icon3" ? "icon-selected" : ""
              }`}
              onClick={() => handleIconClick("icon3")}
              onMouseEnter={() => handleIconMouseEnter("icon3")}
              onMouseLeave={handleIconMouseLeave}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 23 23"
                fill="none"
                style={{ padding: "3px" }}
              >
                <path
                  d="M6.69531 22.0007C6.94835 20.986 7.70747 18.4492 11.25 18.4492C14.7926 18.4492 15.5517 20.986 15.8048 22.0007"
                  stroke={getSvgStrokeColor("icon3")}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.25 17.2665C12.5078 17.2665 13.5274 16.2065 13.5274 14.8989C13.5274 13.5913 12.5078 12.5312 11.25 12.5312C9.99227 12.5312 8.97266 13.5913 8.97266 14.8989C8.97266 16.2065 9.99227 17.2665 11.25 17.2665Z"
                  stroke={getSvgStrokeColor("icon3")}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.83398 7.7932C8.02377 7.45497 8.59311 6.60938 11.25 6.60938C13.9069 6.60938 14.4762 7.45497 14.6661 7.7932"
                  stroke={getSvgStrokeColor("icon3")}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.25 6.61029C12.5078 6.61029 13.5274 5.55026 13.5274 4.24265C13.5274 2.93503 12.5078 1.875 11.25 1.875C9.99227 1.875 8.97266 2.93503 8.97266 4.24265C8.97266 5.55026 9.99227 6.61029 11.25 6.61029Z"
                  stroke={getSvgStrokeColor("icon3")}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.00195 13.7114C1.22336 13.035 1.8876 11.3438 4.98733 11.3438C8.08707 11.3438 8.75131 13.035 8.97271 13.7114"
                  stroke={getSvgStrokeColor("icon3")}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.55666 10.1611C6.81441 10.1611 7.83402 9.10104 7.83402 7.79343C7.83402 6.48581 6.81441 5.42578 5.55666 5.42578C4.29891 5.42578 3.2793 6.48581 3.2793 7.79343C3.2793 9.10104 4.29891 10.1611 5.55666 10.1611Z"
                  stroke={getSvgStrokeColor("icon3")}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.5273 13.7114C13.7487 13.035 14.413 11.3438 17.5127 11.3438C20.6124 11.3438 21.2767 13.035 21.4981 13.7114"
                  stroke={getSvgStrokeColor("icon3")}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.9434 10.1611C18.2011 10.1611 19.2207 9.10104 19.2207 7.79343C19.2207 6.48581 18.2011 5.42578 16.9434 5.42578C15.6856 5.42578 14.666 6.48581 14.666 7.79343C14.666 9.10104 15.6856 10.1611 16.9434 10.1611Z"
                  stroke={getSvgStrokeColor("icon3")}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              {expanded && <span className="textspan">הכיתות שלי</span>}
            </div>
          </Link>
          {/* <Link className="nav-link" to="/user/create_lesson/">
                        <div className={`icon ${getIconStyle('icon4')} ${selectedIcon === 'icon4' ? 'icon-selected' : ''}`}
                            onClick={() => handleIconClick('icon4')}
                            onMouseEnter={() => handleIconMouseEnter('icon4')}
                            onMouseLeave={handleIconMouseLeave}><svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 23 23" fill="none" style={{ padding: '3px' }}>
                                <path d="M14.3098 16.0724C14.0521 16.0724 13.7943 16.0124 13.5615 15.885L10.2277 14.1011C9.87848 14.0936 9.5293 14.0712 9.19674 14.0262C8.97227 13.9962 8.78105 13.8612 8.69791 13.6664C8.61477 13.4715 8.65634 13.2616 8.80599 13.1042C9.35471 12.5271 9.63738 11.8376 9.63738 11.1031C9.63738 9.28925 7.86652 7.81272 5.68825 7.81272C4.87349 7.81272 4.09197 8.01516 3.43517 8.4049C3.25227 8.50983 3.02779 8.52483 2.82826 8.44238C2.63704 8.35993 2.49571 8.19497 2.47077 8.00009C2.44583 7.79023 2.4292 7.58045 2.4292 7.36309C2.4292 3.6455 6.00418 0.625 10.3939 0.625C14.7837 0.625 18.3587 3.6455 18.3587 7.36309C18.3587 9.40175 17.3111 11.2754 15.4654 12.5571L15.7481 14.5958C15.8146 15.1055 15.5652 15.5852 15.0913 15.8625C14.8585 15.9974 14.5842 16.0724 14.3098 16.0724ZM10.3856 12.9693C10.502 12.9618 10.6184 12.9919 10.7182 13.0518L14.2017 14.9181C14.2932 14.9706 14.368 14.9481 14.4179 14.9181C14.4594 14.8956 14.526 14.8357 14.5093 14.7307L14.1851 12.3622C14.1601 12.1524 14.2599 11.9501 14.4428 11.8302C16.1389 10.7584 17.1116 9.12436 17.1116 7.34802C17.1116 4.25259 14.102 1.73426 10.3939 1.73426C6.82726 1.73426 3.90075 4.07275 3.68459 7.01081C4.30814 6.79346 4.98157 6.67352 5.67994 6.67352C8.54826 6.67352 10.8762 8.65217 10.8762 11.0881C10.8845 11.7476 10.7099 12.3847 10.3856 12.9693Z" fill="#384459" stroke={getSvgStrokeColor('icon4')} strokeWidth="0.2" />
                                <path d="M3.26022 16.7305C3.04406 16.7305 2.83621 16.6781 2.64499 16.5657C2.27086 16.3483 2.07134 15.9735 2.12122 15.5763L2.28749 14.4221C1.16511 13.5976 0.5 12.3759 0.5 11.0943C0.5 9.63271 1.34803 8.26861 2.76971 7.45165C3.62604 6.94949 4.64033 6.67969 5.6962 6.67969C8.56452 6.67969 10.8924 8.65836 10.8924 11.0943C10.8924 12.0836 10.4933 13.058 9.76173 13.83C8.82225 14.8568 7.41718 15.4564 5.87079 15.5014L3.84219 16.5881C3.65929 16.6856 3.45975 16.7305 3.26022 16.7305ZM5.68788 7.80392C4.87312 7.80392 4.0916 8.00628 3.4348 8.39603C2.37062 9.01062 1.73877 10.015 1.73877 11.0943C1.73877 12.1361 2.30413 13.0879 3.3018 13.7025C3.49302 13.8225 3.59278 14.0248 3.56783 14.2347L3.38493 15.5163L5.37195 14.452C5.47172 14.3996 5.5798 14.3696 5.68788 14.3696C6.91003 14.3696 8.06569 13.8974 8.79731 13.0954C9.34603 12.5108 9.63702 11.8213 9.63702 11.0868C9.63702 9.28045 7.86615 7.80392 5.68788 7.80392Z" fill="#384459" stroke={getSvgStrokeColor('icon4')} strokeWidth="0.2" />
                            </svg> {expanded && (

                                <span className='textspan'>קהילת טיצ'</span>

                            )}</div>
                    </Link> */}
        </div>
        <div className="bottom-div">
          <Link className="nav-link" to="/user/dashboard-teacher/">
            <div
              className={`icon ${getIconStyle("icon5")} ${
                selectedIcon === "icon5" ? "icon-selected" : ""
              }`}
              onClick={() => handleIconClick("icon5")}
              onMouseEnter={() => handleIconMouseEnter("icon5")}
              onMouseLeave={handleIconMouseLeave}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 23 23"
                fill="none"
                style={{ padding: "3px" }}
              >
                <path
                  d="M15 23.4258H9C3.57 23.4258 1.25 21.1058 1.25 15.6758V9.67578C1.25 4.24578 3.57 1.92578 9 1.92578H15C20.43 1.92578 22.75 4.24578 22.75 9.67578V15.6758C22.75 21.1058 20.43 23.4258 15 23.4258ZM9 3.42578C4.39 3.42578 2.75 5.06578 2.75 9.67578V15.6758C2.75 20.2858 4.39 21.9258 9 21.9258H15C19.61 21.9258 21.25 20.2858 21.25 15.6758V9.67578C21.25 5.06578 19.61 3.42578 15 3.42578H9Z"
                  fill="#384459"
                />
                <path
                  d="M15.5801 19.9278C15.1701 19.9278 14.8301 19.5878 14.8301 19.1778V15.2778C14.8301 14.8678 15.1701 14.5278 15.5801 14.5278C15.9901 14.5278 16.3301 14.8678 16.3301 15.2778V19.1778C16.3301 19.5878 15.9901 19.9278 15.5801 19.9278ZM15.5801 8.87578C15.1701 8.87578 14.8301 8.53578 14.8301 8.12578V6.17578C14.8301 5.76578 15.1701 5.42578 15.5801 5.42578C15.9901 5.42578 16.3301 5.76578 16.3301 6.17578V8.12578C16.3301 8.53578 15.9901 8.87578 15.5801 8.87578Z"
                  fill="#384459"
                  stroke={getSvgStrokeColor("icon5")}
                />
                <path
                  d="M15.5799 14.0758C14.9174 14.0758 14.2697 13.8793 13.7188 13.5112C13.1679 13.1431 12.7385 12.6199 12.4849 12.0078C12.2314 11.3956 12.165 10.7221 12.2943 10.0722C12.4236 9.42239 12.7426 8.82548 13.2111 8.35697C13.6796 7.88847 14.2765 7.56941 14.9264 7.44015C15.5762 7.31089 16.2498 7.37723 16.8619 7.63078C17.474 7.88434 17.9972 8.31372 18.3653 8.86462C18.7334 9.41553 18.9299 10.0632 18.9299 10.7258C18.9299 12.5758 17.4199 14.0758 15.5799 14.0758ZM15.5799 8.87578C14.5599 8.87578 13.7299 9.70578 13.7299 10.7258C13.7299 11.7458 14.5599 12.5758 15.5799 12.5758C16.5999 12.5758 17.4299 11.7458 17.4299 10.7258C17.4299 9.70578 16.5899 8.87578 15.5799 8.87578ZM8.41992 19.9258C8.00992 19.9258 7.66992 19.5858 7.66992 19.1758V17.2258C7.66992 16.8158 8.00992 16.4758 8.41992 16.4758C8.82992 16.4758 9.16992 16.8158 9.16992 17.2258V19.1758C9.16992 19.5858 8.83992 19.9258 8.41992 19.9258ZM8.41992 10.8258C8.00992 10.8258 7.66992 10.4858 7.66992 10.0758V6.17578C7.66992 5.76578 8.00992 5.42578 8.41992 5.42578C8.82992 5.42578 9.16992 5.76578 9.16992 6.17578V10.0758C9.16992 10.4858 8.83992 10.8258 8.41992 10.8258Z"
                  fill="#384459"
                  stroke={getSvgStrokeColor("icon5")}
                />
                <path
                  d="M8.42007 17.9773C7.98014 17.9773 7.54452 17.8907 7.13808 17.7223C6.73164 17.554 6.36234 17.3072 6.05126 16.9962C5.74018 16.6851 5.49343 16.3158 5.32507 15.9093C5.15672 15.5029 5.07007 15.0673 5.07007 14.6273C5.07007 14.1874 5.15672 13.7518 5.32507 13.3454C5.49343 12.9389 5.74018 12.5696 6.05126 12.2585C6.36234 11.9475 6.73164 11.7007 7.13808 11.5323C7.54452 11.364 7.98014 11.2773 8.42007 11.2773C9.30854 11.2773 10.1606 11.6303 10.7889 12.2585C11.4171 12.8868 11.7701 13.7389 11.7701 14.6273C11.7701 15.5158 11.4171 16.3679 10.7889 16.9962C10.1606 17.6244 9.30854 17.9773 8.42007 17.9773ZM8.42007 12.7773C7.40007 12.7773 6.57007 13.6073 6.57007 14.6273C6.57007 15.6473 7.40007 16.4773 8.42007 16.4773C9.44007 16.4773 10.2701 15.6473 10.2701 14.6273C10.2701 13.6073 9.45007 12.7773 8.42007 12.7773Z"
                  fill="#384459"
                  stroke={getSvgStrokeColor("icon5")}
                />
              </svg>{" "}
              {expanded && <span className="textspan">הגדרות</span>}
            </div>
          </Link>
          <Link className="nav-link" to="/">
            <div
              className={`icon ${getIconStyle("icon6")} ${
                selectedIcon === "icon6" ? "icon-selected" : ""
              }`}
              onClick={logout}
              onMouseEnter={() => handleIconMouseEnter("icon6")}
              onMouseLeave={handleIconMouseLeave}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 23 23"
                fill="none"
                style={{ padding: "3px" }}
              >
                <path
                  d="M8.9 15.7627C9.21 19.0627 11.06 20.4102 15.11 20.4102H15.24C19.71 20.4102 21.5 18.7693 21.5 14.6718V8.69516C21.5 4.59766 19.71 2.95682 15.24 2.95682H15.11C11.09 2.95682 9.24 4.28599 8.91 7.53099M15 11.6927L3.62 11.6927M5.85 14.7635L2.5 11.6927L5.85 8.62182"
                  stroke={getSvgStrokeColor("icon6")}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              {expanded && <span className="textspan">התנתקות</span>}
            </div>
          </Link>
        </div>
      </div>
      {/* <div className='placeholderdiv'> */}
      {/* </div> */}
    </div>
  );
};

export default Sidebar;

// linear-gradient(180deg, #E9F2FF 11.98%, #DAF6FF 100%)
