import React, { useEffect, useState } from 'react';

import Grid from './Grid';
import ClassCard from './classCard';
import { makeRequest } from '../../api_service/api_func';
import { ReactComponent as Plus } from "../../images/plus.svg";
import { useNavigate } from 'react-router-dom';
import {BASE_URL} from '../../api_service/api_func'
import './classSum.css'
import { USER } from '../localstorage/store_name';

const ClassSum = ({ isDashboard }) => {
    const [cardData, setCardData] = useState([]);
    const nav = useNavigate();
    const [userId, setUserId] = useState(localStorage.getItem(USER))
    const url = `${BASE_URL}/class-lesson/count/${userId}`;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await makeRequest('GET', url);
                // Assuming the response is an array of objects
                const processedData = response.map(item => ({
                    title: `${item.age_group_name}'${item.class_number} - ${item.field_of_knowledge_name}`,
                    fieldInfo: [item.total_lessons, item.past_lessons, item.prepared_lessons_count],
                    bgColor: `${item.field_of_knowledge_color}`,
                    classId: item.class_id,
                    field_of_knowledge_id: item.field_of_knowledge_id
                }));

                setCardData(processedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);



    return (
        <div className="App" style={{ padding: '10px' }}>
            {isDashboard && (
                <div className='d-flex flex-row align-items-end' style={{ padding: '0px 20px 10px 0px' }}  >
                    <div className='title'>הכיתות שלי - מחצית {"א"}'</div>
                    <div className='expand-view' style={{ paddingRight: '10px' }}
                        onClick={() => {

                            nav('/user/classSum', { state: { title: 'הכיתות שלי' } });
                        }}
                    // onClick={onToggleDashboard} 

                    >הרחב תצוגה </div>
                </div>
            )}
            <Grid isDashboard={isDashboard}>
                {cardData.map((card, index) => (
                    (!isDashboard || index < 6) &&
                    <ClassCard
                        key={index}
                        title={card.title}
                        fieldInfo={card.fieldInfo}
                        bgColor={card.bgColor}
                        classId={card.classId}
                        field_of_knowledge_id={card.field_of_knowledge_id}
                        isDashboard={isDashboard}
                    />
                ))}
            </Grid>

            {isDashboard && (
                <div className='d-flex flex-row-reverse'>
                    {/* <label className='plus-btn'>
                        <span className='m-1'>
                            <Plus width="10" height="10" />
                        </span>
                        הוספת תחום דעת
                    </label> */}
                </div>
            )}
        </div>
    );
};

export default ClassSum;
