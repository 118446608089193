
import React, { useEffect, useState } from 'react'
import "../css/header.css";
import NavBar from './NavBar';
import { doApiFetchUser } from '../api_service/api_func';
import { Link, Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const ROLES = {
  super_admin: "מנהל מערכת",
  admin: "מנהל מוסד",
  user: "מורה",
};
const Header = () => {
  const [user, setUser] = useState({});
const nav = useNavigate()
  useEffect(() => {
    doApiFetchUser(setUser);
  }, [])
  const fullName = user.first_name + " " + user.last_name
  const userRole = ROLES[user.roles]

  const routToEditDetails =(()=> {
    nav('/user/update_personal_details')
  })

  const imgSrc = "/passport-img.jpg"
  return (
    <header className="container-fluid ">
      <div></div>

      <div className="profile-container">
        <div style={{ paddingLeft: "10px", }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 39 37" fill="none">
            <rect opacity="0.8" x="0.95752" y="0.59375" width="37" height="35" rx="17.5" fill="white" stroke="#C3D4E9" />
            <ellipse cx="31.1508" cy="6.23047" rx="4.625" ry="4.5" fill="#FF4423" />
            <path d="M18.9743 10.6543C16.1909 10.6543 13.9288 12.8552 13.9288 15.5634V17.9279C13.9288 18.427 13.7102 19.1879 13.4495 19.6134L12.4825 21.1761C11.8854 22.1416 12.2975 23.2134 13.3907 23.5734C17.015 24.7516 20.9252 24.7516 24.5495 23.5734C25.567 23.2461 26.0127 22.0761 25.4577 21.1761L24.4907 19.6134C24.2384 19.1879 24.0198 18.427 24.0198 17.9279V15.5634C24.0198 12.8634 21.7493 10.6543 18.9743 10.6543Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M20.5299 10.8922C20.2693 10.8186 20.0002 10.7613 19.7227 10.7286C18.9154 10.6304 18.1418 10.6877 17.4186 10.8922C17.6624 10.2868 18.2679 9.86133 18.9743 9.86133C19.6806 9.86133 20.2861 10.2868 20.5299 10.8922Z" stroke="#292D32" strokeWidth="1.5" strokeiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.497 23.8672C21.497 25.2172 20.3618 26.3217 18.9743 26.3217C18.2847 26.3217 17.6456 26.0436 17.1915 25.6017C16.7374 25.1599 16.4515 24.5381 16.4515 23.8672" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" />
          </svg>
        </div>
        <div className="text-details">
          <div style={{ fontWeight: 'bold', fontSize: '18px' }}>{fullName}</div>
          <div style={{ textAlign: 'left' }}>{userRole}</div>
        </div>
        <div className="circle-image" onClick={routToEditDetails}>
          <img src={imgSrc} alt={'png'} style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '25px' }} />
        </div>
      </div>

    </header>
  );
};

export default Header;
