import React, { createContext, useState } from 'react';

// Create the context
export const GlobalContext = createContext();

// Create a provider component
export const GlobalProvider = ({ children }) => {
  // Define the global state using useState hook
  const [globalState, setGlobalState] = useState({});

  // Define any actions or methods to modify the state
  const setGlobalData = (data) => {
    setGlobalState({ ...data });
  };

  // Provide the state and actions to the components
  return (
    <GlobalContext.Provider value={{ globalState, setGlobalData }}>
      {children}
    </GlobalContext.Provider>
  );
};
