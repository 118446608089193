import React, { useEffect, useState } from 'react'
import { USER } from '../localstorage/store_name';
import { getClasesByUser } from '../../api_service/api_func'

const ClassInput = ({ handleClassSelect, selectClass }) => {
    const [userid, setUserid] = useState(localStorage.getItem(USER));
    const [clases, setClases] = useState([])

    useEffect(() => {
        getClasesByUser(userid, setClases)
    }, [userid]);

    const handleClass = (event) => {
        if (event.target.value)
        handleClassSelect(event.target.value)
    }

    return (
        <div>
            <select onChange={handleClass} className='costumSelect' style={{
                marginTop: '-2px',
                verticalAlign: 'top',
                color: 'rgba(13, 13, 13,0.5)',
                paddingLeft: '15px',
                fontSize: '13px',
                direction: 'ltr', borderRadius: '10px',
                width: '100px', borderColor: 'rgba(13, 13, 13,0.2)'

            }}>
                <option
                    style={{ textAlign: 'center', fontSize: '13px', color: 'rgba(13, 13, 13,0.8)', width: '50px' }}
                    key='' value={''} disabled selected hidden>בחר כיתה</option>
                {clases.map(c => {
                    return (
                        <option style={{ textAlign: 'center', fontSize: '13px', color: 'rgba(13, 13, 13,0.8)', width: '50px' }}
                            key={c.class_id} value={c.class_id}>{c.ageGroupName} {c.class_number}</option>
                    )
                })}
            </select>
        </div >
    )
}

export default ClassInput;