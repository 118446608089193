import './ClassesOverview.css'
import {ReactComponent as Plus} from "../../images/plus.svg";
import { ClassReview } from './ClassReview'

const ClassesOverview = () => {


 
 const addFieldKnowledge = () => {
    console.log("add Field Knowledge functions");
};

const expandView = () => {

};

    return(
        <div className="wrap-overview mx-auto d-flex flex-column">
            <div className='d-flex flex-row justify-content-between p-2'>
                <div className='d-flex flex-row align-items-end'>
                    <div className='title'>הכיתות שלי - מחצית {"א"}'</div>
                    <div onClick={expandView} className='expand-view'>הרחב תצוגה</div>
                </div>
                {/* <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div> */}
            </div>
            <div className='d-flex flex-wrap'>
                <ClassReview/>
            </div>
            <div className='d-flex flex-row-reverse' onClick={addFieldKnowledge}>
                <label className='plus-btn'>
                    <span className='m-1'>
                        <Plus width="10" height="10"/>
                    </span>
                    הוספת תחום דעת
                </label>
            </div>
        </div>
    )
}

export {ClassesOverview}