import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import EmojiComponent from '../EmojiComponent ';
import { doApiDeleteStudyPeriodClassByID } from '../../api_service/api_func';
import './Scheduler.css'

const SchedulerTable = (props) => {
  const daysOfWeekHebrew = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי'];
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const { studyPeriod, scheduler, scheduler_time, instituion_id } = props;
  const nav = useNavigate();




  return (
    <>
      <table>
        <thead>
          <tr>
            <th style={{
              color: 'rgb(128,128,128)'

            }}>שעות/ימים</th>
            {daysOfWeekHebrew.map((day) => (
              <th key={day}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {scheduler_time.map((schedule, indexRaw) => {

            return (

              <tr key={indexRaw}>
                <td className='' style={{
                  color: 'rgb(128,128,128)'

                }} >{schedule.start_time.split(":").slice(0, 2).join(":")}-{schedule.end_time.split(":").slice(0, 2).join(":")}</td>

                {daysOfWeek.map((day, i) => {
                  let index = -1;
                  if (studyPeriod.length) {

                    index = studyPeriod.findIndex(
                      (item) => {
                        return item.StudyPeriod?.start_time === schedule.start_time && item.StudyPeriod.day_in_week === day;
                      }
                    )
                  }
                  if (index !== -1) {

                    return (
                      <td className='rounded-3 ' key={i} >

                        <button className='btn  btn-secondary'
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents the primary button's onClick from firing when the delete button is clicked
                            nav(`/user/study_period/${instituion_id}/${scheduler[index]?.id}/PUT/${studyPeriod[index].id}`);
                          }}
                          style={{
                            backgroundColor: studyPeriod[index]?.field_of_knowledge?.color,
                            color: "black",
                            textAlign: 'right', // Align text to the left,
                            alignItems: 'center',
                            width: '82px', // Fixed width
                            height: '38px',// Fixed height,
                            overflow: 'hidden', // Added to handle overflow
                            textOverflow: 'ellipsis', // Add ellipsis if text overflows
                            whiteSpace: 'nowrap', // Keep the text in a single line
                            paddingTop: "1px",
                            paddingBottom: "1px",
                            fontSize: '12px',
                            border: "0px"

                          }}
                        >
                          <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                            <div style={{ width: '80%', display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontSize: "15px", fontWeight: "bold"}}> {studyPeriod[index]?.field_of_knowledge?.name}</span>
                              <span style={{ fontWeight: "normal", marginTop: "-6px", fontSize: "13px" }}>  {studyPeriod[index]?.class.age_group_entity?.name}'
                                {studyPeriod[index]?.class?.number}</span>
                            </div>
                            <div style={{ width: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <button className='badge text-danger border-0 p-0 me-2 delete-btn' style={{ fontSize: "14px", marginRight: "10px" }}
                                onClick={(e) => {
                                  e.stopPropagation(); // Stops the click event from bubbling up to the parent button
                                  const confirmed = window.confirm('Are you sure you want to delete this item ?');
                                  if (confirmed) {
                                    // Delete the item
                                    doApiDeleteStudyPeriodClassByID(studyPeriod[index]?.id);
                                  }
                                }}
                              >X</button>
                            </div>
                          </div>


                        </button>

                      </td>
                    )
                  }
                  else {
                    const index = scheduler.findIndex(
                      (item) => {
                        return item?.start_time === schedule.start_time && item?.day_in_week == day
                      })

                    if (scheduler[index])
                      return <td key={i} className='rounded-3' >

                        <button className='btn btn-secondary '
                          onClick={() => {
                            nav(`/user/study_period/${instituion_id}/${scheduler[index]?.id}/GET/-1`)
                          }}

                        >
                          {`שיעור ${indexRaw + 1}`}

                        </button>
                      </td>;
                    else {
                      return <td key={i} className='rounded-3' >
                        אין שיעור
                      </td>;
                    }
                  }
                })}

              </tr>
            )
          }
          )}
        </tbody>
      </table>


    </>
  );
}

export default SchedulerTable
