import React, { useContext, useEffect, useState } from "react";
import { ClassesOverview } from "../classes_overview/ClassesOverview";
import Week_calender from "../week_calendar/week_calendar";
import TasksUser from "../../Task/tasksUser";
import { Chat } from "../Chat/Chat";
import "./dashboard-teacher.css";
import { USER } from "../localstorage/store_name";
import ClassSum from "../Lesson/classSum";
import Scores from "../Scores/Scores";

const DashboardTeacher = () => {
  // const [user, setUser] = useState({});
  const [isDashboard, setIsDashboard] = useState(true);
  const [userid, setUserid] = useState(localStorage.getItem(USER));
  const toggleDashboard = () => {
    setIsDashboard(false);
  };
  const disabled = true;

  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-main">
        <div className="classSum-wrapper">
          <ClassSum
            isDashboard={isDashboard}
            onToggleDashboard={toggleDashboard}
          />
        </div>
        {isDashboard && (
          <div className="calendar-overflow">
            <Week_calender />
          </div>
        )}
      </div>
      {/* <div className="left-side">
        {!disabled && (
          <>
            <TasksUser userId={userid} disabled={true} />
            <Scores />
          </>
        )}
        <div className="chat">
          <Chat />
        </div>
      </div> */}
    </div>
  );
};

export default DashboardTeacher;
