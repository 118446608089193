// MyModalContent.jsx
import React from 'react';
import { Box, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const modalTitleStyle = {
  fontSize: 14,
}


const MyModalContent = ({ isOpen, onClose, onConfirm, selectedOption, onSelectChange, content }) => {


  return (
    <Box style={modalStyle} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 300, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
      <h2 id="modal-modal-title">{content.title}</h2>
      <div id="modal-content">{content.content}</div>
      <FormControl fullWidth>
        <select labelId="selectModalLabel"
          id="selectModal"
          value={selectedOption}
          label="Select"
          onChange={onSelectChange}>
          <option value="" >בחר מיקום</option>
          {content.params.arr.map(a => {
            return (
              <option value={a} key={a}>{a}</option>
            )
          })}
        </select>
      </FormControl>
          <div className='d-flex gap-2'>

      <Button onClick={onConfirm} variant="contained" sx={{ mt: 2 }}>
        אישור
      </Button>
      <Button onClick={onClose} variant="contained" color="error" sx={{ mt: 2, ml: 2 }}>
        ביטול
      </Button>
          </div>
    </Box>


  );
};

export default MyModalContent;
