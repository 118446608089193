import React, { useEffect, useState } from 'react'
import Checkbox from './Checkbox';
import EmojiComponent from './EmojiComponent ';
import { BASE_URL, doApiGetAllUsers, makeRequest } from '../api_service/api_func';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthComponent from './Auth';



function InstitutionManagement() {

    const [users, setUsers] = useState([]);
    const nav=useNavigate();
   
   
    useEffect(() => {

        doApiGetAllUsers(setUsers);

    }, [nav]);
    


    const handleCheckboxChange = (isChecked) => {
        console.log('Checkbox checked:', isChecked);
        // Perform additional logic based on the checkbox value
    };


    return (

        <div className='container ' >

                <AuthComponent/>
                <div className=' table-responsive-md vh-100' >

                   
                        <table   className="table table-striped table-hover ">
                            <thead className='thead-dark'>
                                <tr>
                                    <th>#</th>
                                    <th>סמל מוסד</th>
                                    <th>שם מנהל</th>
                                    <th>כתובת מייל</th>
                                    <th>טלפון נייד</th>
                                    <th>סוג פיקוח</th>
                                    <th>עריכת המוסד</th>
                                    <th>פעיל</th>
                                    <th>ערוך</th>
                                </tr>
                            </thead>
                            <tbody >
                                {users.length>0?users.map((user, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{user.instituion_simble || 0}</td>
                                        <td>{`${user.first_name} ${user.last_name}`}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phone}</td>
                                        <td></td>
                                        <td></td>
                                        <td> <Checkbox onChange={handleCheckboxChange} />  </td>
                                        <td><button onClick={()=>{
                                            nav("/");
                                        }} className='btn '><EmojiComponent emojiName="pen" /></button></td>
                                    </tr>
                                )):(
                                    <tr>
                                      <td colSpan="9">No users found.</td>
                                    </tr>
                                  )}
                            </tbody>
                        </table>

             
                </div> 

        </div>
    )
}

export default InstitutionManagement