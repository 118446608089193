import { useEffect, useState } from 'react';
import GoogleAuth from './GoogleAuth';
import { sendGoogleAccessToken, syncGoggleCalendar} from './GoogleApi'
import { USER } from '../localstorage/store_name';

const SyncGoogleCalendar = () => {
    const [userid, setUserid] = useState(localStorage.getItem(USER));

    const syncCalendar = async (token) => {
        const accessSync = await syncGoggleCalendar({
            userId:userid,
            accessToken: token.access_token
        })
        if(accessSync){
            alert(accessSync.message)
        }else{
            alert("נסה מאוחר יותר")
        }
    }

    const connectionFiled = (error) => {
        alert("נסה מאוחר יותר")
    }

    return (
        <div>
            <GoogleAuth onLoginSuccess={syncCalendar} onFiledConnect={connectionFiled}></GoogleAuth>
    </div>
)
}

export default SyncGoogleCalendar;