import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../css/takingOff.css'

function Taking_off() {

  const nav = useNavigate();


  return (
    <div style={{paddingTop:"10px",paddingBottom:"10px"}} className='container vh-100 d-flex align-items-center justify-content-center '>

    {/* <div className='container-fluid '> */}

      <div id='main' className="container pb-4">

        <div className='lightbox-wrapper mx-auto w-50 text-center bg-light rounded-4 mt-5 mb-4'>
          <div className='lightbox-container'>
          <img style={{
                        position: "absolute", top: "-50px", left: "50%",
                        transform: "translateX(-50%)",
                        width: "100px",
                        paddingLeft: "2px",
                        height: "90px",
                        borderRadius: "50%",
                        backgroundColor: "rgba(var(--bs-light-rgb), var(--bs-bg-opacity))"
                    }}
                        src="/logoTakingoff.png" alt="logo" />
          </div>
          <div className='d-flex justify-content-center align-items-center' style={{ minHeight: "400px " }} >
            <div>

              <h3 className='display-4 fw-bold ' style={{ fontSize: "30px", fontWeight: "bold" }}>יוצאים לדרך!</h3>
              <br />
              <br />
              <br />
              <br />
              <h3 className='lead fw-medium'>3 שלבים קצרים ואתם מחוברים</h3>
              <h3 className='lead fw-medium'>  לרשת חברתית למורים לשיתוף שיעורים
              </h3>
            </div>
          </div>
          <button
            onClick={() => {
              nav('/scheduler');
            }}

            style={{ backgroundColor: "#3C2F7F" }}
            className='btn px-5 mb-5 rounded-4 text-light'
          >

            שנתחיל?
          </button>
        </div>
      </div>



    </div>
  )
}

export default Taking_off