import React from 'react';
import styles from './classCard.module.css';
import { Box, Button, Typography } from '@mui/material';
import DonutChart from './DonutChart'
import { useNavigate } from 'react-router-dom';
import { Circle } from '../../components/common/Circle'

const statusLessons = (num, status, color) => {
    return (
        <div className="d-flex flex-row align-items-center">
            <Circle color={color} width={6} height={6}></Circle>
            <b>{num}</b> {status}
        </div>
    )
}

const ClassCard = ({ title, fieldInfo, bgColor, classId, field_of_knowledge_id, isDashboard }) => {
    let imgSrc, textColor;
    const nav = useNavigate();
    if ((fieldInfo[0] - fieldInfo[1]) < fieldInfo[3]) {
        imgSrc = "/smileyGreen.png";
        textColor = "#2D932B";
    } else if ((fieldInfo[0] - fieldInfo[1]) == fieldInfo[3]) {
        imgSrc = "/smileyOrange.png";
        textColor = "#BD7103";
    } else {
        imgSrc = "/smileyRed.png";
        textColor = "#D80D20";
    }

    function darkenColor(hex, percent) {
        // Ensure percent is less than 100
        percent = (percent > 100) ? 100 : percent;

        // Convert hex to RGB
        let r = parseInt(hex.substring(1, 3), 16);
        let g = parseInt(hex.substring(3, 5), 16);
        let b = parseInt(hex.substring(5, 7), 16);

        // Calculate the new color value
        r = parseInt(r * (100 - percent) / 100);
        g = parseInt(g * (100 - percent) / 100);
        b = parseInt(b * (100 - percent) / 100);

        // Convert the color back to hex
        r = r.toString(16).padStart(2, '0');
        g = g.toString(16).padStart(2, '0');
        b = b.toString(16).padStart(2, '0');

        return `#${r}${g}${b}`;
    }

    const darkerColor = darkenColor(bgColor, 20); // Darken by 20%
    // console.log(bgColor, darkerColor)


    return (
        <>
            {!isDashboard && (
                <div className={styles.card}>
                    <div className={styles.mainSection}>
                        <div className={`${styles.row1} ${styles.titleClass}`} style={{ backgroundColor: bgColor }}>{title}</div>
                        <div className={styles.row2}>
                            <div className={styles.rightSection2}>
                                {/* <div className={styles.textContainer}>
                            <div>
                                <img src={imgSrc} alt="Smiley" />
                            </div>
                            <div className={`${styles.textLine1}`} style={{ color: textColor }}><span className={styles.boldText}>{fieldInfo[3]}</span> שעות נשארו עבור <span className={styles.boldText}>{fieldInfo[0] - fieldInfo[1]}</span> שיעורים </div>
                        </div> */}
                                <div className={styles.textContainer}>
                                    <div className={`${styles.circle} ${styles.circle1}`}></div>
                                    <div className={styles.textLine}><span className={styles.boldText}>{fieldInfo[1]}</span> מתוך סה"כ <span className={styles.boldText}>{fieldInfo[0]}</span> שיעורים נלמדו</div>
                                </div>
                                <div className={styles.textContainer}>
                                    <div className={`${styles.circle} ${styles.circle2}`}></div>
                                    <div className={styles.textLine}><span className={styles.boldText}>{fieldInfo[2]}</span> הוכנו</div>

                                    <div className={`${styles.circle} ${styles.circle3}`}></div>
                                    <div className={styles.textLine}><span className={styles.boldText}> {fieldInfo[0] - fieldInfo[2]}</span> לא הוכנו </div>
                                </div>
                            </div>
                            <div className={styles.leftSection}>
                                <DonutChart lesson={fieldInfo} />
                            </div>
                        </div>
                        <div className={styles.row3}>
                            <Button
                                variant="contained"
                                className={styles.buttonStyle} style={{ fontSize: '13px' }}
                                onClick={() => {
                                    const params = {
                                        // Specify your parameters here
                                        classId: classId,
                                        field_of_knowledge_id: field_of_knowledge_id,
                                        title
                                    };

                                    // Navigate to '/user/skillsSum' with parameters in state
                                    nav('/user/skillsSum', { state: params });
                                }}
                            >
                                מעבר למיומניות
                            </Button>
                        </div>
                    </div>
                </div >)
            }
            {
                isDashboard && (

                    <div className={styles.cardDesh}
                        onClick={() => {
                            const params = {
                                // Specify your parameters here
                                classId: classId,
                                field_of_knowledge_id: field_of_knowledge_id,
                                title
                            };

                            // Navigate to '/user/skillsSum' with parameters in state
                            nav('/user/skillsSum', { state: params });
                        }}>
                        <div style={{ backgroundColor: bgColor }} className={styles.mainSection}>

                            <div className={styles.row2}>
                                <div className={styles.rightSection2Desh}>

                                    <div className={styles.textContainer} style={{ width: '110px' }}> <div className={styles.titleClassDesh} > {title}</div></div>
                                    <div className={styles.textContainer}>
                                        <div className={`${styles.circle} ${styles.circle2}`} style={{ backgroundColor: darkerColor }}></div>
                                        <div className='textLineDesh'> <span className={styles.boldText} style={{ paddingLeft: '3px', paddingRight: "2px" }}>  {fieldInfo[1]}</span>נלמדו</div>
                                    </div>

                                    <div className={styles.textContainer} ><span className={styles.boldText} style={{ paddingLeft: '3px', paddingRight: "2px" }}>  {fieldInfo[0]}  </span>    שיעורים    </div>

                                    <div className={styles.textContainer}>
                                        <div className={`${styles.circle} ${styles.circle2}`} style={{ backgroundColor: "#d3d3d3 " }}></div>
                                        <span className={styles.boldText} style={{ paddingLeft: '3px', paddingRight: "2px" }}>  {fieldInfo[0] - fieldInfo[1]}</span> להשלים
                                    </div>

                                </div>
                                <div className={styles.leftSectionDesh}>
                                    <DonutChart lesson={fieldInfo} color={darkerColor} />
                                </div>
                            </div>

                        </div>
                    </div >


                )
            }
        </>

    )
}

export default ClassCard;
