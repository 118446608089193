import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { doApiGetBooksByInstitution, doApiGetCubeById, doApiGetCubeGoalByCubeId, doApiGetSystemLessonGoalBySyslessonId, doApiSetCube, doApiSetCubeGoal, UploadFiles, updateClassLesson } from '../../api_service/api_func';

import Input from '../Input';
import './cube.css';
import { Instituion_id, USER } from '../localstorage/store_name';
import EmojiComponent from '../EmojiComponent ';
import Rating from '../Lesson/Rating';
import { GlobalContext } from '../store';
import { LinkAndFile } from '../Input_forLinkAndFiles';
import PopupLoader from '../common/popup/PopupLoader';
import StudyProgram from '../studyProgram/StudyProgram';
import FileInput from '../FileInput';
import styles from './CreateNewCube.module.css'
import { WrapNone } from 'docx';
import CubeDetails from '../Lesson/CubeDetails';

// import ImageUpload from '../ImageUpload';
// import CubeGoal from './CubeGoal';


const CreateNewCube = () => {
    const cubeTypes = ["פתיחה מסקרנת ", "הקניה", "גוף", "סיכום"]
    const nav = useNavigate();
    const location = useLocation();
    const { system_lesson_id, cube_id, readOnly, Type } = useParams();
    // const [checkedItemsCopy, setCheckedItemsCopy] = useState({});
    const [system_lesson_Golas, set_system_lesson_Golas] = useState([]);
    const [checkedItems, setCheckedItems] = useState({});
    const [showCheckboxes, setShowCheckboxes] = useState(readOnly || false);
    const [title, setTitle] = useState("");
    const [subtitle, setSubTitle] = useState("");
    const [body, setBody] = useState("");
    const [level1, setLevel1] = useState("");
    const [level2, setLevel2] = useState("");
    const [level3, setLevel3] = useState("");
    const [book, setBook] = useState("");
    const [books, setBooks] = useState("");
    const [pages, setPages] = useState("");
    const [file, setFile] = useState([]);
    const [link, setLink] = useState([]);
    const [start_page, setStartPage] = useState(0);
    const [end_page, setEndPage] = useState(0);
    const [selectedBookId, setSelectedBookId] = useState('');
    const { globalState, setGlobalData } = useContext(GlobalContext);
    const [files, setFiles] = useState([]);
    const [fileError, setFileError] = useState("")
    const [cubeType, setCubeType] = useState("")

    const state= {}
    const updateGlobalValue = () => {
        setGlobalData({
            ...globalState,
            cube_id: cube_id
        });
        console.log("globalstate", globalState.cube_id);
        nav(-1);
    };
    const [open, setOpen] = useState(false)
    console.log('\n\n\n Type', Type, '\n\n\n ');


    const setValuesForUpdate = {
        setTitle,
        setSubTitle,
        setBody,
        setLevel1,
        setLevel2,
        setLevel3,
        setBooks,
        setPages,
        setStartPage,
        setEndPage,
        setSelectedBookId,
        setFiles,
        setLink
    }

    const handleSubmit = (e) => {
        setFileError("");
        e.preventDefault();

        // Construct your data object to send
        const formData = {
            // golas: checkedItems,
            type: parseInt(Type, 10),//should be the type of the cube ['וכו' פתיחה מסקרנת']
            title,
            subtitle,
            body,
            fileLinks: files,
            Link: "your_link_here",
            user_id: parseInt(localStorage.getItem(USER), 10),
            book: parseInt(selectedBookId, 10),
            created_at: new Date(),
            likes: 0,
            views: 0,
            id_system_lesson: parseInt(system_lesson_id, 10),
            backgroundimage: "path_to_background_image",
            level_1: level1,
            level_2: level2,
            level_3: level3,
            start_page,
            end_page
        };
        console.log("_______", formData);

        if (doApiSetCube(formData, checkedItems)) {
            //  doApiSetCubeGoal(checkedItemsCopy);
            if (cube_id) {

                alert("Copy Cube to your DB successfully!")
              
            } else {
                alert("New Cube saved to your DB successfully!")

            }
        } else {
            alert("something went wrong")

        }
        window.location.reload();
        nav(-1);

    };

    const handleLevel1 = (event) => {
        setLevel1(event.target.value);

    };
    const handleLevel2 = (event) => {
        setLevel2(event.target.value);

    };
    const handleLevel3 = (event) => {
        setLevel3(event.target.value);

    };
    const handleTitle = (event) => {

        setTitle(event.target.value);
    };
    const handleSubTitle = (event) => {
        setSubTitle(event.target.value);
    };
    const handleBody = (event) => {
        setBody(event.target.value);
    };


    const handleBook = (event) => {

        setSelectedBookId(event.target.value);

    };
    const handleStartPage = (event) => {
        // console.log(pages);
        setStartPage(event.target.value);

    };
    const handleEndPage = (event) => {
        // console.log(pages);
        setEndPage(event.target.value);

    };
    const handleFile = (event) => {
        setFile(event.target.value);

    };
    const handleLink = (event) => {
        setLink(event.target.value);

    };



    const inputPropsArrayExtras = [
        {
            onChange: handleStartPage,
            //  defaultValue: "לחצו כאן לעריכה, עד 3 מילים, יופיע בכותרת קוביה",
            value: pages,
            name: "עמוד התחלה ",
            disabled: readOnly || false,
            type: "text",
            req: true,
            class: "cube-input"
        },
        {
            onChange: handleEndPage,
            //  defaultValue: "לחצו כאן לעריכה, עד 3 מילים, יופיע בכותרת קוביה",
            value: pages,
            name: "עמוד אחרון ",
            type: "text",
            disabled: readOnly || false,
            req: true,
            class: "cube-input"
        },
        // {
        //     onChange: handleFile,

        //     value: file,
        //     name: "קובץ  ",
        //     type: "file",
        //     disabled: readOnly || false,
        //     req: true
        // },
        // {
        //     onChange: handleLink,
        //     // defaultValue: "לחצו כאן לעריכה, פרטו עד כמה שניתן ב- 5 מילים",
        //     value: link,
        //     name: "קישור  ",
        //     type: "text",
        //     disabled: readOnly || false,
        //     req: true
        // },

    ];

    const inputPropsArrayDetailes = [
        {
            onChange: handleTitle,
            placeholder: "לחצו כאן לעריכה, עד 3 מילים, יופיע בכותרת קוביה",
            value: title,
            name: "כותרת קוביה",
            type: "text",
            req: true,
            disabled: readOnly || false,
            class: "cube-input"
        },
        {
            onChange: handleSubTitle,
            placeholder: "לחצו כאן לעריכה, פרטו עד כמה שניתן ב- 5 מילים",
            value: subtitle,
            name: "כותרת משנה",
            type: "text",
            disabled: readOnly || false,
            req: true,
            class: "cube-input"
        },

    ];

    const inputPropsArrayLevels = [
        {
            onChange: handleLevel1,
            placeholder: "לחצו כאן לעריכה, עד 3 מילים, יופיע בכותרת קוביה",
            value: level1,
            name: "ילדי שילוב וחנ”מ | רמה 1 ",
            disabled: readOnly || false,
            type: "text",
            req: false,
            class: "cube-input"
        },
        {
            onChange: handleLevel2,
            placeholder: "לחצו כאן לעריכה, פרטו עד כמה שניתן ב- 5 מילים",
            value: level2,
            name: "רמת כיתה | רמה 2",
            type: "text",
            disabled: readOnly || false,
            req: false,
            class: "cube-input"
        },
        {
            onChange: handleLevel3,
            placeholder: "לחצו כאן לעריכה, פרטו עד כמה שניתן ב- 5 מילים",
            value: level3,
            name: "מצטיינים ומחוננים | רמה 3",
            type: "text",
            disabled: readOnly || false,
            req: false,
            class: "cube-input"
        },

    ];

    const inputCubeBody = {
        onChange: handleBody,
        placeholder: "לחצו כאן לכתיבת פרטי הקוביה המלאים ומהלך השיעור",
        value: body,
        name: "גוף קוביה",
        type: "textarea",
        req: true,
        disabled: readOnly || false,
        class: "cube-input"
    }

    const handleFileUpload = async (file) => {
        setFileError("");
        const formData = new FormData();
        formData.append(
            "file",
            file,
            file.name
        );
        // upload file and returns the link of the file
        setOpen(true);
        const res = await UploadFiles(formData);
        if (res.status === 201) {
            setFiles([...files, { "link": res.link, "fileName": file.name }]);
        } else {
            setFileError(res.message)
        }
        setOpen(false);
    };

    const handleFileDelete = (fileIndex) => {
        setFiles(files.filter((_, index) => index !== fileIndex));
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [name]: checked,
        }));
    };

    // const setCheckedItemsForUpdate = (params) => {
    //     const { name, checked } =
    //         setCheckedItems((prevCheckedItems) => (
    //             {
    //                 [name]: checked,
    //             }
    //         ));
    // }

    const toggleCheckboxes = () => {
        setShowCheckboxes(!showCheckboxes);
    };

    useEffect(() => {
        console.log("paramsurl", system_lesson_id);
        //    console.log(Type);
        doApiGetSystemLessonGoalBySyslessonId(system_lesson_id, set_system_lesson_Golas);
        doApiGetBooksByInstitution(localStorage.getItem(Instituion_id), setBooks);

        if (cube_id) {

            doApiGetCubeById(cube_id, setValuesForUpdate);
            doApiGetCubeGoalByCubeId(cube_id, setCheckedItems)

        }
        // console.log('URL changed to:', location.pathname);
        // location.pathname
    }, [location.pathname])
    console.log('\n\n\n cube_id', cube_id, '\n\n\n ');

    return (
        <div className='bg-white mx-5 mb-3 rounded-4 pb-4 px-2'>
            {open && <PopupLoader color={"white"} loading={true} size={100} text={"טוען את הקובץ ..."} />}
            <div className=' d-flex'>
                <p className='text-center pt-3 fw-bold p-4'>
                    {!readOnly ? (cube_id ? "שכפול קוביה קיימת" : "יצירת קוביה חדשה") : "צפייה בקוביה"}
                </p>
                <p className='text-center pt-3 '>{cubeTypes[Type]} {descriptionCubeType[Type]}</p>
            </div>

            <div className='d-flex '>
                <form onSubmit={handleSubmit}>
                    <div className='container mt-2 w-75' styles={styles}>
                        <div className='d-flex'>
                            {/* <div className='col-4 d-flex justify-content-end'> */}
                                <div>
                                <p className='fw-bold'>
                                    פירטי קובייה:
                                </p>
                                </div>
                            {/* </div> */}
                        </div>
                        <div className='d-flex w-100'>
                            <div className='d-flex col-4'>
                            <p className='pt-1'>מותאם מטרה </p>

                            <div className='btn  mb-4 rounded-4' onClick={toggleCheckboxes}>
                                <EmojiComponent emojiName={"arrow_down"} />

                            </div>
                            </div>
                            {/* <CubeGoal system_lesson_id={system_lesson_id} setCheckedItemsCopy={setCheckedItemsCopy} test={test} /> */}
                            {showCheckboxes ?
                                <div className='mb-4 w-100'>
                                    {system_lesson_Golas.length > 0 && (
                                        <div className={`form-control rounded rounded-4 border border-2 ${readOnly? '':'cube-input'}`}>
                                            {system_lesson_Golas.map((goal) => (
                                                <label key={goal.id} className='form-check'>
                                                    <input
                                                        type="checkbox"
                                                        name={goal.id.toString()} // Assuming goal.id is a unique identifier
                                                        checked={checkedItems[goal.id.toString()] || false}
                                                        onChange={handleCheckboxChange}
                                                        disabled={readOnly || false}
                                                    />
                                                    <span className='me-2'>
                                                        {goal.description}
                                                        </span>
                                                </label>
                                            ))}
                                        </div>
                                    )}
                                    <div>

                                    </div>
                                </div>
                                :
                                <div className='btn bg-light mb-4 rounded-4 w-100' >
                                    (ניתן לסמן יותר ממטרה אחת)
                                </div>}
                        </div>
                        {inputPropsArrayDetailes.map((inputProps, index) => (
                            <div key={index}>
                                <Input props={inputProps} />
                            </div>
                        ))}
                        <div className=' '>
                            <div className='d-flex '>
                                <div className='col-4'>
                                    <label className=''>גוף הקוביה</label>
                                </div>
                                <div className='col w-100'>
                                    <textarea
                                        style={{ width: "inherit", resize: inputCubeBody.disabled ? "none" : "vertical" }}
                                        {...inputCubeBody}
                                        onChange={inputCubeBody.onChange}
                                        placeholder={inputCubeBody.placeholder}
                                        value={inputCubeBody.value}
                                        name={inputCubeBody.name}
                                        disabled={inputCubeBody.disabled}
                                        className={inputCubeBody.disabled ? 'form-control border-0 rounded rounded-4 cube-input' : 'form-control border-2 rounded rounded-4 border cube-input'}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='d-flex'>
                            <div className='col-4 d-flex'>
                                <p className='fw-bold pt-2'>
                                    מענה לשונות
                                </p>
                            </div>
                        </div>
                        {inputPropsArrayLevels.map((inputProps, index) => (
                            <div className=' ' key={index}>
                                <Input props={inputProps} />
                            </div>
                        ))}

                        <div className='row pt-2'>
                            <div className='d-flex'>
                                <div className='col-4 d-flex'>
                                    <p className='fw-bold '>
                                        תוספות
                                    </p>
                                </div>
                            </div>
                            <div className='d-flex items-center '>
                                <span className='col-4 ps-3'>
                                    ספר וחוברת
                                </span>
                                <div className='d-flex'>
                                    {books.length > 0 && (
                                        <div className='select-custom'>
                                            <select
                                                value={selectedBookId}
                                                onChange={handleBook}
                                                className='form-select cube-input'
                                                disabled={readOnly || false}
                                                style={{ border: readOnly ? 'none' : '1px solid #ccc', borderRadius: "15px" }}
                                            >
                                                <option value=''>{book || " בחרו מהרשימה"}</option>
                                                {books.map((book) => (
                                                    <option key={book.Book.id} value={parseInt(book.Book.id, 10)}>
                                                        {book.Book.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    <div>{/* Render other components based on selected book */}</div>

                                    <div className=' me-2 d-flex'>
                                        <label className="input-label ms-2"> מעמוד
                                            <input className="custom-input me-2"
                                                type="number"
                                                placeholder="בחר עמוד"
                                                onChange={inputPropsArrayExtras[0].onChange}
                                                value={start_page}
                                                disabled={readOnly || false}
                                                style={{ border: readOnly ? 'none' : '1px solid #ccc cube-input' }}
                                            />

                                        </label>
                                        <label className="input-label"> עד עמוד
                                            <input
                                                className="custom-input me-2"
                                                type="number"
                                                placeholder="בחר עמוד"
                                                onChange={inputPropsArrayExtras[1].onChange}
                                                value={end_page}
                                                disabled={readOnly || false}
                                                style={{ border: readOnly ? 'none' : '1px solid #ccc cube-input' }}

                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                           {!readOnly && <LinkAndFile file={file} setFile={setFile} link={link} setLink={setLink} styles={styles} />}
                           {readOnly && <div className=' '>
                            <div className='d-flex '>
                                <div className='col-4'>
                                    <label className=''> קישורים</label>
                                </div>
                                <div className='col'>
                                                    {console.log(link)}
                                    </div>
                                    </div>
                                    </div>}
                            { inputPropsArrayExtras.map((inputProps, index) => {
                                if (index > 1) {
                                    return (
                                        <div className='' key={index}>
                                            <Input props={inputProps} />
                                        </div>
                                    );
                                }
                                return null; // If index is 0 or less, return null to not render anything
                            })}
                            <div className='err'>{fileError}</div>
                         <FileInput
                                onFileUpload={handleFileUpload}
                                onFileDelete={handleFileDelete}
                                files={files}
                                styles={styles}
                                readOnly={readOnly}
                            />
                        </div>
                        {/* {readOnly && <div className=' '>
                            <div className='d-flex '>
                                <div className='col-4'>
                                    <label className=''> קבצים</label>
                                </div>
                                <div className='col w-100'>
                                                    {console.log(file)}
                                    </div>
                                    </div>
                                    </div>} */}
                        {!readOnly
                            &&
                            <button type='submit' className='btn btn-primary float-start px-5 mb-5'>
                                צור קובייה
                            </button>
                        }
                    </div>
                    {readOnly &&
                        <>
                            <div className='btn bottom-button float-start px-5 mx-1' style={{borderRadius: "5px",backgroundColor: "#3C2F7F", color:"white"}}
                                onClick={async () => {                                 
                                    nav(-1, {state});
                                }}
                            >
                                סמן קוביה
                            </div>
                            <div className='btn bottom-button float-start px-5' style={{borderRadius: "5px",backgroundColor: "#3C2F7F", color:"white"}}
                                onClick={() => {
                                    nav(`/user/copy_cube/${system_lesson_id}/${cube_id}/${Type}`)
                                }}
                            >
                                מעבר לשכפול הקוביה
                            </div>
                        </>
                    }
                </form>

                {/* <div className='border border-2 mt-5 bg-light rounded-4 shadow shadow-lg ' style={{ height: "370px", width: "300px" }}>

                    <div
                        style={{
                            backgroundImage: "url('https://thumbs.dreamstime.com/b/books-6822971.jpg')",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "",
                            minHeight: "130px",
                        }}
                    >
                    </div>
                    <div className='d-flex justify-content-end my-2 ' >
                        {readOnly ? <>
                            <Rating value='5' />
                            <EmojiComponent emojiName={"heart"} />
                        </> : ""}
                    </div>
                    <div className='previewCube '>
                        <div className='content px-2'>
                            <p className='fw-bold '>כותרת: {title.length > 25 ? title.slice(0, 25) + "..." : title}</p>
                            <p className='fw-bolder ' > כותרת משנה: {subtitle.length > 25 ? subtitle.slice(0, 25) + "..." : subtitle}</p>
                            <p className=''>גוף: {body.length > 25 ? body.slice(0, 25) + "..." : body}</p>
                            {readOnly &&
                                <button className='btn btn-info bottom-button'
                                    


                                >
                                    מעבר לקוביה
                                </button>
                            }
                        </div>
                    </div>

                </div> */}

                <div className=' cube-details rounded-5 col  mx-1 '
                    style={{ maxHeight: "500px",maxWidth: "330px", minWidth: "330px", margin: '5px', border: true ? 'rgb(96,88,153) solid 3px' : '', backgroundColor: "white" }}
                >

                    <div className=' rounded-4'
                        style={{
                            backgroundImage: "url('https://thumbs.dreamstime.com/b/books-6822971.jpg')",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "",
                            minHeight: "120px",
                            maxHeight: "130px"
                        }}
                    ></div>
                    <div className='d-flex justify-content-between my-2 ' >
                        {/* <button className='px-3 mx-4 badge border-0 bg-transparent'
                            title='לחץ בשביל להוסיף'
                        >
                            <EmojiComponent emojiName={"heavy_plus_sign"} />
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.6067 10.9983V2.55278C13.6067 1.3867 12.7668 0.441406 11.7308 0.441406H4.22741C3.19141 0.441406 2.35156 1.3867 2.35156 2.55278V10.9983C2.35156 12.1643 3.19141 13.1096 4.22741 13.1096H11.7308C12.7668 13.1096 13.6067 12.1643 13.6067 10.9983Z" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M2.26634 2.4375C1.23041 2.4375 0.390625 3.38305 0.390625 4.54944V12.9935C0.390625 14.1599 1.23041 15.1055 2.26634 15.1055H2.26958L9.77244 15.0909C10.8071 15.0889 11.6148 14.3305 11.6148 13.1655" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M7.97949 3.60938V9.94349" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.793 6.76953H5.16542" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button> */}
                        <div className='d-flex align-items-center'>
                            {/* <Rating value='5' /> */}
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="80" height="16" viewBox="0 0 80 16" fill="none">
                                <path d="M9.03249 1.78658L10.1845 4.3145C10.3416 4.6664 10.7605 5.00393 11.1139 5.06856L13.2019 5.44919C14.5371 5.69336 14.8513 6.75623 13.8891 7.80474L12.2659 9.58578C11.991 9.8874 11.8404 10.4691 11.9255 10.8856L12.3903 13.0904C12.7568 14.8355 11.9124 15.5106 10.5052 14.5985L8.54813 13.3274C8.19468 13.0976 7.61214 13.0976 7.25215 13.3274L5.29507 14.5985C3.89437 15.5106 3.04347 14.8283 3.41001 13.0904L3.87473 10.8856C3.95982 10.4691 3.80928 9.8874 3.53437 9.58578L1.91112 7.80474C0.955504 6.75623 1.26314 5.69336 2.59839 5.44919L4.68635 5.06856C5.03326 5.00393 5.45216 4.6664 5.60925 4.3145L6.76125 1.78658C7.3896 0.414892 8.41068 0.414892 9.03249 1.78658Z" stroke="#FBB30F" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M24.7483 1.78658L25.9003 4.3145C26.0574 4.6664 26.4763 5.00393 26.8297 5.06856L28.9177 5.44919C30.2529 5.69336 30.5671 6.75623 29.605 7.80474L27.9817 9.58578C27.7068 9.8874 27.5563 10.4691 27.6414 10.8856L28.1061 13.0904C28.4726 14.8355 27.6283 15.5106 26.221 14.5985L24.264 13.3274C23.9105 13.0976 23.328 13.0976 22.968 13.3274L21.0109 14.5985C19.6102 15.5106 18.7593 14.8283 19.1258 13.0904L19.5906 10.8856C19.6756 10.4691 19.5251 9.8874 19.2502 9.58578L17.6269 7.80474C16.6713 6.75623 16.979 5.69336 18.3142 5.44919L20.4022 5.06856C20.7491 5.00393 21.168 4.6664 21.3251 4.3145L22.4771 1.78658C23.1054 0.414892 24.1265 0.414892 24.7483 1.78658Z" stroke="#FBB30F" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M40.9417 1.79439L42.0936 4.32231C42.2507 4.67421 42.6696 5.01174 43.0231 5.07638L45.1111 5.457C46.4463 5.70117 46.7605 6.76404 45.7983 7.81255L44.1751 9.59359C43.9002 9.89522 43.7496 10.4769 43.8347 10.8935L44.2994 13.0982C44.666 14.8433 43.8216 15.5184 42.4144 14.6063L40.4573 13.3352C40.1039 13.1054 39.5213 13.1054 39.1613 13.3352L37.2043 14.6063C35.8035 15.5184 34.9526 14.8361 35.3192 13.0982L35.7839 10.8935C35.869 10.4769 35.7185 9.89522 35.4436 9.59359L33.8203 7.81255C32.8647 6.76404 33.1723 5.70117 34.5076 5.457L36.5955 5.07638C36.9424 5.01174 37.3613 4.67421 37.5184 4.32231L38.6704 1.79439C39.2988 0.422704 40.3199 0.422704 40.9417 1.79439Z" stroke="#FBB30F" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M57.1448 1.77486L58.2968 4.30278C58.4539 4.65468 58.8728 4.99221 59.2262 5.05684L61.3142 5.43747C62.6494 5.68164 62.9636 6.74451 62.0014 7.79302L60.3782 9.57406C60.1033 9.87568 59.9527 10.4574 60.0378 10.8739L60.5026 13.0787C60.8691 14.8238 60.0247 15.4989 58.6175 14.5868L56.6604 13.3157C56.307 13.0859 55.7244 13.0859 55.3645 13.3157L53.4074 14.5868C52.0067 15.4989 51.1558 14.8166 51.5223 13.0787L51.987 10.8739C52.0721 10.4574 51.9216 9.87568 51.6467 9.57406L50.0234 7.79302C49.0678 6.74451 49.3754 5.68164 50.7107 5.43747L52.7987 5.05684C53.1456 4.99221 53.5645 4.65468 53.7216 4.30278L54.8736 1.77486C55.5019 0.403173 56.523 0.403173 57.1448 1.77486Z" stroke="#FBB30F" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M73.3518 1.77486L74.5038 4.30278C74.6609 4.65468 75.0798 4.99221 75.4332 5.05684L77.5212 5.43747C78.8565 5.68164 79.1706 6.74451 78.2085 7.79302L76.5852 9.57406C76.3103 9.87568 76.1598 10.4574 76.2449 10.8739L76.7096 13.0787C77.0761 14.8238 76.2318 15.4989 74.8245 14.5868L72.8675 13.3157C72.514 13.0859 71.9315 13.0859 71.5715 13.3157L69.6144 14.5868C68.2137 15.4989 67.3628 14.8166 67.7293 13.0787L68.1941 10.8739C68.2792 10.4574 68.1286 9.87568 67.8537 9.57406L66.2305 7.79302C65.2748 6.74451 65.5825 5.68164 66.9177 5.43747L69.0057 5.05684C69.3526 4.99221 69.7715 4.65468 69.9286 4.30278L71.0806 1.77486C71.7089 0.403173 72.73 0.403173 73.3518 1.77486Z" stroke="#292D32" strokeOpacity="0.49" strokeLinecap="round" strokeLinejoin="round" />
                            </svg> */}
                            {/* <EmojiComponent emojiName={"heart"} /> */}

                        </div>
                    </div>
                    <div className='div-ellipsis'>
                        <span className='fw-bold px-2' style={{ fontSize: "1.2rem" }}>{title}</span>
                    </div>
                    <div className='div-ellipsis'>
                        <span className='fw-bold px-2'
                        >{subtitle}</span>
                    </div>
                    {/* <p className='pe-3'>{Cube.body}</p> */}
                    <table style={{ width: '100%', borderCollapse: 'collapse', border: 'none', borderCollapse: 'separate', borderSpacing: '5px' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '42%', border: 'none', fontSize: '12px' }}>
                                    <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                            {/* SVG paths here */}
                                            <path d="M4.9502 8.89844C7.15933 8.89844 8.9502 7.10758 8.9502 4.89844C8.9502 2.6893 7.15933 0.898438 4.9502 0.898438C2.74106 0.898438 0.950195 2.6893 0.950195 4.89844C0.950195 7.10758 2.74106 8.89844 4.9502 8.89844Z" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M4.95475 7.56771C6.42751 7.56771 7.62142 6.3738 7.62142 4.90104C7.62142 3.42828 6.42751 2.23438 4.95475 2.23438C3.48199 2.23438 2.28809 3.42828 2.28809 4.90104C2.28809 6.3738 3.48199 7.56771 4.95475 7.56771Z" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M4.95052 6.24769C5.6869 6.24769 6.28385 5.65009 6.28385 4.91291C6.28385 4.17573 5.6869 3.57812 4.95052 3.57812C4.21414 3.57812 3.61719 4.17573 3.61719 4.91291C3.61719 5.65009 4.21414 6.24769 4.95052 6.24769Z" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>מותאם מטרה</span>
                                </td>
                                <td style={{ width: '58%', border: 'none', backgroundColor: '#E5EEF8', borderRadius: '8px' }}>
                                  {Object.keys(checkedItems).join()}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: 'none', fontSize: '12px' }}>
                                    <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                            <path opacity="0.4" d="M8.15954 2.87417C8.13252 2.86918 8.10099 2.86918 8.07397 2.87417C7.45245 2.8492 6.95703 2.28479 6.95703 1.58552C6.95703 0.871273 7.47496 0.296875 8.119 0.296875C8.76304 0.296875 9.28098 0.876268 9.28098 1.58552C9.27647 2.28479 8.78106 2.8492 8.15954 2.87417Z" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path opacity="0.4" d="M7.70004 6.50267C8.31705 6.61755 8.99712 6.49767 9.47452 6.14305C10.1096 5.67354 10.1096 4.90434 9.47452 4.43484C8.99262 4.08019 8.30354 3.96031 7.68652 4.08019" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path opacity="0.4" d="M2.73667 2.87417C2.7637 2.86918 2.79522 2.86918 2.82224 2.87417C3.44376 2.8492 3.93918 2.28479 3.93918 1.58552C3.93918 0.871273 3.42125 0.296875 2.77721 0.296875C2.13317 0.296875 1.61523 0.876268 1.61523 1.58552C1.61974 2.28479 2.11515 2.8492 2.73667 2.87417Z" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path opacity="0.4" d="M3.20095 6.50267C2.58394 6.61755 1.90387 6.49767 1.42647 6.14305C0.791437 5.67354 0.791437 4.90434 1.42647 4.43484C1.90837 4.08019 2.59745 3.96031 3.21447 4.08019" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M5.4613 6.61245C5.43428 6.60746 5.40276 6.60746 5.37573 6.61245C4.75421 6.58748 4.25879 6.02307 4.25879 5.3238C4.25879 4.60955 4.77673 4.03516 5.42077 4.03516C6.06481 4.03516 6.58274 4.61454 6.58274 5.3238C6.57824 6.02307 6.08282 6.59247 5.4613 6.61245Z" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M4.1437 8.18615C3.50809 8.65565 3.50809 9.4248 4.1437 9.89431C4.86497 10.4287 6.04604 10.4287 6.7673 9.89431C7.40292 9.4248 7.40292 8.65565 6.7673 8.18615C6.05055 7.6567 4.86497 7.6567 4.1437 8.18615Z" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>מותאם לרמות</span>

                                </td>
                                <td style={{ border: 'none', backgroundColor: '#E5EEF8', borderRadius: '8px' }}>
                                    {/* {Cube.level_1 ? '1,' : ''} {Cube.level_2 ? '2,' : ''} {Cube.level_3 ? '3' : ''} */}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: 'none', fontSize: '12px' }}>
                                    <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                            <path d="M9.9502 7.97757V2.15918C9.9502 1.58071 9.5092 1.15168 8.9737 1.19989H8.9467C8.0017 1.28666 6.5662 1.80246 5.7652 2.34236L5.6887 2.39538C5.5582 2.48215 5.3422 2.48215 5.2117 2.39538L5.0992 2.32308C4.2982 1.78799 2.8672 1.27702 1.9222 1.19507C1.3867 1.14686 0.950195 1.58071 0.950195 2.15436V7.97757C0.950195 8.44034 1.3012 8.87419 1.7332 8.93204L1.8637 8.95132C2.8402 9.09111 4.3477 9.62138 5.2117 10.1275L5.2297 10.1372C5.3512 10.2095 5.5447 10.2095 5.6617 10.1372C6.5257 9.6262 8.0377 9.09111 9.0187 8.95132L9.1672 8.93204C9.5992 8.87419 9.9502 8.44034 9.9502 7.97757Z" stroke="#4B4D4F" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M5.4502 2.54297V9.77381" stroke="#4B4D4F" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3.53919 3.99609H2.5293" stroke="#4B4D4F" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3.87806 5.44141H2.5293" stroke="#4B4D4F" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>ספר או חוברת</span>

                                </td>
                                <td style={{ border: 'none', backgroundColor: '#E5EEF8', borderRadius: '8px' }}>
                                    {/* {Cube?.book?.name || 'אין ספר'} */}
                                </td>
                            </tr>
                            <tr>

                                <td style={{ border: 'none', fontSize: '12px' }}>
                                    <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" fill="none">
                                            <path d="M3.83022 7.51063C3.79309 7.51063 3.75967 7.50226 3.72255 7.48551C3.61859 7.43947 3.55176 7.32226 3.55176 7.19668V4.68505C3.55176 4.51342 3.67799 4.37109 3.83022 4.37109C3.98244 4.37109 4.10868 4.51342 4.10868 4.68505V6.439L4.376 6.13761C4.48367 6.01621 4.66188 6.01621 4.76956 6.13761C4.87723 6.259 4.87723 6.45993 4.76956 6.58133L4.027 7.41854C3.97502 7.47714 3.90076 7.51063 3.83022 7.51063Z" fill="#4B4D4F" />
                                            <path d="M3.82868 7.47524C3.75814 7.47524 3.6876 7.44595 3.63191 7.38318L2.88935 6.54629C2.78168 6.42494 2.78168 6.22408 2.88935 6.10273C2.99702 5.98138 3.17523 5.98138 3.2829 6.10273L4.02546 6.93963C4.13313 7.06098 4.13313 7.26183 4.02546 7.38318C3.96977 7.44595 3.89923 7.47524 3.82868 7.47524Z" fill="#4B4D4F" />
                                            <path d="M6.06647 9.58594H3.83392C1.81345 9.58594 0.950195 8.61477 0.950195 6.34175V3.83012C0.950195 1.5571 1.81345 0.585938 3.83392 0.585938H5.69438C5.84694 0.585938 5.97345 0.728263 5.97345 0.899891C5.97345 1.07152 5.84694 1.21384 5.69438 1.21384H3.83392C2.11857 1.21384 1.50833 1.90036 1.50833 3.83012V6.34175C1.50833 8.27152 2.11857 8.95803 3.83392 8.95803H6.06647C7.78182 8.95803 8.39206 8.27152 8.39206 6.34175V4.24873C8.39206 4.0771 8.51857 3.93477 8.67113 3.93477C8.82368 3.93477 8.9502 4.0771 8.9502 4.24873V6.34175C8.9502 8.61477 8.08694 9.58594 6.06647 9.58594Z" fill="#4B4D4F" />
                                            <path d="M8.66304 4.56207H7.17467C5.90211 4.56207 5.40723 4.00532 5.40723 2.57369V0.899273C5.40723 0.773692 5.4742 0.656482 5.57839 0.610436C5.68258 0.560203 5.80164 0.589506 5.88351 0.677413L8.86025 4.02625C8.93839 4.11416 8.96443 4.2523 8.91978 4.36951C8.87513 4.48672 8.77467 4.56207 8.66304 4.56207ZM5.96537 1.65695V2.57369C5.96537 3.65369 6.21467 3.93416 7.17467 3.93416H7.98955L5.96537 1.65695Z" fill="#4B4D4F" />
                                        </svg>
                                    </div>
                                    <span style={{ display: 'inline-block', verticalAlign: 'middle' }} קבצים></span>
                                    קבצים
                                </td>
                                <td style={{ border: 'none', backgroundColor: '#E5EEF8', borderRadius: '8px' }}>
                                    {/* {Cube.file} */}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: 'none', fontSize: '12px' }}>
                                    <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                            <path d="M5.4502 9.97266C2.97206 9.97266 0.950195 7.95498 0.950195 5.47266C0.950195 2.99033 2.97206 0.972656 5.4502 0.972656C7.92833 0.972656 9.9502 2.99033 9.9502 5.47266C9.9502 7.95498 7.93252 9.97266 5.4502 9.97266ZM5.4502 1.60056C3.31531 1.60056 1.5781 3.33777 1.5781 5.47266C1.5781 7.60754 3.31531 9.34475 5.4502 9.34475C7.58508 9.34475 9.32229 7.60754 9.32229 5.47266C9.32229 3.33777 7.58508 1.60056 5.4502 1.60056Z" fill="#4B4D4F" />
                                            <path d="M4.859 7.56111C4.67482 7.56111 4.49902 7.51506 4.34413 7.42716C3.98413 7.21785 3.7832 6.80762 3.7832 6.27181V4.86948C3.7832 4.33366 3.97995 3.92343 4.33995 3.71413C4.69993 3.50483 5.15621 3.53831 5.62087 3.80622L6.83482 4.50529C7.29947 4.7732 7.55482 5.14995 7.55482 5.56855C7.55482 5.98297 7.29947 6.3639 6.83482 6.63181L5.62087 7.33088C5.36133 7.48576 5.09761 7.56111 4.859 7.56111ZM4.859 4.2039C4.78366 4.2039 4.71249 4.22064 4.65807 4.25413C4.49902 4.34622 4.41111 4.56808 4.41111 4.86948V6.27181C4.41111 6.56902 4.49902 6.79506 4.65807 6.88297C4.81296 6.97506 5.05156 6.93739 5.3111 6.79088L6.52505 6.09181C6.78459 5.94111 6.9311 5.75274 6.9311 5.57274C6.9311 5.39274 6.7804 5.20437 6.52505 5.05367L5.3111 4.35459C5.14366 4.25413 4.98877 4.2039 4.859 4.2039Z" fill="#4B4D4F" />
                                        </svg>
                                    </div>
                                    <span style={{ display: 'inline-block', verticalAlign: 'middle' }} >קישורים</span>

                                </td>
                                <td style={{ border: 'none', backgroundColor: '#E5EEF8', borderRadius: '8px' }}>
                                    {/* {Cube.link} */}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: 'none', fontSize: '12px' }}>
                                    <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" fill="none">
                                            <path d="M4.57617 3.625H6.41199" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M2.48242 3.64881L2.74435 3.94978L3.53013 3.04688" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M4.57617 6.4375H6.41199" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M2.48242 6.44568L2.74435 6.74665L3.53013 5.84375" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3.4002 8.89063H5.5002C7.2502 8.89063 7.9502 8.09063 7.9502 6.09063V3.69063C7.9502 1.69063 7.2502 0.890625 5.5002 0.890625H3.4002C1.6502 0.890625 0.950195 1.69063 0.950195 3.69063V6.09063C0.950195 8.09063 1.6502 8.89063 3.4002 8.89063Z" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <span style={{ display: 'inline-block', verticalAlign: 'middle' }} >משימות הערכות</span>
                                </td>
                                <td style={{ border: 'none', backgroundColor: '#E5EEF8', borderRadius: '8px' }}>
                                    יש
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='d-flex justify-content-center'>

                        <button className='btn  my-3 px-5 text-light fw-bold'
                            style={{ backgroundColor: "#605899", width: "160px", height: "35px", paddingTop: '5px' }}>
                            מעבר לקובייה
                        </button>
                    </div>
                </div>
            </div>


            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button className='btn  btn-primary  mt-4' style={{ width: '120px', height: '40px', fontSize: '14px', margin: '30px' }} >צור קוביה</button>
            </div> */}







        </div>
    )
}

export default CreateNewCube;


export const descriptionCubeType = [
    "| 5-7 דקות - יצירת עניין לנושא הספציפי שילמד בשיעור",
    "| 7-10 דקות - הוראת חומר חדש",
    "| 20-25 דקות - תרגול דיפרנציאלי של ההקניה",
    "| 7-10 דקות - ווידוא הבנה"
]