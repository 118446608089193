import { useState, useEffect } from 'react';
import Lesson from "./Lesson.jsx";
import { Draggable } from "react-drag-reorder";
import { BASE_URL, makeRequest } from '../../api_service/api_func';
import { reOrderClassLesson, addEnrichmentLessonToClass, deleteEnrichmentLessonOfClass, updateLessonDone } from '../../api_service/api_func.js'
import { USER } from '../localstorage/store_name';
import MyModalContent from '../MyModal.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal } from '@mui/material';
import EmojiComponent from "../EmojiComponent ";

const StudyProgram = () => {
    const baseURL = BASE_URL;
    const [lessons, setLessons] = useState([]);
    const [enrichLessons, setenrichLessons] = useState([]);
    const [numLesson, setNumLesson] = useState(0);
    const [numLessonbasic, setNumLessonBasic] = useState(0);
    const [numLessonEnrichment, setNumLessonEnrichment] = useState(0);
    const [numLessonsLearned, setNumLessonsLearned] = useState(0);
    const [numLessonsPrepared, setNumLessonsPrepared] = useState(0);
    const [fieldOfKnowladge, setFieldOfKnowladge] = useState();
    const [fieldOfKnowledgeName, setFieldOfKnowledgeName] = useState('');

    const [ordersArray, setOrdersArray] = useState([])
    const [params, setParams] = useState({})
    const [addEnrichment, setAddEnrichment] = useState({})
    const [dragKey, setDragKey] = useState(Date.now());
    const [classId, setClassId] = useState()
    const [skillId, setSkillId] = useState()
    const location = useLocation();
    const nav = useNavigate()
    const [showOpeningEnrichLesson, setShowOpeningEnrichLesson] = useState(false)
    
    const handleToggleEnrichLesson = (() => {
        setShowOpeningEnrichLesson(!showOpeningEnrichLesson)
    })

    useEffect(() => {
        const { state } = location;
        if (state) {
            // Access your params from the state object
            const { id_class, id_skill } = state;
            setClassId(id_class)
            setSkillId(id_skill)
            if (classId && skillId) {
                fetchSystemLessons();
            }
        } else {
            console.log('No params received');
        }

    }, [classId, skillId]);

    const fetchSystemLessons = async () => {

        // console.log('Received parameters:', params);
        const url = `${baseURL}/studyProgram/system-lessons?skillId=${skillId}&classId=${classId}`;
        try {
            const { currentData, additionalData } = await makeRequest('GET', url, null);

            // const arrayOrder = currentData.map(l => l.order)
            setLessons(currentData.sort((a, b) => { return a.order - b.order }));
            // setOrdersArray(arrayOrder.sort((a,b)=>{return(a-b)}));
            setOrdersArray(getOrderList(lessons))
            setenrichLessons(additionalData.sort((a, b) => { return a.order - b.order }));
            setOrdersArray(getOrderList(currentData))
            setNumLesson(currentData.length);
            setNumLessonBasic(currentData.filter(lesson => lesson.type === 'בסיס').length);
            // setNumLessonEnrichment(currentData.length - currentData.filter(lesson => lesson.type === 'בסיס').length);
            setNumLessonEnrichment(additionalData.length)
            setNumLessonsLearned(currentData.filter(lesson => lesson.isLessonLearned).length);
            setNumLessonsPrepared(currentData.filter(lesson => lesson.isLessonPrepared).length);
            setFieldOfKnowladge(currentData[0].fieldOfKnowledge)
            setFieldOfKnowledgeName(currentData[0].fieldOfKnowledgeName)
        } catch (error) {
            console.error('Error fetching system lessons:', error);
        }

    };

    const postReOrder = async (updatedLessons) => {
        try {
            return await reOrderClassLesson(updatedLessons);
        } catch (error) {
            console.error('Error updating lessons:', error);
        }
    };

    const explanation = "לחיצה רציפה על השיעור מאפשרת הזזה וסידור רצף השיעורים בהתאם לנוחות שלכם רוצים להוסיף שיעור משלכם? לחצו על סמליל (+) והוסיפו שיעור העשרה "


    const getChangedPos = (currentPos, newPos) => {
        // ... other logic if needed
        if (currentPos === newPos) {
            return
        }

        const updatedLessons = [...lessons];
        const item = updatedLessons.splice(currentPos, 1)[0];
        updatedLessons.splice(newPos, 0, item);
        const oldOrder = lessons[currentPos].order;
        const newOrder = lessons[newPos].order;
        const classLessonId = lessons[currentPos].key;
        const postClassReOrder = { classId, classLessonId, oldOrder, newOrder }
        setLessons(async (prevLessons) => {
            // Logic to rearrange lessons based on newPos and currentPos
            // For example, you might want to reorder the lessons array
            // based on the new positions.

            // This is a placeholder logic, modify it as per your requirements
            if (oldOrder !== newOrder) {
                try {
                    await postReOrder(postClassReOrder);
                    await fetchSystemLessons(); // Assuming fetchSystemLessons updates the 'lessons' state
                } catch (error) {
                    console.error('Error updating lessons:', error);
                }
            }
        });
    };

    const addEnrichmentLesson = async () => {
        try {
            const classLesson = {
                // userId: Number(userid),
                fieldOfKnowledge: fieldOfKnowladge,
                systemLeesonId: addEnrichment.key,
                //  study_period_class: StudyPeriodClassEntity;
                classId: classId,
                order: addEnrichment.order
            }
            if (isPastLessons()) {
                // classLesson.order = getOrderList()[getOrderList().length - 1]
                classLesson.order = ordersArray[ordersArray.length - 1]
            }
            if (await addEnrichmentLessonToClass(classLesson))
                return await fetchSystemLessons();
        } catch (error) {
            alert("נסה שנית")
        }

    }


    const deleteEnrichment = async (classLessonId) => {
        try {
            return await deleteEnrichmentLessonOfClass(classLessonId)
        } catch (error) {
            alert(error.response.data.message)
        }
    }

    const handleCheckboxChange = async (event, id) => {
        const done = event ? 1 : 0;
        try {
            const res = await updateLessonDone(id, done)
            await fetchSystemLessons()
        } catch (error) {
            alert(error)
        }

    }

    const generatListLessons = (lessonsList) => {

        if (lessonsList && lessonsList.length > 0) {
            return (
                <Draggable key={lessonsList.map(lesson => lesson.key).join('-')} onPosChange={getChangedPos}>
                    {lessonsList.map((lesson) => (
                        <Lesson key={lesson.key} lesson={lesson} isChecked={lesson.isLessonLearned || false} onCheckboxChange={(e) => handleCheckboxChange(e, lesson.key)} handleClick={(event) => handleClick(lesson, event)}>
                            lesson
                        </Lesson>
                    ))}
                </Draggable>
            );
        } else {
            // console.log('\n\n\n oooooo \n\n\n');
        }
    };

    const [eventType, setEventType] = useState()

    const handleClick = async (lesson, event) => {

        const eventType = {
            type: event ? 'add' : 'delete',
            lesson: lesson
        }
        if (!event) {
            try {
                await deleteEnrichment(lesson.key)
                await fetchSystemLessons()
            } catch (error) {

            }
        }
        if (event) {
            setAddEnrichment(prevEnrichment => ({
                ...prevEnrichment, ...lesson // set new state    
            })
            );
            handleOpenModal(); // Open the modal when the "Add Lesson" is clicked
        }

        setEventType(eventType)
    }

    const isPastLessons = () => {
        return lessons.some(item => item.isLessonLearned === true);
    }

    const modalContent = {
        title: "הוספת שיעור העשרה למערכת",
        content: ` בחר/י את מיקום השיעור בסדר השיעורים בתחום דעת ${fieldOfKnowledgeName}`,
        params: {
            // arr : lessons.map(l=>l.order)
            arr: ordersArray
        }
    }

    function getOrderList(lessonsList) {
        if (lessonsList.length) {
            const list = lessonsList.map(l => l.order)
            list.push(list[list.length - 1] + 1)
            return list
        }
        return []
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleConfirm = () => {
        // Handle the selected option or perform any necessary actions
        if (selectedOption != '') {
            addEnrichment.order = Number(selectedOption);
            addEnrichmentLesson()
            handleCloseModal();
            setSelectedOption('')
        }
    };


    return (

        <div className="wrap-study-program mx-auto" style={{ background: "rgba(215, 227, 248,0.2)", padding: "20px 20px" }}>
            <div style={{ background: "white", padding: "20px 20px", borderRadius: '15px' }}>
                <div className="top-banner d-flex flex-row justify-content-between" >
                    <div className="summary d-flex flex-row justify-content-between align-items-center" >
                        <div className="">תוכנית שיעורים</div>
                        <div className="d-flex align-items-center">
                            <div>{<Circle color={"basic-lesson-color"}></Circle>}</div>
                            <div>{numLessonbasic} בסיס </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div>{<Circle color={"enrichment-lesson-color"}></Circle>}</div>
                            <div>{numLessonEnrichment} העשרה </div>
                        </div>
                        <div>{numLessonsLearned} / {numLesson} נלמדו </div>
                        <div>{numLessonsPrepared} / {numLesson} שהוכנו </div>
                    </div>
                    <div className="explanation">
                        {explanation}
                    </div>
                </div>

                <div className="lessons d-flex flex-wrap">

                    {generatListLessons(lessons)}
                </div>
            </div >

            <div className="enrichment d-flex flex-column">
                <div style={{ background: "white", padding: "20px 20px", borderRadius: '15px', marginTop: '20px' }}>
                    <div className="d-flex justify-content-between">
                        <div><b>שיעורי העשרה | </b>{numLessonEnrichment} שיעורים</div>
                        <button className="btn " onClick={handleToggleEnrichLesson}>
                            {!showOpeningEnrichLesson ? (
                                <EmojiComponent emojiName={"arrow_down"}></EmojiComponent>
                            ) : (
                                <EmojiComponent emojiName={"arrow_up"}></EmojiComponent>
                            )}
                        </button>
                    </div>
                    {showOpeningEnrichLesson && <div>
                    <div><hr></hr></div>
                    <div className="d-flex flex-row-reverse">
                        <div className="explanation">
                            {explanation}
                        </div>
                    </div>
                    <div className="d-flex flex-wrap">
                        {generatListLessons(enrichLessons)}
                        <div className="add-lesson" onClick={() => { nav(`/user/create_enrichLesson/${skillId}`) }}>
                            <label>
                                <span className="m-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                </span>
                                הוסף שיעור העשרה
                            </label>
                        </div>
                    </div>
                    </div>}
                </div>
            </div>




            <div>

                <Modal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <MyModalContent
                        content={modalContent}
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                        onConfirm={handleConfirm}
                        selectedOption={selectedOption}
                        onSelectChange={handleSelectChange}
                    />
                </Modal>
            </div>


        </div >




    )
}

export default StudyProgram

const Circle = ({ color }) => {
    return (
        <div className={color} style={{
            width: "10px",
            height: "10px",
            marginInlineEnd: "5px",
            borderRadius: "5px",
        }}>
        </div>
    )
}