import React, { useState, useEffect } from "react";
import ImageUpload from "./ImageUpload";
import {
  doApiFetchUser,
  doApiUpdateUser,
  doApiChangePassword,
} from "../api_service/api_func";
import AuthComponent from "./Auth";
import { useNavigate } from "react-router-dom";
import "../css/update_personal_details.css";

function Update_personal_details() {
  const [user, setUser] = useState({});
  const userId = user.id;
  const [firstName, setFirstName] = useState(user.first_name || "");
  const [lastName, setLastName] = useState(user.last_name || "");
  const [email, setEmail] = useState(user.email || "");
  const [phone, setPhone] = useState(user.phone || "");
  const [password, setPassword] = useState(user.password || "");
  const [instituion_simble, setinstituion_simble] = useState(
    user.instituion_simble
  );
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };
  const nav = useNavigate();

  const handleSubmit = async () => {
    const updatedUser = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
    };
    try {
      await doApiUpdateUser(userId, updatedUser);
      // Navigate to the next page upon successful update..
      // nav("/takingoff");
      nav("/scheduler");
    } catch (error) {
      console.error("Error updating user:", error);
      // Handle errors appropriately (e.g., show an error message)
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      alert("New passwords do not match");
      return;
    }
    if (passwordRegex.test(newPassword)) {
      console.log("Password is valid");

      try {
        await doApiChangePassword(userId, oldPassword, newPassword);
        alert("Password changed successfully");
        // Additional logic after successful password change (e.g., navigate to another page)
      } catch (error) {
        alert("Failed to change password");
        console.error(error);
      }
    } else {
      console.log("Password does not meet the criteria");
      alert(
        "Password does not meet the criteria.\n It must be at least 8 characters,\n include 1 capital letter and 1 number."
      );
    }
  };

  useEffect(() => {
    doApiFetchUser(setUser);
  }, []);
  const pen = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="none"
    >
      <path
        d="M1.58647 10.0447C1.30801 10.0447 1.04781 9.93636 0.860642 9.73526C0.623264 9.4826 0.50914 9.10102 0.550224 8.68851L0.719128 7.01785C0.751083 6.70331 0.919986 6.28565 1.11628 6.05877L4.86412 1.57791C5.79994 0.458974 6.77684 0.428036 7.76743 1.48509C8.75803 2.54215 8.78542 3.64561 7.8496 4.76454L4.10177 9.2454C3.91004 9.47744 3.55397 9.69401 3.27551 9.74562L1.80559 10.0292C1.72798 10.0343 1.65951 10.0447 1.58647 10.0447ZM6.32947 1.47994C5.97797 1.47994 5.67212 1.72744 5.3617 2.0987L1.61386 6.58477C1.52256 6.69305 1.41757 6.95087 1.39931 7.1004L1.2304 8.77102C1.21214 8.94118 1.24866 9.0804 1.33083 9.16806C1.413 9.25571 1.53626 9.28665 1.6869 9.26087L3.15682 8.97732C3.2892 8.95154 3.50832 8.81742 3.59962 8.70914L7.34746 4.22828C7.91351 3.54764 8.11894 2.91856 7.29268 2.04198C6.92748 1.64494 6.6125 1.47994 6.32947 1.47994Z"
        fill="#B4BAC8"
      />
      <path
        d="M6.97279 5.62719C6.96366 5.62719 6.94996 5.62719 6.94083 5.62719C5.51657 5.46735 4.37076 4.24531 4.15164 2.64684C4.12425 2.43543 4.25207 2.23949 4.43924 2.20339C4.6264 2.17245 4.79987 2.31683 4.83182 2.52824C5.00529 3.77608 5.90002 4.73517 7.01387 4.85892C7.20104 4.87955 7.33799 5.07033 7.31973 5.28172C7.2969 5.47766 7.14626 5.62719 6.97279 5.62719Z"
        fill="#B4BAC8"
      />
      <path
        d="M8.64427 11.7149H0.427333C0.24017 11.7149 0.0849609 11.5395 0.0849609 11.3281C0.0849609 11.1167 0.24017 10.9414 0.427333 10.9414H8.64427C8.83143 10.9414 8.98664 11.1167 8.98664 11.3281C8.98664 11.5395 8.83143 11.7149 8.64427 11.7149Z"
        fill="#B4BAC8"
      />
    </svg>
  );

  return (
    // <div className='container-fluid'>
    <div className="update_personal">
      <div
        style={{ paddingTop: "10px", paddingBottom: "10px" }}
        className="container vh-100 d-flex align-items-center justify-content-center "
      >
        <AuthComponent />
        <div id="main" className="container" style={{ paddingBottom: "100px" }}>
          <div
            className="border border-3 mx-auto w-50 text-center bg-light shadow rounded-4 mt-5"
            style={{
              minHeight: "500px ",
              minWidth: "550px",
              position: "relative",
            }}
          >
            <img
              style={{
                position: "absolute",
                top: "-50px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "100px",
                paddingLeft: "2px",
                height: "90px",
                borderRadius: "50%",
                backgroundColor:
                  "rgba(var(--bs-light-rgb), var(--bs-bg-opacity))",
              }}
              src="/logoTakingoff.png"
              alt="logo"
            />
            <div className="d-flex justify-content-center align-items-center mt-5">
              <div>
                <p>שלב 1 מתוך 2</p>

                <span style={{ fontSize: "22px", fontWeight: "700" }}>
                  מילוי פרטים אישיים
                </span>
                <br />
                {/* <img src="/User-60.png" /> */}
                <br />
                {/* <span>העלאת תמונת פרופיל</span> */}
              </div>
            </div>

            <div class="parent">
              <div className="container">
                <div className="rows">
                  <span>אימות פרטים</span>

                  <label> שם פרטי </label>
                  <div>
                    {pen}{" "}
                    <input
                      type="text"
                      defaultValue={user.first_name}
                      onChange={(e) => handleInputChange(e, setFirstName)}
                    />{" "}
                  </div>
                </div>
                <div className="rows">
                  <span></span>

                  <label> שם משפחה </label>
                  <div>
                    {pen}{" "}
                    <input
                      type="text"
                      defaultValue={user.last_name}
                      onChange={(e) => handleInputChange(e, setLastName)}
                    />{" "}
                  </div>
                </div>
                <div className="rows">
                  <span></span>

                  <label>טלפון נייד</label>
                  <div>
                    {pen}{" "}
                    <input
                      type="text"
                      defaultValue={user.phone}
                      onChange={(e) => handleInputChange(e, setPhone)}
                    />{" "}
                  </div>
                </div>
                <div className="rows">
                  <span></span>

                  <label>אימייל</label>
                  <div>
                    {pen}{" "}
                    <input
                      type="text"
                      defaultValue={user.email}
                      onChange={(e) => handleInputChange(e, setEmail)}
                    />{" "}
                  </div>
                </div>
                <div className="rows">
                  <span></span>
                  <label>מוסד לימודים</label>
                  <div>
                    <span
                      style={{
                        visibility: "hidden",
                        width: "12px",
                        display: "inline-block",
                      }}
                    ></span>
                    <input
                      type="text"
                      defaultValue={user.instituion_simble?.name}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="rows">
                  <span>שינוי סיסמא</span>

                  <label> סיסמא ישנה</label>
                  <div>
                    {pen}{" "}
                    <input
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleInputChange(e, setOldPassword)}
                    />{" "}
                  </div>
                </div>
                <div className="rows">
                  <span></span>
                  <label>סיסמא חדשה</label>
                  <div>
                    {pen}{" "}
                    <input
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleInputChange(e, setNewPassword)}
                    />{" "}
                    <span
                      className="hover-effect"
                      title="It must be at least 8 characters, include 1 capital letter and 1 number."
                    >
                      ❕
                    </span>
                  </div>
                </div>

                <div className="rows">
                  <span></span>

                  <label>אישור סיסמא</label>
                  <div>
                    {pen}{" "}
                    <input
                      type="text"
                      defaultValue={""}
                      onChange={(e) =>
                        handleInputChange(e, setConfirmNewPassword)
                      }
                    />
                    <span onClick={handleChangePassword}> החלף סיסמא </span>
                  </div>
                </div>
              </div>
              {/* <div className='container'>





                            <div className='rows'>
                                <span>קצת עליך</span>

                                <label > ותק בהוראה </label>
                                <div>{pen}  <input type="text" defaultValue={user.roles} /> </div>

                            </div>
                            <div className='rows'>
                                <span></span>

                                <label >מוטו בחינוך</label>
                                <div>{pen}  <input type="text" defaultValue={user.gender} /> </div>

                            </div>

                        </div> */}
            </div>

            <button
              onClick={handleSubmit}
              style={{ backgroundColor: "#3C2F7F" }}
              className="btn px-5 mb-5 rounded-4 text-light mt-3"
            >
              אפשר להמשיך
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_personal_details;
