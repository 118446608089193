import React from 'react';
import { get } from 'node-emoji';

const EmojiComponent = ({ emojiName }) => {
  const emojiCharacter = get(emojiName);

  return <span className='px-2' role="img" aria-label={emojiCharacter}>
    {emojiCharacter}
  </span>
    ;
};

export default EmojiComponent;
