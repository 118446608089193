import React, { useState } from 'react';

function FileInput({ onFileUpload, onFileDelete, files, styles, readOnly }) {
    const [inputValue, setInputValue] = useState("");

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            onFileUpload(file);

            setInputValue("");
        }
    }



    return (
        <>
            <div>
                <div className="d-flex">
                    <label htmlFor="fileInput" className="form-label col-4">
                        קבצים
                    </label>
                    {/* <div className={styles.middleCell}></td> */}
                    <div>
                        <label htmlFor="fileInput" className="form-label" style={{ backgroundColor: 'rgba(215, 227, 248, 0.2)', borderRadius: '15px', padding: '5px 15px' }}>
                            לחץ לבחירת קובץ
                        </label>
                        <img style={{ width: "20px", marginRight:"10px" }} src="/addFile.jpg" alt="logo" />

                        {!readOnly && <input
                            id="fileInput"
                            className="form-control rounded rounded-4 border border-2 d-none cube-input" // Hide the default input
                            type="file"
                            onChange={handleFileUpload}
                            value={inputValue}
                            style={{ width: '100px' }}
                        /> }
                    </div>
                </div>
            </div>
            <tr>
                <td className={styles.leftCell}></td>
                <td className={styles.middleCell}></td>
                <td styles={styles.rightCell}>
                    <ul>
                        {files.map((file, index) => (

                            <li
                                key={index}
                            >
                                {file.fileName}
                               {!readOnly && <button className='badge text-danger border-0 p-0 me-2 delete-btn' style={{ fontSize: "14px", marginRight: "10px" }}

                                    onClick={() => onFileDelete(index)} >X</button>
                        }

                            </li>
                        ))}
                    </ul>
                </td>
            </tr >
        </>
    );
}

export default FileInput;
