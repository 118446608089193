import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doApiFetchUser,doApiGetClassesByInstitutionAndLayers, doApiGetClassesByInstitution, doApiGetField_of_knowledge_instituionByInstitution, doApiGetLayersByInstitution, doApiSetScheduler, doApiUpdateStudyPeriodByUser } from '../../../api_service/api_func';
import { GlobalContext } from '../../store';
import { USER } from '../../localstorage/store_name';

const Button_study_period = () => {
    // const { globalState, setGlobalData } = useContext(GlobalContext);
    const nav = useNavigate()
    const [user, setUser] = useState({});
    const [userid, setUserid] = useState(localStorage.getItem(USER));

    // const [study_period_class, setStudyPeriodClass] = useState({});
    const [age_group_names, set_age_group_names] = useState([]);
    const [classes, setClasses] = useState([]);
    const [fieldOfKnowledgeInstituion, setFieldOfKnowledgeInstituion] = useState([]);
    const { institution_id, study_period_id, Method, study_period_class_id } = useParams();
    const [layer, setLayer] = useState("");
    const [_class, setClass] = useState("");
    const [fieldOfKnowledge, setFieldOfKnowledge] = useState("");
    // Update the global state using setGlobalData
    // const updateGlobalState = (data) => {
    //     setGlobalData({ ...data });
    // };

    useEffect(() => {

        // doApiFetchUser(setUser);
        doApiGetLayersByInstitution(institution_id, set_age_group_names);
        doApiGetClassesByInstitution(institution_id, setClasses);
        doApiGetField_of_knowledge_instituionByInstitution(institution_id, setFieldOfKnowledgeInstituion);
        // doApiGetClassesByInstitutionAndLayers(institution_id, layer, setClasses)

    }, [institution_id])

    const handleSelectLayer = (event) => {
        const value = event.target.value;
        if(value != ''){
            setLayer(parseInt(event.target.value, 10));
            doApiGetClassesByInstitutionAndLayers(institution_id,event.target.value,setClasses)
        }  
    };

    const handleSelectClass = (event) => {

        setClass(parseInt(event.target.value, 10));
    };

    const handleSelectFieldOfKnowledge = (event) => {


        setFieldOfKnowledge(parseInt(event.target.value, 10));
    };

    const sendData = async () => {
        const data = { 
            // age_group_entity: layer, 
            class: _class, 
            field_of_knowledge: fieldOfKnowledge, 
            user: parseInt(userid, 10), 
            StudyPeriod: parseInt(study_period_id, 10) };
        if (Method === "GET") {
            if (await doApiSetScheduler(data)) {
                alert("רשומה הוספה בהצלחה!");
                // updateGlobalState({...data});

                nav(-1)
            }
            else {
                alert("שגיאה נסה מאוחר יותר")
                nav(-1)
                return null;
            }
        }
        else {
            try {
                const data2 = {
                    age_group_entity: layer,
                    class: _class,
                    fieldOfKnowledge: fieldOfKnowledge,
                    user: parseInt(userid, 10),
                    StudyPeriod: parseInt(study_period_id, 10)
                };


                if (await doApiUpdateStudyPeriodByUser(study_period_class_id, data2)) {
                    alert("רשומה עודכנה בהצלחה!");

                    nav(-1)
                }
                else {
                    alert("שגיאה נסה מאוחר יותר")
                    return null;
                }
            } catch (error) {
                alert(error)
            }
        }
    }



    return (

        <div>

            {Method === "GET" ? <h2 className='text-center'>הוספת רשומה חדשה</h2> : <h2 className='text-center'>עדכון רשומה קיימת</h2>}
            {fieldOfKnowledgeInstituion.length > 0 ? <div className='container-fluid pb-5'>
            {/* {age_group_names.length > 0 && classes.length > 0 && fieldOfKnowledgeInstituion.length > 0 ? <div className='vh-100 container-fluid'> */}
                <div style={{maxWidth:"750px", position:"relative"}} className='container pt-5'>
                    <div className='scheduler bg-light text-center rounded-5 shadow-lg pt-5' >
                        <div style={{position:'absolute',top:"20px", right:"44%",height: "70px",
                        borderRadius: "50%",
                        backgroundColor: "rgba(var(--bs-light-rgb), var(--bs-bg-opacity))"}}>
                            <img style={{ width: "100px" }} src="/logoTakingoff.png" alt="logo" />
                        </div>
                        {user &&
                            <div className='user_data'>
                                <p>{user.first_name}</p>
                            </div>
                        }
                        <div>
                            <p>שלב 2 מתוך 2</p>
                            <h2 className='fw-bold'>בואו נבנה את המערכת שלכם</h2>
                            {/* <p>לחצו על הזמנים בהם אתם מלמדים את אותו תחום דעת באותה הכיתה</p> */}
                       <p>בחרו תחום דעת, שכבת גיל ומספר כיתה בהתאם לזמנים שסימנתם</p>
                        </div>

                        <div className='row  align-items-center justify-content-center'>
                            <label className='col-3 fw-bold'>השכבה שאתם מלמדים:</label>
                            <div className='col-5'>
                                <select className='form-select' value={layer} onChange={handleSelectLayer}>
                                    <option value="">בחר שכבה</option>
                                    {age_group_names.length > 0 ? age_group_names.map((age_group, index) => {

                                        return (
                                            <option key={index} value={age_group.id}>{age_group.name}</option>
                                        )
                                    }) : ""}
                                </select>
                            </div>
                        </div>
                        <div className='row align-items-center justify-content-center'>
                            <label className='col-3 fw-bold'>מס כיתה:</label>
                            <div className='col-5'>
                                <select className='form-select' value={_class} onChange={handleSelectClass}>
                                    <option value="">בחר כיתה</option>
                                    {classes.length > 0 ? classes.map((c, index) => {
                                        return (
                                            <option key={index} value={c.id}> {c.name} {c.number}</option>
                                        )
                                    }) : ""}
                                </select>
                            </div>
                        </div>
                            <div className='row align-items-center justify-content-center'>
                                <label className='col-3 fw-bold'>בחר תחום דעת:</label>
                                <div className='col-5'>
                                    <select className='form-select' value={fieldOfKnowledge} onChange={handleSelectFieldOfKnowledge}>
                                        <option value="">בחר תחום דעת</option>
                                        {fieldOfKnowledgeInstituion.length > 0 ? fieldOfKnowledgeInstituion.map((fieldOfKnowledgeInstituion, index) => {

                                            return (
                                                <option key={index} value={fieldOfKnowledgeInstituion.FieldOfKnowledge?.id}>{fieldOfKnowledgeInstituion.FieldOfKnowledge?.name}</option>
                                            )
                                        }) : ""}
                                    </select>
                                </div>
                            </div>
                            <button onClick={sendData} className='w-25  btn-primary btn my-4'>סיימתם לחצו כאן</button>
                    </div>
                </div>
            </div> : ""}
        </div>
    );
};

export default Button_study_period;
