import React, { useEffect, useState } from "react";
import Table from "./Table";
import {
  doApiGetAllCLassLesson,
  getClassLessinByClass,
  addAbsenceToUserClass,
} from "../../api_service/api_func";
import "./week_calendar.css";
import { ROLE, Instituion_id } from "../localstorage/store_name";
import { Button, Modal } from "@mui/material";
import MyModalContent from "../MyModal.jsx";
import CalendarSelect from "./calendarSelect.jsx";
import axios from "axios";
import { doApiFetchAzureToken } from "../../api_service/api_func";
import MicrosoftLogin from "react-microsoft-login";
import Search_input from './Search_input';
import './week_calendar.css';
import ClassInput from './Class_input';
import { useGoogleLogin } from '@react-oauth/google';
import { syncGoggleCalendarWithoutAuth ,syncGoggleCalendar} from '../Google/GoogleApi.js'
import { USER } from '../localstorage/store_name';
import { CustomLoginButton } from "../microsoftButton.jsx";
const clientId = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
const uri = process.env.REACT_APP_REDIRECT_URI;

const Week_calender = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [allLesson, setAllLesson] = useState([]);
  const [allAbsence, setAllAbsence] = useState([]);
  const [selectClass, setSelectClass] = useState("");
  const [currentUserRole, setCurrentUserRole] = useState(
    localStorage.getItem(ROLE)
  );
  const [instituion_id, setInstitutionId] = useState(
    localStorage.getItem(Instituion_id)
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userid, setUserid] = useState(localStorage.getItem(USER));

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const modalContent = {
    title: "הוספת היעדרות",
    content: "הגדר יום ושעה",
    params: {
      // arr : lessons.map(l=>l.order)
      arr: [],
    },
  };

  const handleConfirm = () => {
    // Handle the selected option or perform any necessary actions
    handleCloseModal();
  };

  useEffect(() => {
    const { startDate, endDate } = getWeekStartAndEndDates();
    handleDatesChange(startDate, endDate);
  }, []);

  const handleDatesChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    fetchData(start, end, selectClass);
  };

  const fetchData = (startDateWeek, endDateWeek, selectClass) => {
    if (currentUserRole === '"admin"') {
      const body = {
        instituion_id: instituion_id,
        classId: selectClass,
        startDate: startDateWeek,
        endDate: endDateWeek,
      };
      return getClassLessinByClass(body, setAllLesson);
    } else {
      doApiGetAllCLassLesson(
        startDateWeek,
        endDateWeek,
        setAllLesson,
        setAllAbsence
      );
    }
  };

  const handleSelectClass = (event) => {
    if (event != "") {
      setSelectClass(event);
      fetchData(startDate, endDate, event);
    }
  };

    const syncToGoogleCalendar = async () => {
  // login()
//   const isSync = await syncGoggleCalendarWithoutAuth(10)
//   if(isSync){
      // window.location.reload()
//   }else{
//    login()
//   }
}
const login = useGoogleLogin({
    onSuccess: tokenResponse => {
        syncCalendar(tokenResponse);
    },
    onError: error => console.log(error),
    scope:'https://www.googleapis.com/auth/calendar'
  });

  const syncCalendar = async (token) => {
    const accessToken = await syncGoggleCalendar({
        userId:userid,
        accessToken: token.access_token
    })
    if(accessToken){
        window.location.reload()
    }
}

    const handleTableEvents = async (event) => {
        if (addAbsenceToUserClass(event)) {
          window.location.reload()
          // syncToGoogleCalendar()
        }
    };
  function getWeekStartAndEndDates() {
    const today = new Date();
    const currentDay = today.getDay();
    const startDate = new Date(today.setHours(0, 0, 0));
    startDate.setDate(today.getDate() - currentDay);
    const endDate = new Date(today.setHours(23, 59, 59));
    endDate.setDate(today.getDate() + (5 - currentDay));

    return {
      startDate,
      endDate,
    };
  }

  const authHandler = async (err, data) => {
    if (err) {
      console.error(err);
      // Handle errors
    } else {
      localStorage.azure = data.accessToken;
      try {
        const response = await doApiFetchAzureToken(data.accessToken);
        if (response) {
          alert("Outlook calendar has been synchronized");
        }
      } catch (error) {
        // Handle any errors here
      }
      // Proceed with other operations
    }
  };

  return (
    <div className="week-table-wrapper">
      <div className="control-row">
        <Search_input
          startDate={startDate}
          endDate={endDate}
          handleDatesChange={handleDatesChange}
        />
        {currentUserRole == '"admin"' && (
          <ClassInput
            selectClass={selectClass}
            handleClassSelect={handleSelectClass}
          />
        )}
        {/* <button className='button'
            onClick={handleOpenModal}>הוסף חופשה</button> */}
        <div>
          {" "}
          {currentUserRole == '"user"' && ( <MicrosoftLogin className="microsoft-login-button"
            clientId={clientId}
            authCallback={authHandler}
            redirectUri={uri}
            graphScopes={["user.read", "Calendars.ReadWrite"]}
            buttonTheme="dark_short"
          >
            <CustomLoginButton />
           </MicrosoftLogin>)}
        </div>
      </div>

      <Table
        startDate={startDate}
        data={allLesson}
        absence={allAbsence}
        eventTable={handleTableEvents}
      />
      <div>
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {/* <MyModalContent
            content ={modalContent}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirm}
        /> */}

          <CalendarSelect />
        </Modal>
      </div>
    </div>
  );
};

export default Week_calender;
