
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './week_calendar.css'

const DateRangePicker = ({ startDate, endDate, onDatesChange }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const handleDateChange = (date) => {
    if (date) {
      const selectweek = getWeekStartAndEndDates(date)      
      setSelectedStartDate(selectweek.startDate)
      setSelectedEndDate(selectweek.endDate)
      onDatesChange(selectweek.startDate, selectweek.endDate)
    } else {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
      setSelectedDate(null);
    }
  };

  function getWeekStartAndEndDates(date) {
    const today = new Date(date);
    const currentDay = today.getDay(); // 0 is Sunday, 1 is Monday, and so on
    const startDate = new Date(today.setHours(0,0,0));
    startDate.setDate(today.getDate() - currentDay); // Set to the most recent Sunday
    const endDate = new Date(today.setHours(23,59,59));
    endDate.setDate(today.getDate() + (5 - currentDay)); // Set to the upcoming Friday

    return {
        startDate,
        endDate,
    };
}



  return (
    <div className="d-flex date-picker-wrapper">
      <DatePicker
        selected={selectedStartDate ? selectedStartDate : null}
        onChange={handleDateChange}
        startDate={selectedStartDate ? selectedStartDate : null}
        endDate={selectedEndDate ? selectedEndDate : null}
        placeholderText="בחרו שבוע"
        className="form-select rounded-5 DateRangePicker"
        // maxDate={new Date()} // Prevent future dates from being selected
        value={selectedStartDate ? new Date(selectedStartDate).toLocaleDateString('HE') + ' - ' + new Date(selectedEndDate).toLocaleDateString('HE') : null}
      />
    </div>
  );
};

export default DateRangePicker;
