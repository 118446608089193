import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart } from 'chart.js';

// Register ArcElement
Chart.register(ArcElement);

const DonutChart = ({ lesson, color }) => {

    const bcolor = color || "#2B83C780"



    const color1 = lesson[1] / lesson[0]
    const color2 = Math.max(0, lesson[2] / lesson[0] - color1);
    const color3 = Math.max(0, 1 - color2 - color1)


    const data = {
        labels: ['#2B83C780', '#84C8FF80', '#D7D7D780'],
        datasets: [{
            data: [color1, color2, color3],
            borderWidth: 0,
            backgroundColor: [bcolor, '#84C8FF80', '#D7D7D780'],
            hoverBackgroundColor: ['#2B83C780', '#84C8FF80', '#D7D7D780']
        }]
    };
    const options = {
        cutout: '60%'
    };
    return <Doughnut data={data} options={options} />
}

export default DonutChart;
