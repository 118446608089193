import React, { useState } from 'react';

export const LinkAndFile = ({ file, setFile, link, setLink, styles }) => {
  const [linktemp, setlinktemp] = useState();
  // const [files, setFiles] = useState([]);

  const handleAddLink = () => {
    setLink([...link, linktemp]);
    setlinktemp("");
  };

  const handleLinkChange = (event) => {
    setlinktemp(event.target.value);

  };

  const handleRemoveLink = (index) => {
    setLink(link.filter((_, i) => i !== index));
  };

  const handleFileChange = (event) => {
    setFile([...file, "event.target.file"]);
    // setFile([...file, event.target.file]);
  };

  const handleRemoveFile = (index) => {
    setFile(file.filter((_, i) => i !== index));
  };

  return (
    <>
      {/* <tr >
        <td className={styles.leftCell}>
          קבצים
        </td>
        <td className={styles.middleCell}></td>
        <td styles={styles.rightCell}>
          <label>

            בחר קובץ
            <img style={{ width: "20px" }} src="/addFile.jpg" alt="logo" />
            <input id='input-file-style' type="file" onChange={handleFileChange} multiple style={{ display: 'none' }} />
          </label>
        </td>

      </tr>
      <tr>
        <td className={styles.leftCell}>
        </td>
        <td className={styles.middleCell}></td>
        <td >
          {file.map((file, index) => (
            <div className="subtitle-row relative-style" key={index} style={{ display: 'flex', marginBottom: '8px' }}>
              <span>קובץ </span>
              <span className='subtitle-icon' onClick={() => handleRemoveFile(index)}>X</span>
            </div>
          ))}
        </td>
      </tr> */}
      <div className='d-flex mt-2'>
        <div className='col-4'>
          קישורים
        </div>
        {/* <div className={styles.middleCell}></div> */}
        <div>
          <div className='relative-style  unset-felx'>
            <span className='subtitle-icon' onClick={handleAddLink}><img style={{ width: "20px" }} src="/addFile.jpg" alt="logo" /></span>
            <input className="subtitle-row unborder cube-input rounded-4" type="text" onChange={handleLinkChange} value={linktemp} placeholder='הכנס לינק' multiple />
          </div>
        </div>
      </div>
      <div className='d-flex w-75'>
        <div className='col-4'>
        </div>
        {/* <td className={styles.middleCell}></td> */}
        <div >
          {link.map((link, index) => (
            <div className="subtitle-row relative-style" key={index}>{link}<span className='subtitle-icon' onClick={() => handleRemoveLink(index)}>X</span></div>
          ))}
        </div>
      </div>
    </>
  )
}

