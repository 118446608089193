import { useEffect, useState } from 'react';
import './Scores.css'
import { ScoreItem } from './ScoreItem'
import {ReactComponent as ContributingCommunity} from '../../images/contributing_to_community.svg'
import { BASE_URL, makeRequest } from '../../api_service/api_func';



export const Scores = () => {

    const [data, setData] = useState([
        {
            name: "cubeUploaded",
            icon: "cubeUploaded",
            text: "קוביות העלת עד היום",
            num: "0"
        },
        {
            name: "rating",
            icon: "rating",
            text: "פעמים דירגת ושיפרת קוביות",
            num: "0"
        },
        {
            name: "wereChosen",
            icon: "were_chosen",
            text: "מהקוביות שלך נבחרו ע”י מורים",
            num: "0"
        },
        {
            name: "score",
            icon: "score",
            text: "הניקוד שלך עד לתג הבא",
            num: "0"
        },
    ]);
    
    // const Teachdtat  =  makeRequest('GET', "url", {});

    useEffect (
         () => {
            const numUploaded= 25;
            const nuRating= 10 ;
            const numChosen= 12;
            const numScore = "17/20";
            
            const copyData = [...data];
            for (const item of copyData) {           
                switch (item.name) {
                    case "cubeUploaded":
                        item.num = numUploaded
                        break;
                    case "rating":
                        item.num = nuRating
                        break;
                    case "wereChosen":
                        item.num = numChosen
                        break;
                    case "score":
                        item.num = numScore
                        break;
                    default:
                        break;
                }
            }
            setData(copyData);
    }
  ,[])

    return(
        <div className="wrap">
            <div className="header d-flex flex-column w-75 mx-auto">
                <div className='d-flex flex-row align-items-center'>
                    <div className="icon" ><ContributingCommunity/> </div>
                    <div className="title">תרומה לקהילה</div>
                </div>
                <hr />
            </div>
            <div className="scores">
                {data.map((item)=> (
                    <ScoreItem icon={item.icon} num={item.num} text={item.text}></ScoreItem>
                ))}
            </div>
        </div>
    )

}

export default Scores;