import React, { useState } from 'react';

const Checkbox = ({ label, onChange }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);

    // Call the onChange callback if provided
    if (onChange) {
      onChange(isChecked);
    }
  };

  return (
    <div>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
        />
    </div>
  );
};

export default Checkbox;
