import React, { useEffect, useState } from "react";
import "../css/lightBox.css";
import Navigator from "./Navigator";
import { useNavigate } from "react-router-dom";
import { doApiFetchUser } from "../api_service/api_func";
function LightBox() {
  const nav = useNavigate();
  const [user, setUser] = useState({});

  useEffect(() => {
    // doApiFetchUser(setUser);
  }, []);

  return (
    <div
      style={{ paddingTop: "10px", paddingBottom: "10px" }}
      className="container  vh-100 d-flex align-items-center justify-content-center "
    >
      <div
        className=" lightbox-wrapper w-50 mx-auto text-center bg-light rounded rounded-5"
        style={{ minHeight: "500px" }}
      >
        <div className="lightbox-container">
          <img
            style={{
              position: "absolute",
              top: "-50px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100px",
              paddingLeft: "2px",
              height: "90px",
              borderRadius: "50%",
              backgroundColor:
                "rgba(var(--bs-light-rgb), var(--bs-bg-opacity))",
            }}
            src="/logoTakingoff.png"
            alt="logo"
          />
        </div>

        <div style={{ marginTop: "100px" }}>
          <h2 style={{ fontSize: "30px", fontWeight: "bold" }}>יוצאים לדרך!</h2>
        </div>
        <p style={{ paddingTop: "40px" }} className="lead fw-medium">
          שאלון קצר שיעזור לנו
          <br /> להתאים עבורך את המערכת.
        </p>

        <button
          style={{ marginTop: "70px" }}
          onClick={() => nav("/user/update_personal_details")}
          className="btn btn-primary rounded rounded-5 px-5"
        >
          שנתחיל?
        </button>

        <div className=" ">
          {/* <Navigator props={{ route: "/takingoff", name: "דלג בינתיים", color: "blue" }} /> */}
          <Navigator
            props={{ route: "/scheduler", name: "דלג בינתיים", color: "blue" }}
          />
        </div>
      </div>
    </div>
  );
}

export default LightBox;
