import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="container text-center  ">
      <div style={{marginTop:"250px"}}>
      <h1 className="display-4">404 - Page Not Found</h1>
      <p className="lead font-monospace">העמוד שחיפשת לא נמצא.</p>

      </div>
    </div>
  );
};

export default NotFoundPage;
