import React from 'react'
import Input from './Input'
// import { GlobalContext } from './store';
import { BASE_URL, makeRequest } from '../api_service/api_func';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
function Verify_temp_pass() {
    // const { globalState } = useContext(GlobalContext);
    // const { data } = globalState;
    const nav = useNavigate();
    const [temp_pass, setTempPass] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [pass, set_pass] = React.useState("");
    const [confirmPass, setConfirmPass] = React.useState("");
    const doApi = async (data) => {
        console.log(data);
        const res = await makeRequest('POST', `${BASE_URL}/auth/resetPass`, { email: data.email, password: data.password, temp_password: data.temp_pass });
        return res;

    }
    const onClick = async () => {
        if (temp_pass.length > 0) {

            if (pass !== confirmPass || temp_pass.length !== 6) {
                toast.error("Passwords do not match");
                return;
            }

            const user = { email, temp_password: temp_pass, password: pass };
            try {
                const _data = await doApi(user);
                console.log(_data);
                nav("/")

            } catch (error) {
                toast.error(`${error}`);
                nav("/tempPass")
                return null;
            }


        }


    }

    const handleTempPass = (event) => {
        setTempPass(event.target.value);
    };

    const handlePass = (event) => {
        set_pass(event.target.value);
    };
    const handleConfirmPass = (event) => {
        setConfirmPass(event.target.value);
    };

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };

    return (
        <div style={{paddingTop:"10px",paddingBottom:"10px"}} className='container vh-100 d-flex align-items-center justify-content-center '>

            <div className='lightbox-wrapper mx-auto w-50 text-center bg-light rounded-4 mt-5' style={{ position: "relative" }}>
            <img style={{
                        position: "absolute", top: "-50px", left: "50%",
                        transform: "translateX(-50%)",
                        width: "100px",
                        paddingLeft: "2px",
                        height: "90px",
                        borderRadius: "50%",
                        backgroundColor: "rgba(var(--bs-light-rgb), var(--bs-bg-opacity))"
                    }}
                        src="/logoTakingoff.png" alt="logo" />
                <div className='d-flex flex-column justify-content-center align-items-center mt-5 mb-3'  >


                    <h2 className='disply-4'>ברוכים הבאים ל-TEACH</h2>
                    <div className="mx-auto mt-4">
                        <div>
                            <Input props={{
                                onChange: handleTempPass,
                                value: temp_pass,
                                name: "סיסמא זמנית",
                                type: "text",
                                req: true
                            }} />

                        </div>
                        <div>
                            <Input props={{
                                onChange: handleEmail,
                                value: email,
                                name: "אימייל",
                                type: "email",
                                req: true
                            }} />

                        </div>
                        <div>
                            <Input props={{
                                onChange: handlePass,
                                value: pass,
                                name: "סיסמא",
                                type: "password",
                                req: true
                            }} />

                        </div>
                        <div>
                            <Input props={{
                                onChange: handleConfirmPass,
                                value: confirmPass,
                                name: "אימות סיסמא",
                                type: "password",
                                req: true
                            }} />

                        </div>
                        <button className='btn btn-info' onClick={() => {
                            onClick();
                        }}>שלח</button>

                    </div>
                </div>
            </div>





        </div>
    )
}

export default Verify_temp_pass