import React, { useState } from 'react'
import "../css/lightBox.css"
// import { useNavigate } from 'react-router-dom'
function CompleteDetails() {
    const [selectValues, setSelectValues] = useState({
        instituion_simble: '',
        school_name: '',
        age_group: '',
        division_year: ''
    });

    const handleSelectChange = (selectName, selectedValue) => {
        setSelectValues(prevState => ({
            ...prevState,
            [selectName]: selectedValue
        }));
    };

    const handleSubmit = e => {
        e.preventDefault();
        // Send request with selectValues to the server
        console.log('Select values:', selectValues);
        // Reset select values

        setSelectValues({
            instituion_simble: '',
            school_name: '',
            age_group: '',
            division_year: ''
        });
    };

    return (
        <div className="container-fluid">
            <div id='main' className="container" style={{paddingBottom:"100px"}}>
                <div className='border border-3 mx-auto w-50 text-center bg-light shadow rounded-4 mt-5' style={{ minHeight: "500px ", position: "relative" }}>
                     <img style={{
                         position: "absolute", top: "-50px", left: "50%",
                         transform: "translateX(-50%)",
                         width: "100px",
                         paddingLeft: "2px",
                         height: "90px",
                         borderRadius: "50%",
                         backgroundColor: "rgba(var(--bs-light-rgb), var(--bs-bg-opacity))"
                     }}
                         src="/logoTakingoff.png" alt="logo" />
                         <div className='d-flex justify-content-center align-items-center mt-5'  >
                         <div>
                            <p>שלב 2 מתוך 2</p>
                     </div>
                     </div>
            <form onSubmit={handleSubmit} className='mx-auto rounded rounded-3 p-4  row'>
            <div className='text-center'>
            <h3 style={{fontSize:"1.5rem" }}>משלימים כמה פרטים אחרונים...</h3 >
            <p>סמנו את שכבת הגיל אצלכם וכיצד השנה מתחלקת</p>

            </div >
            <div className='d-flex mb-3'>

                <label className='form-control w-50 border-0'>
                    סמל מוסד
                </label>

                <select
                    value={selectValues.instituion_simble}
                    onChange={e => handleSelectChange('instituion_simble', e.target.value)}
                    className='form-select w-50 border-0 '
                    required
                >
                    <option value="">-- בחרו אפשרות --</option>
                    <option value="option1">dasfaf</option>
                    <option value="option2">Option 2</option>
                </select>
            </div>
                <br /><br />
                <div className='d-flex mb-3'>
                <label className='form-control w-50 border-0'>
                    שם מוסד לימודי
                </label>

                <select
                    value={selectValues.school_name}
                    onChange={e => handleSelectChange('school_name', e.target.value)}
                    className='form-select w-50'
                    required
                >
                    <option value="">-- בחרו אפשרות  --</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                </select>
                </div>
                <br /><br />
                <div className='d-flex mb-3'>
                <label className='form-control w-50 border-0'>
                    בחרו שכבת לימוד
                </label>

                <select
                    value={selectValues.age_group}
                    onChange={e => handleSelectChange('age_group', e.target.value)}
                    className='form-select w-50'
                    required
                >
                    <option value="">-- בחרו אפשרות  --</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                </select>
                </div>
                <br /><br />
                <div className='d-flex mb-3'>
                <label className='form-control w-50 border-0'>
                    חלוקת שנה
                </label>

                <select
                    value={selectValues.division_year}
                    onChange={e => handleSelectChange('division_year', e.target.value)}
                    className='form-select w-50'
                    required
                >
                    <option value="">-- בחרו אפשרות  --</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                </select>
                </div>
                <br /><br />
                <div className=''>

                <button style={{fontSize:"13px" }} className='btn btn-primary mt-4 float-start rounded rounded-5 px-5' type="submit">הכל מדוייק! <br></br>
                אפשר להמשיך
                </button>

                </div>
            </form>
            </div>
          </div>
        </div>

    );

    // return (
    //     <div className='container-fluid'>
    //         <AuthComponent />
    //         <div id='main' className="container" style={{paddingBottom:"100px"}}>

    //             <div className='border border-3 mx-auto w-50 text-center bg-light shadow rounded-4 mt-5' style={{ minHeight: "500px ", position: "relative" }}>
    //                 <img style={{
    //                     position: "absolute", top: "-25px", left: "50%",
    //                     transform: "translateX(-50%)",
    //                     width: "100px",
    //                     paddingLeft: "2px",
    //                     height: "70px",
    //                     borderRadius: "50%",
    //                     backgroundColor: "rgba(var(--bs-light-rgb), var(--bs-bg-opacity))"
    //                 }}
    //                     src="/logoTakingoff.png" alt="logo" />
    //                 <div className='d-flex justify-content-center align-items-center mt-5'  >
    //                     <div>
    //                         <p>שלב 1 מתוך 2</p>

    //                         <h3 className='fw-bold fs-30'>מילוי פרטים אישיים</h3>
    //                         <ImageUpload />


    //                     </div>
    //                 </div>
    //                 <div>

    //                     <label className='px-2 fw-bold mt-2'>  אימות פרטים </label>
    //                     <div className='d-flex'>

    //                     </div>

    //                     <br />
    //                     <div style={{ padding: "0px 100px"}} className='' >
    //                         <div className='d-flex mb-1'>

    //                             <label className='col-2' >שם משפחה</label>
    //                             <input className="me-4 col-3 form-control w-75" type="text" defaultValue={user.last_name} />

    //                         </div>
    //                         <div className='d-flex mb-1'>

    //                             <label className='col-2'> שם פרטי </label>
    //                             <input type="text" className='me-4 form-control w-75' defaultValue={user.first_name} />

    //                         </div>
    //                         <div className='d-flex mb-1'>
    //                             <label className='col-2' >טלפון נייד</label>
    //                             <input className='me-4 form-control w-75' type="text" defaultValue={user.phone} />
    //                         </div>
    //                         <div className='d-flex mb-1'>
    //                             <label className='col-2'>אימייל</label>
    //                             <input className='me-4 form-control w-75' type="text" defaultValue={user.email} />
    //                         </div>
    //                     </div>
    //                 </div>

    //                 <button
    //                     onClick={() => {
    //                         nav('/user/takingoff');
    //                     }}

    //                     style={{ backgroundColor: "#3C2F7F" }}
    //                     className='btn px-5 mb-5 rounded-4 text-light mt-3'
    //                 >

    //                     אפשר להמשיך
    //                 </button>
    //             </div>
    //         </div>


    //     </div>
    // )
};

export default CompleteDetails