import React from 'react';
import styles from './skillCard.module.css';
//import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Button, Typography } from '@mui/material';
import DonutChart from './DonutChart';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const SkillCard = ({ number, title, lesson, classId, skillId }) => {

    // console.log('\n\n\n lesson', lesson, '\n\n\n ');
    const nav = useNavigate();
    const state = {
        id_class: classId, // Replace with the actual value
        id_skill: skillId, // Replace with the actual value
        title
    };
    return (
        <div className={styles.card}>
            <div className={`${styles.rightSection} ${styles.numberClass}`}>{number}</div>
            <div className={`${styles.mainSection}`} >
                <div className={`${styles.row1} ${styles.titleClass} d-flex flex-row justify-content-between`}>{title}

                    <div>
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="move-left-right-thin 10" clip-path="url(#clip0_1184_20240)">
                                <path id="Vector" d="M6.17125 14.933L3.0269 11.682C2.71796 11.3908 2.52487 10.973 2.52487 10.5085C2.52487 9.6324 3.21178 8.92221 4.05912 8.92221C4.50746 8.92221 4.91089 9.12104 5.19141 9.43806L5.19247 9.43926L5.47693 9.73335V4.51352C5.4769 4.50954 5.47688 4.50481 5.47688 4.50012C5.47688 3.69929 6.05268 3.03754 6.7996 2.93323L6.80747 2.93233H6.81971C6.85674 2.92893 6.8998 2.927 6.9433 2.927C7.75881 2.927 8.42046 3.60788 8.42552 4.44978V7.79292L11.2218 8.20909C11.9414 8.32013 12.4874 8.95307 12.4905 9.71847V9.71881C12.4899 11.548 12.0666 13.2748 11.3163 14.8008L11.3448 14.7369L11.2897 14.8507L10.7428 14.568L10.7979 14.4541C11.479 13.0744 11.8783 11.4449 11.8788 9.71893V9.71877C11.8782 9.27029 11.5591 8.89906 11.1428 8.83392L11.138 8.83331L7.81685 8.33871V4.45024V4.44909C7.81685 4.20633 7.72093 3.98673 7.56612 3.82859L7.56176 3.8241C7.40773 3.66093 7.19292 3.55976 6.95525 3.55976C6.93016 3.55976 6.90533 3.56089 6.88077 3.56311L6.88396 3.56287C6.43397 3.62884 6.09171 4.02445 6.09171 4.50269C6.09171 4.50581 6.09171 4.50894 6.09175 4.51208V4.51158V11.2601L4.76424 9.88577C4.59725 9.71295 4.36646 9.60605 4.11153 9.60605C3.60196 9.60605 3.18887 10.0331 3.18887 10.56C3.18887 10.8232 3.29201 11.0616 3.4588 11.2342L6.59948 14.4851L6.17125 14.933ZM11.8568 5.27439L11.4236 4.82786L12.9579 3.23653L11.4237 1.65093L11.8562 1.2038L13.8217 3.23593L11.8568 5.27439ZM13.3898 3.55216H9.30336V2.91968H13.3898V3.55216ZM2.07627 5.27439L0.111328 3.23529L2.07627 1.20312L2.50874 1.6503L0.975128 3.23656L2.50938 4.82789L2.07627 5.27439ZM4.62963 3.55216H0.543235V2.91968H4.62966L4.62963 3.55216Z" fill="#4B4D4F" fill-opacity="0.28" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1184_20240">
                                    <rect width="13.7109" height="14.2736" fill="white" transform="translate(0.11084 0.917969)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div className={styles.row2}>
                    <div className={styles.rightSection2}>
                        <div className={styles.textContainer}>
                            <div className={`${styles.circle} ${styles.circle1}`}></div>
                            <div className={styles.textLine}>
                                <span className={styles.boldText}>{lesson[1]}</span> מתוך <span className={styles.boldText}>{lesson[0]}</span> שיעורים נלמדו
                            </div>
                        </div>
                        <div className={styles.textContainer}>
                            <div className={`${styles.circle} ${styles.circle2}`}></div>
                            <div className={styles.textLine}><span className={styles.boldText}>{lesson[2]}</span> הוכנו</div>
                        </div>
                        <div className={styles.textContainer}>
                            <div className={`${styles.circle} ${styles.circle3}`}></div>
                            <div className={styles.textLine}><span className={styles.boldText}>{lesson[0] - lesson[2]}</span> לא הוכנו</div>
                        </div>
                    </div>
                    <div className={styles.leftSection}>
                        <DonutChart lesson={lesson} />
                    </div>
                </div>
                <div className={styles.row3}>
                    <Button
                        variant="contained"
                        className={styles.buttonStyle}
                        style={{ fontSize: '13px' }}
                        onClick={() => {
                            nav('/user/study_program', { state });
                        }}
                    >
                        מעבר לשיעורים
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SkillCard;
