import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../src/css/breadcrumb.css'
const Breadcrumbs = () => {
    const location = useLocation();
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const navigate = useNavigate();

    console.log("location333", location);
    const getLabel = (pathname) => {
        const pathMap = {
            'classSum': 'הכיתות שלי',
            'dashboard-teacher': 'לוח בקרה',
            'skillsSum': 'מיומנויות',
            'study_program': 'תוכנית שיעורים',
            'create_lesson': ' שיעור',
            'cubeDetails': 'קוביה'
        };

        for (const key in pathMap) {
            if (pathname.includes(key)) {
                return pathMap[key];
            }
        }

        return pathname; // Default label if no match is found
    };

    useEffect(() => {
        const pathnames = location.pathname.split('/').filter(x => x);
        const title = location.state?.title

        setBreadcrumbs(prevBreadcrumbs => {
            const label = title || getLabel(pathnames);
            const newPath = { path: location.pathname, state: location.state, label };
            const existingPathIndex = prevBreadcrumbs.findIndex(breadcrumb => breadcrumb.path === location.pathname || title === breadcrumb.state?.title);

            if (existingPathIndex === -1) {
                return [...prevBreadcrumbs, newPath];
            } else {
                return prevBreadcrumbs.slice(0, existingPathIndex + 1);
            }
        });
    }, [location]);

    return (
        <nav>
            <div className='linkcontainer' >
                {breadcrumbs.map((breadcrumb, index) => {
                    const isLast = index === breadcrumbs.length - 1;
                    return (
                        <React.Fragment key={breadcrumb.path}>
                            <div>
                                {isLast ? (
                                    <div>{breadcrumb.label}</div>
                                ) : (
                                    <div className="breadcrumb-link"
                                        onClick={() => navigate(breadcrumb.path, { state: breadcrumb.state })}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {breadcrumb.label}
                                    </div>
                                )}
                            </div>
                            {!isLast && <span className="separator"> {' > '} </span>}
                        </React.Fragment>
                    );
                })}
            </div>
        </nav>
    );
}

export default Breadcrumbs; 