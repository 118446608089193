import React from 'react'
import '../css/input.css'
function Input(props) {
    const flag = props.props.req;
    // const defalut_val = props.props.req || "";


    return (
        <div className="mb-3 d-flex">
            <div className="col-4">
                <label className="form-label">{props.props.name}</label>

            </div>
            <input
             
                type={props.props.type}
                className={`${props.props.disabled ? 'border-0' : ''} form-control rounded rounded-4 border border-2 ${props.props.class}`}
                onChange={props.props.onChange}
                required={flag}
                value={props.props.value}
                placeholder={props.props.placeholder}
                disabled={props.props.disabled}                
            />
        </div>
    )
}

export default Input