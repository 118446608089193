export function setValuesFromObject(data, setters) {
    const {
      title,
      subtitle,
      body,
      level_1,
      level_2,
      level_3,
      book,
      start_page,
      end_page,
      files,
      id_system_lesson,
    } = data;
  
    const {
      setTitle,
      setSubTitle,
      setBody,
      setLevel1,
      setLevel2,
      setLevel3,
      // setBooks,
      // setPages,
      setStartPage,
      setEndPage,
      setSelectedBookId,
      setFiles
    } = setters;
  
    setTitle(title);
    setSubTitle(subtitle);
    setBody(body);
    setLevel1(level_1);
    setLevel2(level_2);
    setLevel3(level_3);
    // setBooks(book);
    // setPages(start_page, end_page);
    setStartPage(start_page);
    setEndPage(end_page);
    setSelectedBookId(book ? book.id : ''); // You may need to adjust this part based on your data structure
    setFiles(files);
  }
