import React, { useEffect, useState } from 'react';
import Input from '../Input';
import EmojiComponent from '../EmojiComponent ';
import { useNavigate, useParams } from 'react-router-dom';
import CreateNewCube from '../Cube/CreateNewCube';
import { doApiCreateEnrichLesson ,doApiCreateSystemLessonGoals} from '../../api_service/api_func'


const CreateEnrichLesson = () => {

    const [openCube1, setOpenCube1] = useState(false)
    const [openCube2, setOpenCube2] = useState(false)
    const [openCube3, setOpenCube3] = useState(false)
    const [openCube4, setOpenCube4] = useState(false)
    const {systemLessonId,skillsId} = useParams()


    const handleOpen = () => {
        setOpenCube1(false)
        setOpenCube2(false)
        setOpenCube3(false)
        setOpenCube4(false)
    }

    const displayForm = () => {

    }

    const nav = useNavigate();
    const [lessonName, setLessonName] = useState('')
    const [level1, setLevel1] = useState('')
    const [level2, setLevel2] = useState('')
    const [level3, setLevel3] = useState('')

    const formDataPost = {}
    const inputPropsArrayLevels = [
        {
            // onChange: handleLevel1,
            placeholder: "כתבו שם שיעור העשרה",
            value: lessonName,
            lable: "שם השיעור",
            name: "name",
            // disabled: readOnly || false,
            type: "text",
            req: true
        },
        {
            // onChange: handleLevel2,
            placeholder: "מטרה 1",
            value: level1,
            lable: "מטרה",
            name:"level1",
            type: "text",
            // disabled: readOnly || false,
            req: false
        },
        {
            // onChange: handleLevel2,
            placeholder: "מטרה 2",
            value: level2,
            lable: "מטרה",
            name:"level2",
            type: "text",
            // disabled: readOnly || false,
            req: false
        }, {
            // onChange: handleLevel2,
            placeholder: "מטרה 3",
            value: level3,
            lable: "מטרה",
            name:"level3",
            type: "text",
            // disabled: readOnly || false,
            req: false
        },
    ];

    const cube = () => {

        // return (
        //     <div className=' cube-details rounded-5 col  mx-1 '
        //     style={{ maxHeight: "500px", maxWidth: "330px", margin: '5px', border: true ? 'rgb(96,88,153) solid 3px' : '', backgroundColor: "white" }}
        // >

        //     <div className=' rounded-5'
        //         style={{
        //             backgroundImage: "url('https://thumbs.dreamstime.com/b/books-6822971.jpg')",
        //             backgroundRepeat: "no-repeat",
        //             backgroundSize: "cover",
        //             backgroundPosition: "",
        //             minHeight: "120px",
        //             maxHeight: "130px"
        //         }}
        //     ></div>

        //     <div className='div-ellipsis'>
        //         <span className='fw-bold px-2' style={{ fontSize: "1.2rem" }}>
        //             {/* {title} */}
        //             </span>
        //     </div>
        //     <div className='div-ellipsis'>
        //         <span className='fw-bold px-2'
        //         >
        //             {/* {subtitle} */}
        //             </span>
        //     </div>
        //     {/* <p className='pe-3'>{Cube.body}</p> */}
        //     <table style={{ width: '100%', borderCollapse: 'collapse', border: 'none', borderCollapse: 'separate', borderSpacing: '5px' }}>
        //         <tbody>
        //             <tr>
        //                 <td style={{ width: '42%', border: 'none', fontSize: '12px' }}>
        //                     <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
        //                         <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        //                             {/* SVG paths here */}
        //                             <path d="M4.9502 8.89844C7.15933 8.89844 8.9502 7.10758 8.9502 4.89844C8.9502 2.6893 7.15933 0.898438 4.9502 0.898438C2.74106 0.898438 0.950195 2.6893 0.950195 4.89844C0.950195 7.10758 2.74106 8.89844 4.9502 8.89844Z" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        //                             <path d="M4.95475 7.56771C6.42751 7.56771 7.62142 6.3738 7.62142 4.90104C7.62142 3.42828 6.42751 2.23438 4.95475 2.23438C3.48199 2.23438 2.28809 3.42828 2.28809 4.90104C2.28809 6.3738 3.48199 7.56771 4.95475 7.56771Z" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        //                             <path d="M4.95052 6.24769C5.6869 6.24769 6.28385 5.65009 6.28385 4.91291C6.28385 4.17573 5.6869 3.57812 4.95052 3.57812C4.21414 3.57812 3.61719 4.17573 3.61719 4.91291C3.61719 5.65009 4.21414 6.24769 4.95052 6.24769Z" stroke="#4B4D4F" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        //                         </svg>
        //                     </div>
        //                     <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>מותאם מטרה</span>
        //                 </td>
        //                 <td style={{ width: '58%', border: 'none', backgroundColor: '#E5EEF8', borderRadius: '8px' }}>

        //                 </td>
        //             </tr>
        //             <tr>
        //                 <td style={{ border: 'none', fontSize: '12px' }}>
        //                     <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
        //                         <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
        //                             <path opacity="0.4" d="M8.15954 2.87417C8.13252 2.86918 8.10099 2.86918 8.07397 2.87417C7.45245 2.8492 6.95703 2.28479 6.95703 1.58552C6.95703 0.871273 7.47496 0.296875 8.119 0.296875C8.76304 0.296875 9.28098 0.876268 9.28098 1.58552C9.27647 2.28479 8.78106 2.8492 8.15954 2.87417Z" stroke="#4B4D4F" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        //                             <path opacity="0.4" d="M7.70004 6.50267C8.31705 6.61755 8.99712 6.49767 9.47452 6.14305C10.1096 5.67354 10.1096 4.90434 9.47452 4.43484C8.99262 4.08019 8.30354 3.96031 7.68652 4.08019" stroke="#4B4D4F" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        //                             <path opacity="0.4" d="M2.73667 2.87417C2.7637 2.86918 2.79522 2.86918 2.82224 2.87417C3.44376 2.8492 3.93918 2.28479 3.93918 1.58552C3.93918 0.871273 3.42125 0.296875 2.77721 0.296875C2.13317 0.296875 1.61523 0.876268 1.61523 1.58552C1.61974 2.28479 2.11515 2.8492 2.73667 2.87417Z" stroke="#4B4D4F" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        //                             <path opacity="0.4" d="M3.20095 6.50267C2.58394 6.61755 1.90387 6.49767 1.42647 6.14305C0.791437 5.67354 0.791437 4.90434 1.42647 4.43484C1.90837 4.08019 2.59745 3.96031 3.21447 4.08019" stroke="#4B4D4F" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        //                             <path d="M5.4613 6.61245C5.43428 6.60746 5.40276 6.60746 5.37573 6.61245C4.75421 6.58748 4.25879 6.02307 4.25879 5.3238C4.25879 4.60955 4.77673 4.03516 5.42077 4.03516C6.06481 4.03516 6.58274 4.61454 6.58274 5.3238C6.57824 6.02307 6.08282 6.59247 5.4613 6.61245Z" stroke="#4B4D4F" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        //                             <path d="M4.1437 8.18615C3.50809 8.65565 3.50809 9.4248 4.1437 9.89431C4.86497 10.4287 6.04604 10.4287 6.7673 9.89431C7.40292 9.4248 7.40292 8.65565 6.7673 8.18615C6.05055 7.6567 4.86497 7.6567 4.1437 8.18615Z" stroke="#4B4D4F" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        //                         </svg>
        //                     </div>
        //                     <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>מותאם לרמות</span>

        //                 </td>
        //                 <td style={{ border: 'none', backgroundColor: '#E5EEF8', borderRadius: '8px' }}>
        //                     {/* {Cube.level_1 ? '1,' : ''} {Cube.level_2 ? '2,' : ''} {Cube.level_3 ? '3' : ''} */}
        //                 </td>
        //             </tr>
        //             <tr>
        //                 <td style={{ border: 'none', fontSize: '12px' }}>
        //                     <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
        //                         <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
        //                             <path d="M9.9502 7.97757V2.15918C9.9502 1.58071 9.5092 1.15168 8.9737 1.19989H8.9467C8.0017 1.28666 6.5662 1.80246 5.7652 2.34236L5.6887 2.39538C5.5582 2.48215 5.3422 2.48215 5.2117 2.39538L5.0992 2.32308C4.2982 1.78799 2.8672 1.27702 1.9222 1.19507C1.3867 1.14686 0.950195 1.58071 0.950195 2.15436V7.97757C0.950195 8.44034 1.3012 8.87419 1.7332 8.93204L1.8637 8.95132C2.8402 9.09111 4.3477 9.62138 5.2117 10.1275L5.2297 10.1372C5.3512 10.2095 5.5447 10.2095 5.6617 10.1372C6.5257 9.6262 8.0377 9.09111 9.0187 8.95132L9.1672 8.93204C9.5992 8.87419 9.9502 8.44034 9.9502 7.97757Z" stroke="#4B4D4F" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
        //                             <path d="M5.4502 2.54297V9.77381" stroke="#4B4D4F" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
        //                             <path d="M3.53919 3.99609H2.5293" stroke="#4B4D4F" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
        //                             <path d="M3.87806 5.44141H2.5293" stroke="#4B4D4F" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
        //                         </svg>
        //                     </div>
        //                     <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>ספר או חוברת</span>

        //                 </td>
        //                 <td style={{ border: 'none', backgroundColor: '#E5EEF8', borderRadius: '8px' }}>
        //                     {/* {Cube?.book?.name || 'אין ספר'} */}
        //                 </td>
        //             </tr>
        //             <tr>

        //                 <td style={{ border: 'none', fontSize: '12px' }}>
        //                     <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
        //                         <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" fill="none">
        //                             <path d="M3.83022 7.51063C3.79309 7.51063 3.75967 7.50226 3.72255 7.48551C3.61859 7.43947 3.55176 7.32226 3.55176 7.19668V4.68505C3.55176 4.51342 3.67799 4.37109 3.83022 4.37109C3.98244 4.37109 4.10868 4.51342 4.10868 4.68505V6.439L4.376 6.13761C4.48367 6.01621 4.66188 6.01621 4.76956 6.13761C4.87723 6.259 4.87723 6.45993 4.76956 6.58133L4.027 7.41854C3.97502 7.47714 3.90076 7.51063 3.83022 7.51063Z" fill="#4B4D4F" />
        //                             <path d="M3.82868 7.47524C3.75814 7.47524 3.6876 7.44595 3.63191 7.38318L2.88935 6.54629C2.78168 6.42494 2.78168 6.22408 2.88935 6.10273C2.99702 5.98138 3.17523 5.98138 3.2829 6.10273L4.02546 6.93963C4.13313 7.06098 4.13313 7.26183 4.02546 7.38318C3.96977 7.44595 3.89923 7.47524 3.82868 7.47524Z" fill="#4B4D4F" />
        //                             <path d="M6.06647 9.58594H3.83392C1.81345 9.58594 0.950195 8.61477 0.950195 6.34175V3.83012C0.950195 1.5571 1.81345 0.585938 3.83392 0.585938H5.69438C5.84694 0.585938 5.97345 0.728263 5.97345 0.899891C5.97345 1.07152 5.84694 1.21384 5.69438 1.21384H3.83392C2.11857 1.21384 1.50833 1.90036 1.50833 3.83012V6.34175C1.50833 8.27152 2.11857 8.95803 3.83392 8.95803H6.06647C7.78182 8.95803 8.39206 8.27152 8.39206 6.34175V4.24873C8.39206 4.0771 8.51857 3.93477 8.67113 3.93477C8.82368 3.93477 8.9502 4.0771 8.9502 4.24873V6.34175C8.9502 8.61477 8.08694 9.58594 6.06647 9.58594Z" fill="#4B4D4F" />
        //                             <path d="M8.66304 4.56207H7.17467C5.90211 4.56207 5.40723 4.00532 5.40723 2.57369V0.899273C5.40723 0.773692 5.4742 0.656482 5.57839 0.610436C5.68258 0.560203 5.80164 0.589506 5.88351 0.677413L8.86025 4.02625C8.93839 4.11416 8.96443 4.2523 8.91978 4.36951C8.87513 4.48672 8.77467 4.56207 8.66304 4.56207ZM5.96537 1.65695V2.57369C5.96537 3.65369 6.21467 3.93416 7.17467 3.93416H7.98955L5.96537 1.65695Z" fill="#4B4D4F" />
        //                         </svg>
        //                     </div>
        //                     <span style={{ display: 'inline-block', verticalAlign: 'middle' }}></span>
        //                     קבצים
        //                 </td>
        //                 <td style={{ border: 'none', backgroundColor: '#E5EEF8', borderRadius: '8px' }}>
        //                     {/* {Cube.file} */}
        //                 </td>
        //             </tr>
        //             <tr>
        //                 <td style={{ border: 'none', fontSize: '12px' }}>
        //                     <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
        //                         <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        //                             <path d="M5.4502 9.97266C2.97206 9.97266 0.950195 7.95498 0.950195 5.47266C0.950195 2.99033 2.97206 0.972656 5.4502 0.972656C7.92833 0.972656 9.9502 2.99033 9.9502 5.47266C9.9502 7.95498 7.93252 9.97266 5.4502 9.97266ZM5.4502 1.60056C3.31531 1.60056 1.5781 3.33777 1.5781 5.47266C1.5781 7.60754 3.31531 9.34475 5.4502 9.34475C7.58508 9.34475 9.32229 7.60754 9.32229 5.47266C9.32229 3.33777 7.58508 1.60056 5.4502 1.60056Z" fill="#4B4D4F" />
        //                             <path d="M4.859 7.56111C4.67482 7.56111 4.49902 7.51506 4.34413 7.42716C3.98413 7.21785 3.7832 6.80762 3.7832 6.27181V4.86948C3.7832 4.33366 3.97995 3.92343 4.33995 3.71413C4.69993 3.50483 5.15621 3.53831 5.62087 3.80622L6.83482 4.50529C7.29947 4.7732 7.55482 5.14995 7.55482 5.56855C7.55482 5.98297 7.29947 6.3639 6.83482 6.63181L5.62087 7.33088C5.36133 7.48576 5.09761 7.56111 4.859 7.56111ZM4.859 4.2039C4.78366 4.2039 4.71249 4.22064 4.65807 4.25413C4.49902 4.34622 4.41111 4.56808 4.41111 4.86948V6.27181C4.41111 6.56902 4.49902 6.79506 4.65807 6.88297C4.81296 6.97506 5.05156 6.93739 5.3111 6.79088L6.52505 6.09181C6.78459 5.94111 6.9311 5.75274 6.9311 5.57274C6.9311 5.39274 6.7804 5.20437 6.52505 5.05367L5.3111 4.35459C5.14366 4.25413 4.98877 4.2039 4.859 4.2039Z" fill="#4B4D4F" />
        //                         </svg>
        //                     </div>
        //                     <span style={{ display: 'inline-block', verticalAlign: 'middle' }} >קישורים</span>

        //                 </td>
        //                 <td style={{ border: 'none', backgroundColor: '#E5EEF8', borderRadius: '8px' }}>
        //                     {/* {Cube.link} */}
        //                 </td>
        //             </tr>
        //             <tr>
        //                 <td style={{ border: 'none', fontSize: '12px' }}>
        //                     <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
        //                         <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" fill="none">
        //                             <path d="M4.57617 3.625H6.41199" stroke="#4B4D4F" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        //                             <path d="M2.48242 3.64881L2.74435 3.94978L3.53013 3.04688" stroke="#4B4D4F" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        //                             <path d="M4.57617 6.4375H6.41199" stroke="#4B4D4F" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        //                             <path d="M2.48242 6.44568L2.74435 6.74665L3.53013 5.84375" stroke="#4B4D4F" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        //                             <path d="M3.4002 8.89063H5.5002C7.2502 8.89063 7.9502 8.09063 7.9502 6.09063V3.69063C7.9502 1.69063 7.2502 0.890625 5.5002 0.890625H3.4002C1.6502 0.890625 0.950195 1.69063 0.950195 3.69063V6.09063C0.950195 8.09063 1.6502 8.89063 3.4002 8.89063Z" stroke="#4B4D4F" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        //                         </svg>
        //                     </div>
        //                     <span style={{ display: 'inline-block', verticalAlign: 'middle' }} >משימות הערכות</span>
        //                 </td>
        //                 <td style={{ border: 'none', backgroundColor: '#E5EEF8', borderRadius: '8px' }}>
        //                 </td>
        //             </tr>
        //         </tbody>
        //     </table>

        //     <div className='d-flex justify-content-center'>

        //         <button className='btn  my-3 px-5 text-light fw-bold'
        //             style={{ backgroundColor: "#605899", width: "160px", height: "35px", paddingTop: '5px' }}
        //             onClick={(() => {nav(`/user/create_new_cube/10/0`)})}
        //             >
        //          צור קובייה
        //         </button>
        //     </div>
        // </div>
        // )
        return (
            <CreateNewCube ></CreateNewCube>
        )
    }

    const handleLessonSubmit = async(event) => {
        event.preventDefault();       
        formDataPost['type'] = 0;
        formDataPost['id_skills'] = skillsId
        const systemlesson = await doApiCreateEnrichLesson(formDataPost)
        console.log(systemlesson.id);
        const goals = Object.entries(formDataPost).map(([key,value]) => {
            if(key.includes('level')){
            
                return {description : value, systemLesson : systemlesson.id }
            }
        }).filter(Boolean);
        doApiCreateSystemLessonGoals(goals)  
        nav(-1)
    }

    const handleChanges = (e) => {
        if(e.target.name){
            formDataPost[e.target.name] = e.target.value
        }
    }


    useEffect(() => {

    })

    return (
        <div className='container'>
            <div className='container' >
                <div className="input-group input-group-sm mb-3" style={{ width: "60%" }}>
                    <div className="input-group-prepend w-100">
        <form onSubmit={handleLessonSubmit}>
                        {inputPropsArrayLevels.map((i, index) => (
                            <div key={index} className='d-flex' style={{ padding: "0px 10px", backgroundColor: "#FDFFF4", marginBottom: "10px", borderRadius: "10px" }}>
                                <span className="input-group-text" id="inputGroup-sizing-sm">{i.lable}</span>
                                <input key={i.name} name={i.name} required={i.req} onChange={(e)=> handleChanges(e)}
                                 type={i.type} className="form-control" placeholder={i.placeholder} aria-label={i.lable} aria-describedby="inputGroup-sizing-sm" />
                            </div>
                        ))}
                        <div className='d-flex justify-content-end'>
                        <button className='btn btn-primary' type='submit'>צור שיעור</button>
                        </div>
                        </form>
                    </div>

                </div>
              
                {/* <div className='w-100 bgcolor1 rounded-4 mb-2'>

                    <button className='btn ' onClick={() => { handleOpen(); setOpenCube1(!openCube1) }}>


                        <span className='fw-bold px-5'>פתיחה מסקרנת
                        </span>
                        <span >  | 5-7 דקות - יצירת עניין לנושא הספציפי שילמד בשיעור</span>
                    </button>
                    {openCube1 ? cube() : <></>}
                </div>
                <div className='w-100 bgcolor1 rounded-4 mb-2'>

                    <button className='btn ' onClick={() => { handleOpen(); setOpenCube2(!openCube2) }}>

                        <span className='fw-bold px-5'>הקניה</span>
                        <span style={{ paddingRight: "74px" }}>|  7-10 דקות - הוראת חומר חדש</span>
                      
                    </button>
                    {openCube2 ? cube() : <></>}

                </div>
                <div className='w-100 bgcolor1 rounded-4 mb-2'>

                    <button className='btn ' onClick={() => { handleOpen(); setOpenCube3(!openCube3) }}>

                        <span className='fw-bold px-5'>גוף שיעור</span>

                        <span style={{ paddingRight: "44px" }}>   |  20-25 דקות - תרגול דיפרנציאלי של ההקניה</span>
                       
                    </button>
                    {openCube3 ? cube() : <></>}
                </div>
                <div className='w-100 bgcolor1 rounded-4 mb-2'>

                    <button className='btn' onClick={() => { handleOpen(); setOpenCube4(!openCube4) }}>
                        <span className='fw-bold px-5'>
                            סיכום
                        </span>
                        <span style={{ paddingRight: "74px" }}>
                            |  7-10 דקות - ווידוא הבנה
                        </span>

                    </button>
                    {openCube4 ? cube() : <></>}
                </div> */}
            </div>
        </div>
    )
}

export default CreateEnrichLesson;