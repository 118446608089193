import React from 'react'
import {useNavigate} from "react-router-dom"





function Navigator(props) {
  const navigate = useNavigate();

  return (
  <>
    
     <button className='btn '  style={{color:props.props.color}} onClick={()=>navigate(props.props.route)}> {props.props.name}</button>
  </>
  )
}

export default Navigator