import React from 'react'
import './week_calendar.css'
import { useNavigate } from 'react-router-dom';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { Button, Modal, Box } from '@mui/material';
import { useState } from 'react';
import MyModalContent from '../MyModal';
import {addAbsenceToUserClass, addAbsenceDayToUserClass} from '../../api_service/api_func'
import { USER } from '../localstorage/store_name';
const Table = ({ data, startDate, absence, eventTable }) => {
  const nav = useNavigate();

  const hebrewDaysOfWeek = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי'];
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const sundayOfCurrentWeek = getSundayOfCurrentWeek(startDate);
  const StartDate = sundayOfCurrentWeek;
  const [propToModal, setPropsToModal] = useState({})

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [modalActive, setModalActive] = useState('')
  const [modalWarning, setModalWarning] = useState(false)
  const handleOpenModal = (item) => {
    if (item.classLesson.date) {
      const fullDate = new Date(item.classLesson.date)
      const props = {
        ...item,
        day: fullDate.getDate(),
        month: fullDate.getMonth() + 1,
        year: fullDate.getFullYear(),
        // startTime: item.startTime,
        // endTime: item.endTime,
      }
      setModalActive('day')
      setPropsToModal(props);
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmModal = () => {
    if(!modalWarning){
      return setModalWarning(true)
    }
    const date = new Date(propToModal.classLesson.date)
    const start_date = date.setHours(0, 0, 0)
    const end_date = date.setHours(23, 59, 59)
    // console.log(propToModal);
    if(modalActive == 'day'){
      const post = {
        klass: propToModal.classLesson?.classId,
        start_date: new Date(start_date),
        end_date: new Date(end_date),
        user: Number(localStorage.getItem(USER)),
        fieldOfKnowledge: propToModal.classLesson?.knowladgeId,
      }
      handleTableClick(post)
      // if(addAbsenceDayToUserClass(post))
      setIsModalOpen(false);
  }
  if(modalActive == 'lesson'){
    const post = {
      klass: propToModal.classLesson?.classId,
      start_date: new Date(date.setHours(Number(propToModal.startTime.split(':')[0]), Number(propToModal.startTime.split(':')[1]), 0)).toLocaleString(),
      end_date: new Date(date.setHours(Number(propToModal.endTime.split(':')[0]), Number(propToModal.endTime.split(':')[1]), 0)).toLocaleString(),
      user: localStorage.getItem(USER),
      fieldOfKnowledge: propToModal.classLesson?.knowladgeId,
    }
    handleTableClick(post)

    // if(addAbsenceToUserClass(post))
    setIsModalOpen(false);
    }

  }

  const handleCancelModal = () => {
    if (modalActive == 'day')
      setModalActive('lesson')
    else
      setIsModalOpen(false);

  }

  const dayModalContent = {
    title: "האם לעדכן חופשה יומית?",
    buttons: [{
      type: "confirm",
      text: "כן"
    },
    {
      type: "cancel",
      text: "לא"
    }]
  }

  const dateArray = [...Array(6)].map((_, index) => {
    const currentDate = new Date(StartDate);
    currentDate.setDate(StartDate.getDate() + index);
    return currentDate;
  });

  const tableData = {};

  data = data.sort((a, b) => {

    const timeA = new Date(`2000-01-01 ${a.startTime}`);
    const timeB = new Date(`2000-01-01 ${b.startTime}`);

    // Compare the times
    if (timeA < timeB) return -1;
    if (timeA > timeB) return 1;
    return 0;
  })

  data.forEach(d => {
    if (!tableData[d.startTime + " " + d.endTime]) {
      // If the key doesn't exist, initialize it with an empty array
      tableData[d.startTime + " " + d.endTime] = [];
    }
    tableData[d.startTime + " " + d.endTime].push(d);
  });

  const handleClickVacation = (item) => {
    // console.log(item);
    handleOpenModal(item)
  }


  const handleTableClick = (item) => {
    // Call the provided eventTable callback with the clicked item
    if (eventTable) {
      eventTable(item);
    }
  };


  return (
    <div className='table-wrapper'>
      <table className="custom-table mx-auto">
        <thead>
          <tr>
            <th></th>

            {dateArray.map((date, index) => (
              <th className='hebrewDay' key={index}>

                <div className={`date-header ${new Date(date).toDateString() === new Date().toDateString() ? 'day_now' : ''}`}>
                  <span>
                    {date.toLocaleDateString('he-IL', { weekday: 'long', }).replace('יום', '')}
                  </span>
                  <span className='sub_header'>
                    {date.toLocaleDateString('EN-IL', { day: 'numeric', month: 'numeric' })}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(tableData).map(([startTime, value]) => {

            return (
              <tr key={startTime}>

                <td >

                  <div className='first-cube'>
                    <span>
                      {startTime.split(' ')[0]}
                    </span>
                    <span>

                      {startTime.split(' ')[1]}
                    </span>
                  </div>

                </td>
                {daysOfWeek.map((day, index) => {
                  const item = value.find(v => (v['day'] === day))
                  const absenceDay = absence.find(a=>a['day'] == day)            
                  return (
                    <td key={index} >
                      {absenceDay && absenceDay.vacation ? <div className='outLesson'> חופשת {absenceDay.vacation}</div> :
                      item &&
                        <div className='cube-wrapper' style={{ opacity: new Date(item.date) > new Date() ? 1 : 0.7 }} >
                          {absenceDay && !item.classLesson && absenceDay.absenceArray[startTime.split(' ')[0]] && <div className='cube outLesson'>היעדרות</div>}
                          
                          {item.classLesson &&
                            <div className='cube' style={{ background: item.classLesson.color }} onClick={() => {
                              nav(`/user/create_lesson/${item.classLesson.id}`);
                            }}>
                              <div className='className'>
                                {`${item.classLesson.class}${item.classLesson.class_number} - ${item.classLesson.knowledge}`}
                                <div onClick={(e) => { e.stopPropagation() }}>
                                  <EventBusyIcon onClick={() => handleClickVacation(item)} sx={{ width: "20px", height: "20px" }} />

                                </div>
                              </div>
                              <div className='knowledge' >
                                {item.classLesson.subtchum}
                              </div>
                            </div>
                          }
                        </div>
                      }
                    </td>
                  )
                })}
              </tr>
            )
          })}

        </tbody>
      </table>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 300, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>

            {modalActive == 'day' && !modalWarning && <div className='d-flex flex-column' style={{textAlign:"center"}}>
              <div className='title'>
                האם לעדכן היעדרות יומית?
              </div>
              <div className='m-auto'>
                בתאריך
                  {` ${propToModal.day}/${propToModal.month}/${propToModal.year}`}
                </div>
              <div className='d-flex gap-2 justify-content-center'>
                <Button onClick={handleConfirmModal} variant="contained" sx={{ mt: 2 }}>
                  כן
                </Button>
                <Button onClick={handleCancelModal} variant="contained" color="error" sx={{ mt: 2, ml: 2 }}>
                  לא
                </Button>
              </div>
            </div>}
            {
              modalActive == 'lesson' && !modalWarning && <div className='d-flex flex-column'>
                <div className='title'>
                האם לעדכן היעדרות שעתית ?
                </div>
                <div style={{textAlign:"center"}}>
               בתאריך
                  {` ${propToModal.day}/${propToModal.month}/${propToModal.year}`}
                  <br />

                  {` בין השעות : ${propToModal.endTime} - 
                 ${propToModal.startTime}`}

                </div>
                <div className='d-flex gap-2' style={{margin:"auto"}}>
                  <Button onClick={handleConfirmModal} variant="contained" sx={{ mt: 2 }}>
                    אישור
                  </Button>
                  <Button onClick={handleCancelModal} variant="contained" color="error" sx={{ mt: 2, ml: 2 }}>
                    ביטול
                  </Button>
                </div>
              </div>
            }
            {
              modalWarning && <div className='d-flex flex-column'>
                     <div className='title'>
                ??האם אתה בטוח בעדכון היעדרות
                </div>
                <div style={{textAlign:"center"}}>
                  המערכת תאותחל בהתאם לשינויים שביצעת
                
                </div>
                <div className='d-flex gap-2' style={{margin:"auto"}}>
                  <Button onClick={handleConfirmModal} variant="contained" sx={{ mt: 2 }}>
                    אישור
                  </Button>
                  <Button onClick={handleCancelModal} variant="contained" color="error" sx={{ mt: 2, ml: 2 }}>
                    ביטול
                  </Button>
                </div>
              </div>
            }
          </Box>
        </div>
        {/* <MyModalContent
                                  content ={modalContent}
                                isOpen={isModalOpen}
                                onClose={handleCloseModal}
                              /> */}
      </Modal>
    </div >
  );
};


function getSundayOfCurrentWeek(date) {
  const today = new Date(date);
  const currentDay = today.getDay(); // Get the current day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)

  // Calculate the difference between the current day and Sunday (0 - currentDay will give you Sunday's index)
  const daysUntilSunday = 0 - currentDay;

  // Set the date to the Sunday of the current week
  today.setDate(today.getDate() + daysUntilSunday);
  return today;
}




export default Table;