export const Circle = ({color, width, height}) => {
    return(
        <div style={{ 
            width: width,
            height: height,
            background: color,
            margin: "5px",
            borderRadius:"5px",
            }}>
        </div>
    )
}