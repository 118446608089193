import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SignUpComponent from './components/Sign_up';
import LayoutAdmin from './LayoutAdmin';
import LoginComponent from './components/LoginUser';
import InstitutionManagement from './components/InstitutionManagement';
import NotFoundPage from './components/NotFoundPage';
import Verify_temp_pass from './components/Verify_temp_pass';
import LayoutUser from './LayoutUser';
import LightBox from './components/LightBox';
import CompleteDetails from './components/CompleteDetails';
import Taking_off from './components/TakingOff';
import Update_personal_details from './components/Update_personal_details';
import Scheduler from './components/Scheduler/Scheduler';
import Button_study_period from './components/Scheduler/button_study_period/Button_study_period';
import CreateNewCube from './components/Cube/CreateNewCube';
import SchedulerTable from './components/Scheduler/SchedulerTable';
import CreateLesson from './components/Lesson/CreateLesson';
import { ClassesOverview } from './components/classes_overview/ClassesOverview';
import { Chat } from './components/Chat/Chat'
// import Table from './components/week_calender/Table';
import Week_calendar from './components/week_calendar/week_calendar';
import StudyProgram from './components/studyProgram/StudyProgram';
import SkillsSum from './components/Lesson/skillsSum';
import ClassSum from './components/Lesson/classSum';
import DashboardTeacher from './components/dashboard-teacher/dashboard-teacher'
import TasksUser from './Task/tasksUser';
import TasksLesson from './Task/tasksLesson';
import { Scores } from './components/Scores/Scores';
import { ROLE } from './components/localstorage/store_name';
import PreviewScreen from './previewScreen'
import CreateEnrichLesson from './components/Lesson/CreateEnrichLesson';
// import CopyCube from './components/Cube/copyCube';

const PrivateRoute = ({ element, authorizedRoles }) => {
  // Check if the user is authenticated and has the required roles
  const isAdmin = localStorage.getItem(ROLE) == '"admin"'
  const hasRequiredRoles = /* Add your role check logic here */ true;
  const isAuthenticated = /* Add your authentication check logic here */ isAdmin;
  if (isAuthenticated && hasRequiredRoles) {
    return element;
  } else if (!isAuthenticated) {
    // Redirect to the login page if not authenticated
    return <Navigate to="/" />;
  } else {
    // Redirect to a permission-denied page or handle as needed
    return <Navigate to="/" />;
  }
};

const AppRouter = () => {
  return (
    <Router>
      {/* <Navigate from="/" to="/login" /> */}
      <Routes>
      {/* <Route path="/" element={<LoginComponent url={"/lightBox"} title={"ברוכים הבאים"} emojiName={"wave"} fontSize={"40px"} role={"user"} maxHeight={"550px"} />} /> */}
      <Route path="/" element={<PreviewScreen />} >
        <Route path="" element={<LoginComponent url={"/lightBox"} rout={"כניסה כמנהל"} title={"ברוכים הבאים"} emojiName={"wave"} fontSize={"40px"} role={"user"} maxHeight={"550px"} />} />
        <Route path="/login" element={<LoginComponent url={"/lightBox"} title={"ברוכים הבאים"} emojiName={"wave"} fontSize={"40px"} role={"user"} maxHeight={"550px"} />} />
        <Route path="/tempPass" element={<Verify_temp_pass />} />
        <Route path="/lightBox" element={<LightBox />} />
        <Route path="/takingoff" element={<Taking_off />} />
        <Route path="/scheduler" element={<Scheduler />} />
        <Route path="/user/update_personal_details" element={<Update_personal_details />} />
        {/* <Route path="/scheduler/table" element={<SchedulerTable />} /> */}
        </Route>
        <Route path="/user" element={<LayoutUser />}>
          <Route path='/user/dashboard-teacher' element={<DashboardTeacher />} />
          <Route path="/user/study_period/:institution_id/:study_period_id/:Method/:study_period_class_id" element={<Button_study_period />} />
          <Route path="/user/create_new_cube/:system_lesson_id/:Type" element={<CreateNewCube />} />
          <Route path="/user/copy_cube/:system_lesson_id/:cube_id/:Type" element={<CreateNewCube />} />
          <Route path="/user/cubeDetails/:system_lesson_id/:cube_id/:Type/:readOnly" element={<CreateNewCube />} />
          <Route path="/user/create_lesson/" element={<CreateLesson />} />
          <Route path="/user/create_lesson/:classLessonId" element={<CreateLesson />} />
          <Route path="/user/create_enrichLesson/:skillsId" element={<CreateEnrichLesson />} /> 
          <Route path="/user/create_lesson/week_calendar" element={<Week_calendar />} />
          <Route path="/user/completeDetails" element={<CompleteDetails />} />
          {/* <Route path="/user/skillsSum" element={<SkillsSum />} /> */}
          <Route path="/user/skillsSum" element={<SkillsSum />} />
          <Route path="/user/classes_overview" element={<ClassesOverview />} />
          <Route path="/user/chat" element={<Chat />} />
          <Route path="/user/classSum" element={<ClassSum />} />
          <Route path="/user/tasksUser" element={<TasksUser />} />
          <Route path="/user/tasksLesson" element={<TasksLesson />} />

          <Route path="/user/study_program" element={<StudyProgram />} />
          {/* <Route path="/user/study_program" element={<StudyProgram />} /> */}
          <Route path="/user/scores" element={<Scores />} />
        </Route>

        {/* <Route path="/admin" element={<LayoutAdmin />}> */}
        <Route path="/admin" element={<LoginComponent url={"/admin/InstitutionManagement"} title={"מערכת ניהול אתר"} rout={"כניסה כמורה"} emojiName={"books"} fontSize={"30px"} role={"admin"} />} />
        <Route path="/admin/SignUp" element={<PrivateRoute element={<SignUpComponent />} />} />
        <Route path="/admin/InstitutionManagement" element={<InstitutionManagement />} />
        {/* </Route> */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
//<Task classLesson='1'> </Task>