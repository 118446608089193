import React from 'react';
import {ReactComponent as Brush} from "../../images/brush.svg";
import {Circle} from '../../components/common/Circle'

import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart } from 'chart.js';

const ClassReview = () => {

    Chart.register(ArcElement);


    const FieldKnowledge = 'תורה';
    const numLesson = 29;

    const dataChart = {
        labels: ['#51CCE', '#84C8FF'],
        datasets: [
            {
            data: [19/29, 10/29],
            borderWidth: 0,
            backgroundColor: ['#51CCE7', '#DFDFDF'],
        }]
    };

    const statusLessons = ( num, status, color) => {
        return (
            <div className="d-flex flex-row align-items-center">
                <Circle color={color} width={6} height={6}></Circle>
                <b>{num}</b> {status} 
            </div>
        )
    }


    return (
        <div className="item d-flex flex-row justify-content-between">
            {/* <div className="icon-brush">
                <Brush/>
            </div> */}
            <div className="d-flex flex-column">
                <div className='title'> {"ב1"} - {FieldKnowledge}</div>
                <div><b>{numLesson}</b> שיעורים</div>
            </div>
           
            <div className="d-flex flex-column">
                {statusLessons(19, "נלמדו", "#51CCE7")}
                {statusLessons(10, "להשלים", "#DFDFDF")}
            </div>
            <div className="chart d-flex flex-column">
                <Doughnut data={dataChart} />
            </div>
        </div>
    )
}

export {ClassReview}