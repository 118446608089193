import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
// import Navigate from './components/Navigator';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import Sidebar from './components/sidebar/sidebar';
import './layout.css'
import Breadcrumbs from './Breadcrumbs'

const LayoutUser = () => {



  return (
    <div className='app-container'>
      <Sidebar />

      <div className='layout'>

        <Header />
        <div  >

          {/* <NavBar /> */}
          <Breadcrumbs />
          <ToastContainer
            containerId="an id"
            draggable={false}
            closeButton={true}
            newestOnTop={true}
            toastClassName="my-toast-class"
            progressClassName="my-progress-class"
            autoClose={3000}
            hideProgressBar={false}
            rtl={false}

          />
          <Outlet />
          {/* <Navigate props={{
        name: "חזור אחורה",
        emojiName: "",
        route: -1
      }}
    /> */}
          {/* <Footer /> */}
        </div>
      </div>

    </div>
  );
};

export default LayoutUser;