import React, { useEffect, useState } from 'react';
import { BASE_URL, makeRequest } from '../api_service/api_func';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AuthComponent = () => {
  const [tokenValid, setTokenValid] = useState(false);
  const nav = useNavigate();
  const [res, setRes] = useState({});
  
  const YOUR_TOKEN = localStorage.getItem('access_token')?.split('"').join('') || null;
  useEffect(() => {
    // Function to check if the token is valid
    const checkTokenValidity = async () => {
      try {
        // Make an API request to your server endpoint to validate the token
        const _res = await makeRequest('GET', `${BASE_URL}/auth/profile`, {});
        setRes(_res);
        // console.log(_res);
        // If the token is valid, setTokenValid to true
        setTokenValid(true);
        // console.log(_res);
      } catch (error) {
        // If the token is invalid or the request fails, setTokenValid to false
        console.error(error);
        setTokenValid(false);

      
      }

    };

    if (YOUR_TOKEN) {
      checkTokenValidity();
    } else {
      // Token is not available, setTokenValid to false
      setTokenValid(false);
      localStorage.removeItem('access_token');
      toast.error("Error: Token expired or invalid");
      nav('/');
    }

  }, [YOUR_TOKEN, nav]);


  if (tokenValid) {
    return <h2></h2>;
  } else {
    return <h2>Token expired or invalid</h2>  ;
  }
};

export default AuthComponent;
