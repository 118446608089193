import React, { useState, useRef, useEffect } from 'react';
import './Tasks.css'; // Importing your CSS

function AddTodo({ onAdd }) {
  const [task, setTask] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupStyle, setPopupStyle] = useState({});
  const spanRef = useRef(null);



  useEffect(() => {
    if (showPopup && spanRef.current) {
      const rect = spanRef.current.getBoundingClientRect();
      setPopupStyle({
        position: 'fixed',
        top: `${rect.top + 30}px`,
        left: `${rect.left + 155}px`,
        lineHeight: '24px',  // Line height per line
        height: '72px',

      });
    }
  }, [showPopup]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (task.trim() !== '') {


      onAdd(task);

      setTask('');
      setShowPopup(false);
    }
  };

  const handleInputChange = (e) => setTask(e.target.value);

  return (
    <div>
      {showPopup ? (
        <div className='popup' style={popupStyle}>
          <form onSubmit={handleSubmit} className="popup-form">
            <div className="textarea-container">
              <textarea
                className="add-todo-input"
                value={task}
                onChange={handleInputChange}
                placeholder="רשום משימה חדשה"

                rows="3"
              />
            </div>
            <div className="button-container">
              <button type="submit" className="add-todo-button">Add</button>
              <button type="button" className="close-button" onClick={() => setShowPopup(false)}>Close</button>
            </div>
          </form>

        </div>
      ) : null}

      <div>
        <span ref={spanRef} className="add-todo-wrapper" onClick={() => setShowPopup(true)}>
          <svg className='add-todo-button2' width="20" height="20" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon add circle">
              <g id="Group">
                <path id="Vector" d="M4.52587 7.91049C2.33889 7.91049 0.561279 6.23252 0.561279 4.16814C0.561279 2.10375 2.33889 0.425781 4.52587 0.425781C6.71285 0.425781 8.49047 2.10375 8.49047 4.16814C8.49047 6.23252 6.71285 7.91049 4.52587 7.91049ZM4.52587 0.94797C2.645 0.94797 1.11448 2.39269 1.11448 4.16814C1.11448 5.94358 2.645 7.3883 4.52587 7.3883C6.40675 7.3883 7.93727 5.94358 7.93727 4.16814C7.93727 2.39269 6.40675 0.94797 4.52587 0.94797Z" fill="#4255A1" />
                <path id="Vector_2" d="M6.00117 4.42844H3.05077C2.89956 4.42844 2.77417 4.31008 2.77417 4.16734C2.77417 4.02461 2.89956 3.90625 3.05077 3.90625H6.00117C6.15237 3.90625 6.27776 4.02461 6.27776 4.16734C6.27776 4.31008 6.15237 4.42844 6.00117 4.42844Z" fill="#4255A1" />
                <path id="Vector_3" d="M4.52587 5.82282C4.37466 5.82282 4.24927 5.70446 4.24927 5.56173V2.77672C4.24927 2.63399 4.37466 2.51562 4.52587 2.51562C4.67707 2.51562 4.80247 2.63399 4.80247 2.77672V5.56173C4.80247 5.70446 4.67707 5.82282 4.52587 5.82282Z" fill="#4255A1" />
              </g>
            </g>
          </svg>
          <span className="add-todo-text">לחץ להוספת משימה</span>
        </span>
      </div>
    </div>
  );
}

export default AddTodo;
