import { useState } from 'react';
import './Chat.css';
import {createMessage, getUserMessages } from '../../api_service/api_func';
import PopupMessage from '../common/popup/PopupMessage';
import { useEffect } from 'react';
import PopupLoader from '../common/popup/PopupLoader'

export const Chat = () => {

    const url = "/final.jpg";
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);

    // useEffect(() => {
    //     getUserMessages()
    // }, [])

    const changeMessage = (e) => {
        setError("");
        setMessage(e.target.value);
    };

    const sendMessage = async () => {
        if (message.length === 0) return;
        setLoader(true);
        const res = await createMessage(message);
        setLoader(false);
        if(res.status && res.status !== 201) {
            console.log(res.message);
            setError(res.message);
        } 
        else {
            setOpen(true)
            setMessage("");
        }
    }

    return(
        <div className="wrap-chat">
            <div className="header d-flex flex-column w-75">
                <div className='d-flex flex-row align-items-center'>
                    <div className="icon">
                        <img src="/chat.svg" alt="icon" srcset="" />
                     </div>
                    <div className="title">תרומה לקהילה</div>
                </div>
                <hr />
            </div>
            <div className='message d-flex flex-column justify-content-around'>

                <div className='d-flex flex-row align-items-center'>
                    <div className='profile-picture'>
                        <img className='profile-picture-img' src="/profileEliran.svg" alt="profil"/>
                    </div>
                    <div className='text-bot'>
                        <div className='text'>
                            <div> אשמח לדעת מה חסר לך על מנת שהרשת תהיה מושלמת? תודה רבה!</div>
                            <div>אלירן נחום מנכ”ל טיצ’</div>
                        </div>
                        <img className='arrow' src="/arrow-right.svg" alt="" srcset="" />
                    </div>
                </div>
                
                <div className='sender d-flex flex-row-reverse align-items-center'>
                    <div className='profile-picture-sender' style={{backgroundImage: `url(${url})` }}>
                    </div>
                    <div className='text-sender'>
                        <div className='text'>
                            <textarea value={message} onChange={changeMessage} className='textarea sender-textarea' rows={3} placeholder='הקלידו כאן ...'/>
                            <img onClick={sendMessage} className='send' src="/send.svg" alt="" srcset="" />
                        </div>
                        <img className='arrow-left' src="/arrow-left.svg" alt="" srcset="" />
                    </div>
                    <div className='picture-sender'></div>
                </div>
            </div>
            <div className='err'>{error}</div>
            <div>

            {open && <PopupMessage text={"ההודעה נשלחה בהצלחה!"} textBotton={"סגור"} closePopup={() => setOpen(false)} />}
            {loader && <PopupLoader color={"white"} loading={true} size={100} text={"המערכת מבצעת שליחה... "}></PopupLoader>}
            </div>
        </div>
    )
}