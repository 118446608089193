//frontend-teach\src\Task\TodoItem.jsx


import React, { useState } from 'react';
import './Tasks.css';
function TodoItem({ todo, onEdit, onDelete }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedtask, setEditedtask] = useState(todo.task);

  function handleDoubleClick() {
    // setIsEditing(true);
  }

  function handleSave() {
    onEdit(todo.id, editedtask);
    setIsEditing(false);
  }

  function handleDelete() {
    onDelete(todo.id);
  }
  function handleToggleComplete() {
    const newIsDone = !todo.is_done;
    onEdit(todo.id, todo.task, newIsDone);
  }

  const truncatedTask = (todo.task && typeof todo.task === 'string' && todo.task.length > 25)
    ? todo.task.substring(0, 25) + '...'
    : todo.task;

  return (
    <div className='tododiv'>
      <input type="checkbox" className="checkbox" id="task-delete" value={true} checked={todo.is_done} onClick={handleToggleComplete} />
      {
        isEditing ? (
          <input type="text" value={editedtask} onChange={e => setEditedtask(e.target.value)} onBlur={handleSave} />
        ) : (
          <span onDoubleClick={handleDoubleClick} className='todotext'>
            {todo.is_done ? <s>{truncatedTask}</s> : truncatedTask}
          </span>
        )
      }
      {/* <button className='task-delete' onClick={handleDelete}>Delete</button> */}
      {/* <button className='task-delete' onClick={handleToggleComplete}>Done</button> */}
      {/* <label for="task-delete" class="task-delete-label">Done</label> */}

      {/* <input type='checkbox' checked={todo.done} onChange={handleToggleComplete}/> */}
    </div>
  );
}

export default TodoItem;
