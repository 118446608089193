import React, { useState } from 'react';
import { Box } from '@mui/material';



  const CalendarSelect = () => {
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
  
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const years = Array.from({ length: 3 }, (_, i) => new Date().getFullYear() - i);
  
    const days = Array.from({ length: 31 }, (_, i) => i + 1);

    const getDaysInMonth = (month, year) => {
      return new Date(year, month, 0).getDate();
    };
  
    const generateDays = (month, year) => {
      const numberOfDays = getDaysInMonth(month, year);
      return Array.from({ length: numberOfDays }, (_, i) => i + 1);
    };
  
    const handleMonthChange = (e) => {
      const selectedMonthValue = e.target.value;
      setSelectedMonth(selectedMonthValue);
      setSelectedDay('');
    };
  
    const handleYearChange = (e) => {
      const selectedYearValue = e.target.value;
      setSelectedYear(selectedYearValue);
      setSelectedDay('');
    };
  
    return (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
      <div className="container mt-4">
        <div className="row">
         
  
          <div className="col-md-4">
            <label className="form-label">
              Year:
              <select className="form-select" value={selectedYear} onChange={handleYearChange}>
                <option value="">Select Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className="col-md-4">
            <label className="form-label">
              Month:
              <select className="form-select" value={selectedMonth} onChange={handleMonthChange}>
                <option value="">Select Month</option>
                {months.map((month, index) => (
                  <option key={index} value={index + 1}>
                    {month}
                  </option>
                ))}
              </select>
            </label>
          </div>
  
          <div className="col-md-4">
            <label className="form-label">
              Day:
              <select className="form-select" value={selectedDay} onChange={(e) => setSelectedDay(e.target.value)}>
                <option value="">Select Day</option>
                {(selectedMonth && selectedYear) ? generateDays(selectedMonth, selectedYear).map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                )) : days.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
  
        {selectedDay && selectedMonth && selectedYear && (
          <p className="mt-3">Selected Date: {selectedMonth}/{selectedDay}/{selectedYear}</p>
        )}
      </div>
      </Box>
    );
  };
  
  export default CalendarSelect;
  