import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { ReactComponent as GoogleIcon } from "../../images/google_calendar_logo_icon_159345.svg";

import axios from 'axios'
const GoogleAuth = ({ onLoginSuccess, onFiledConnect }) => {


    const login = useGoogleLogin({
        onSuccess: tokenResponse => {
            console.log(tokenResponse);
            onLoginSuccess(tokenResponse); // Call the callback function
        },
        onError: error => onFiledConnect(),
        scope:'https://www.googleapis.com/auth/calendar'
      });

    

      
    return (
     
        <div className="App">
                <div>
             <button  style={{borderRadius:"15px"}}onClick={() => login()}>סנכרן עם   <GoogleIcon width="30px" height="30"></GoogleIcon></button>
                </div>
        </div>
    )

}

export default GoogleAuth;