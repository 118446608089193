import React, { useState, useContext, useEffect } from 'react';
import { BASE_URL, makeRequest, doApiGetAllClassLessonDateInUser } from '../api_service/api_func';
import Input from './Input';
import { useNavigate } from 'react-router-dom';
import EmojiComponent from './EmojiComponent ';
import { GlobalContext } from './store';
import { ACCESS_TOKEN, EMAIL_USER, USERNAME, USER, ROLE } from './localstorage/store_name';
import '../css/login.css'
import GoogleAuth from './Google/GoogleAuth';

const LoginComponent = ({ url, title, emojiName, fontSize, role, maxHeight, rout }) => {
  const { globalState, setGlobalData } = useContext(GlobalContext);

  // // Access the global state
  // const dataFromGlobalState = globalState.data;
  // useEffect(() => {
  //   localStorage.clear()
  // })

  // Update the global state using setGlobalData
  const updateGlobalState = (data) => {
    setGlobalData({ ...data });
  };

  const names = ['סמל מוסד', 'כתובת מייל', 'סיסמא']
  const [institustionSimble, setInstitutionSymbol] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleinstitustionSimbleChange = (event) => {
    setInstitutionSymbol(event.target.value);
    localStorage.setItem("Instituion_id", event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);

  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const create_user = (email, password) => {

    let user = {
      email: email,
      password: password,
    }

    return user;
  };

  const forgotPassword = async () => {
    if (!email) {
      alert("please enter email");
      return null;
    }
    try {

      const res = await makeRequest('POST', `${BASE_URL}/auth/forgotPass`, { email: email });
      if (!res.id) {
        alert("בבקשה הכנס אימייל תקין");
        return null;
      }
      alert("אנא הסתכל בתיבת המייל שלך להמשך התהליך");

    } catch (error) {

      alert(error);
      return null;
    }


  }

  const routAsAdmin = async () => {
    rout == 'כניסה כמנהל' ? navigate('/admin') : navigate('/');
  }

  const initialEntry = (async (userId)=> {
    const res = await doApiGetAllClassLessonDateInUser(userId)
    if(res && res.length){
      return false
    }else
    return true
  })


  const handleSubmit = async (event) => {
    event.preventDefault();

    let user = create_user(email, password);
    try {
      const res = await makeRequest('POST', `${BASE_URL}/auth/signIn`, user);

      if (res === "User not found") {
        alert('User not found');
        return null;
      }
      const updatedData = { ...globalState, ...res };
      // Call setGlobalData to update the global state
      updateGlobalState(updatedData);
      // alert(`Welcome ${res.username}`);
      localStorage.setItem(ACCESS_TOKEN, JSON.stringify(res.access_token));
      localStorage.setItem(EMAIL_USER, JSON.stringify(res.email));
      localStorage.setItem(USERNAME, JSON.stringify(res.username))
      localStorage.setItem(USER, res.id_user)
      localStorage.setItem(ROLE, JSON.stringify(res.roles))
      const isInitialEntry = (await initialEntry(res.id_user))
      // Reset the form
      setEmail('');
      setPassword('');
      setInstitutionSymbol('');

      const DEFAULT_URL = '/lightBox';
      // const ADMIN_URL = '/user/dashboard-teacher';
      const DASHBOARD = '/user/dashboard-teacher'

      const role = res.roles;
      const url = role === 'user' && isInitialEntry ? DEFAULT_URL : DASHBOARD;
      navigate(url);
    } catch (error) {
      alert(error);
    }
  };


  return (
    <div style={{ minHeight: "100vh" }} className="background d-flex flex-column justify-content-center layout ">
      <div className="row p-3 d-flex flex-wrap-nowrap justify-content-evenly align-items-center">
      <div className="row p-3 justify-content-around">


          <div style={{ maxHeight: maxHeight, maxWidth: "381px", margin: "0px 15px" }} className="login-box">
            <div className='pb-4'>
              <h2 style={{ fontSize: "35px", paddingTop: "20px", alignContent: "center" }} className="mb-1 text-center fw-bold">{title}<EmojiComponent emojiName={emojiName} /></h2>
            </div>
            {role === "user" ? <div>
              {/* <div className="mb-4">
              <button style={{ fontSize: "16px" }} className="btn btn-primary text-light w-100 rounded rounded-4">
                <img src="/image 25.png" alt="Penguin" style={{ width: '20px', marginleft: '15px' }} />
                <span>כניסה באמצעות הזדהות משרד החינוך</span>
              </button>
            </div> */}
              {/* <div className='d-flex'>
                <hr className='w-50' />
                <span className='px-2'> או   </span>
                <hr className='w-50' />
              </div> */}



            </div> : ""}
            <form onSubmit={handleSubmit} className=''>
              {role === "user" && <Input className="input-login" props={{
                onChange: handleinstitustionSimbleChange,
                value: institustionSimble,
                name: names[0],
                type: "text",
                req: false
              }} />}
              <Input className="input-login" props={{
                onChange: handleEmailChange,
                value: email,
                name: names[1],
                type: "email",
                req: true
              }} />
              <Input className="input-login" props={{
                onChange: handlePasswordChange,
                value: password,
                name: names[2],
                type: "password",
                req: true

              }} />
              {/* <div style={{ display: "flex", justifyContent: "end", marginBottom: "10px" }}>

                <div className='btn' style={{ color: "blue", fontSize: '12px' }} onClick={() => {
                  forgotPassword();
                }}>
                  שכחתי סיסמא?
              </div>

              </div> */}
              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                <div className='btn px-0' style={{ color: "blue", fontSize: '12px' }} onClick={() => {
                  routAsAdmin();
                }}>
                  {rout}
                </div>
                <div className='btn px-0' style={{ color: "blue", fontSize: '12px' }} onClick={() => {
                  forgotPassword();
                }}>
                  שכחתי סיסמא?

                </div>
              </div>
              <div className="mb-4">
                <button type="submit" className="btn btn-primary border border-2 text-light w-100 rounded rounded-4">כניסה</button>
              </div>
            </form>
          </div>

          <div style={{ width: "380px", margin: "0px 15px", height: "500px" }} className="col-lg-6 border border-0 login-logo-box" >
            <div className=" mx-auto ">
              <img style={{ width: "250px" }} src="/logoTeach.png" alt="logo" />

            </div>
            <p style={{ fontSize: "20px", textAlign: "center", fontWeight: 300 }} className='text-center '>
              רשת חברתית למורים לשיתוף שיעורים
              <br />
              עבודת צוות וזיכרון אירגוני</p>
            {/* <img src="https://cdn.pixabay.com/photo/2021/02/11/11/21/meeting-6004997_640.png" alt="Logo" className="img-fluid w-75 me-5 " /> */}
            <img src="\login-image.svg" alt="Logo" className="img-fluid w-75 me-5 " />

          </div>

        </div>
      </div>
    </div>

  );
};

export default LoginComponent;


