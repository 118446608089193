//frontend-teach\src\components\Lesson\CreateLesson.jsx
import React, { useContext, useEffect, useState } from "react";
import {
  doApiGetAmountCubeByType,
  doApiGetCubeByTypeAndSysLesson,
  updateClassLesson,
  doApiGetSystemLessonGoalBySyslessonId,
  doApiGetCubeByType,
  doApiSetCLassLesson,
  doApiGetCLassLesson,
  doApiGetCubeByIdRead,
} from "../../api_service/api_func";
import CubePreview from "./CubePreview";
import EmptyCube from '../Cube/emptyCube';
import EmojiComponent from "../EmojiComponent ";
import { GlobalContext } from "../store";
import { USER } from "../localstorage/store_name";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { downloadDataAsWord } from "./CreateWord";
import { Button, Space } from "antd";
import "./CreateLesson.css";
import { useLocation } from "react-router-dom";
import CubeDetails from "./CubeDetails";

export const cubeType = {
  opening: 0,
  purchase: 1,
  body: 2,
  summary: 3,
};

const CreateLesson = () => {
  const { classLessonId } = useParams("");

  const [systemLessonId, setSystemLessonId] = useState("");
  const [cubeIds, setCubeIds] = useState([]);
  const [openingCubeAr, setOpeningCubeAr] = useState([]);
  const [purchaseCubeAr, setPurchaseCubeAr] = useState([]);
  const [bodyCubeAr, setBodyCubeAr] = useState([]);
  const [summeryCubeAr, setSummeryCubeAr] = useState([]);
  const [showPurchaseCube, setShowPurchaseCube] = useState(false);
  const [showOpeningCubeAr, setShowOpeningCubeAr] = useState(true);
  const [showBodyCubeAr, setShowBodyCubeAr] = useState(false);
  const [showSummeryCubeAr, setShowSummeryCubeAr] = useState(false);
  const [pageOpening, setPageOpening] = useState(1);
  const [pagePurchase, setPagePurchase] = useState(1);
  const [pageBody, setPageBody] = useState(1);
  const [pageSummery, setPageSummery] = useState(1);
  const [sizeOpening, setSizeOpening] = useState(0);
  const [sizePurchase, setSizePurchase] = useState(0);
  const [sizeBody, setSizeBody] = useState(0);
  const [sizeSummery, setSizeSummery] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [systemLessonGolas, setSystemLessonGolas] = useState([]);
  const location = useLocation();

  const handleToggleOpeningCube = () => {
    setShowOpeningCubeAr(!showOpeningCubeAr);
    setShowBodyCubeAr(false);
    setShowSummeryCubeAr(false);
    setShowPurchaseCube(false);
  };

  const handleToggleBodyCube = () => {
    setShowBodyCubeAr(!showBodyCubeAr);
    setShowOpeningCubeAr(false);
    setShowSummeryCubeAr(false);
    setShowPurchaseCube(false);
  };

  const handleToggleSummeryCube = () => {
    setShowSummeryCubeAr(!showSummeryCubeAr);
    setShowBodyCubeAr(false);
    setShowOpeningCubeAr(false);
    setShowPurchaseCube(false);
  };
  // You can add similar state for other sections if needed

  const handleTogglePurchaseCube = () => {
    setShowPurchaseCube(!showPurchaseCube);
    setShowSummeryCubeAr(false);
    setShowBodyCubeAr(false);
    setShowOpeningCubeAr(false);
  };

  async function fetchAndLogClassLesson(id) {
    try {
      const result = await doApiGetCLassLesson(id);

      // Extract all id_cube values
      // const idCubes = findIdCube(result);

      const idCubes = extractIds(result);
      if (result.system_lesson) {
        setSystemLessonId(result.system_lesson.id);
      }
      // An array to hold all cubeData
      const allCubeData = [];

      // Fetch data for each cube_id in idCubes
      for (let cube_id of idCubes) {
        const cubeData = await doApiGetCubeByIdRead(cube_id);

        // Push each cubeData to the allCubeData array
        allCubeData.push({
          cube_id: cube_id,
          data: cubeData,
        });
      }

      const filteredCubeDataArray = allCubeData.map(filterCubeData);

      setFilteredData(filteredCubeDataArray);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  function extractIds(result) {
    const ids = [];
    if (result.body) ids.push(result.body.id);
    if (result.intriguing_opening) ids.push(result.intriguing_opening.id);
    if (result.purchase) ids.push(result.purchase.id);
    if (result.summery) ids.push(result.summery.id);
    return ids;
  }

  function findIdCube(obj, idCubes = []) {
    // If the object has id_cube, push to the array
    if (obj && obj.id) {
      idCubes.push(obj.id);
    }

    // Recursively search through the object
    for (let key in obj) {
      if (typeof obj[key] === "object") {
        findIdCube(obj[key], idCubes);
      }
    }

    return idCubes;
  }

  function filterCubeData(cubeData) {
    return {
      cube_id: cubeData.cube_id,
      data: {
        type: cubeData.data.type,
        title: cubeData.data.title,
        subtitle: cubeData.data.subtitle,
        body: cubeData.data.body,
        level_1: cubeData.data.level_1,
        level_2: cubeData.data.level_2,
        level_3: cubeData.data.level_3,
        book: cubeData.data.book ? cubeData.data.book.name : "",
        start_page: cubeData.data.start_page,
        end_page: cubeData.data.end_page,
        link: cubeData.data.link,
        file: cubeData.data.file,
      },
    };
  }

//   useEffect(() => {
//     if (!classLessonId) {
//       doApiGetCubeByType(cubeType.body, setBodyCubeAr, 1, 10000, setLoading);
//       doApiGetCubeByType(
//         cubeType.opening,
//         setOpeningCubeAr,
//         1,
//         10000,
//         setLoading
//       );
//       doApiGetCubeByType(
//         cubeType.purchase,
//         setPurchaseCubeAr,
//         1,
//         10000,
//         setLoading
//       );
//       doApiGetCubeByType(
//         cubeType.summary,
//         setSummeryCubeAr,
//         1,
//         10000,
//         setLoading
//       );

//       // doApiGetCubeByTypeAndSysLesson(cubeType.opening,systemLessonId, setBodyCubeAr, 1, 10000, setLoading);
//       // doApiGetCubeByTypeAndSysLesson(cubeType.opening, systemLessonId,setOpeningCubeAr, 1, 10000, setLoading);
//       // doApiGetCubeByTypeAndSysLesson(cubeType.purchase,systemLessonId, setPurchaseCubeAr, 1, 10000, setLoading);
//       // doApiGetCubeByTypeAndSysLesson(cubeType.summary,systemLessonId, setSummeryCubeAr, 1, 10000, setLoading);
//       doApiGetAmountCubeByType(cubeType.opening, setSizeOpening);
//       doApiGetAmountCubeByType(cubeType.purchase, setSizePurchase);
//       doApiGetAmountCubeByType(cubeType.body, setSizeBody);
//       doApiGetAmountCubeByType(cubeType.summary, setSizeSummery);

//       // if (id) {
//     }
//     //     fetchAndLogClassLesson(id);
//     //     console.log(systemLessonId);

//     // }
//   }, []); // Runs only once on component mount and if filteredData is empty

//   useEffect(() => {
//     // Check if filteredData has data
//     if (filteredData.length > 0) {
//       console.log(
//         "Filtered data is available for the first time: ",
//         filteredData
//       );
//       doApiGetCubeByTypeAndSysLesson(
//         cubeType.body,
//         systemLessonId,
//         setBodyCubeAr,
//         1,
//         10000,
//         setLoading,
//         filteredData[0]
//       );
//       doApiGetCubeByTypeAndSysLesson(
//         cubeType.opening,
//         systemLessonId,
//         setOpeningCubeAr,
//         1,
//         10000,
//         setLoading,
//         filteredData[1]
//       );
//       doApiGetCubeByTypeAndSysLesson(
//         cubeType.purchase,
//         systemLessonId,
//         setPurchaseCubeAr,
//         1,
//         10000,
//         setLoading,
//         filteredData[2]
//       );
//       doApiGetCubeByTypeAndSysLesson(
//         cubeType.summary,
//         systemLessonId,
//         setSummeryCubeAr,
//         1,
//         10000,
//         setLoading,
//         filteredData[3]
//       );
//     }
//   }, [filteredData]); // Runs whenever filteredData changes

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const result = await doApiGetCLassLesson(classLessonId);

//         // Extract all id_cube values
//         const idCubes = extractIds(result);
//         console.log(idCubes);
//         if (result.system_lesson) {
//           setSystemLessonId(result.system_lesson.id);
//         }
//         const allCubeData = [];

//         // Fetch data for each cube_id in idCubes
//         for (let cube_id of idCubes) {
//           const cubeData = await doApiGetCubeByIdRead(cube_id);
//           // Push each cubeData to the allCubeData array
//           allCubeData.push({
//             cube_id: cube_id,
//             data: cubeData,
//           });
//         }

//         const filteredCubeDataArray = allCubeData.map(filterCubeData);

//         setFilteredData(filteredCubeDataArray);

//         // if (classLessonId && systemLessonId) {
//         //     // Ensure systemLessonId is not null when making API calls
//         //     doApiGetCubeByTypeAndSysLesson(cubeType.body, systemLessonId, setBodyCubeAr, 1, 10000, setLoading);
//         //     doApiGetCubeByTypeAndSysLesson(cubeType.opening, systemLessonId, setOpeningCubeAr, 1, 10000, setLoading);
//         //     doApiGetCubeByTypeAndSysLesson(cubeType.purchase, systemLessonId, setPurchaseCubeAr, 1, 10000, setLoading);
//         //     doApiGetCubeByTypeAndSysLesson(cubeType.summary, systemLessonId, setSummeryCubeAr, 1, 10000, setLoading);
//         //     }
//       } catch (error) {
//         console.error("Error:", error);
//       }
//     };

//     fetchData(); // Call the fetchData function

//     // Other dependencies for useEffect...
//   }, [classLessonId]);

//   useEffect(() => {
//     if (systemLessonId != "") {
//       // doApiGetCubeByTypeAndSysLesson(cubeType.body, systemLessonId, setBodyCubeAr, 1, 10000, setLoading,filteredData[0]);
//       // doApiGetCubeByTypeAndSysLesson(cubeType.opening, systemLessonId, setOpeningCubeAr, 1, 10000, setLoading,filteredData[1]);
//       // doApiGetCubeByTypeAndSysLesson(cubeType.purchase, systemLessonId, setPurchaseCubeAr, 1, 10000, setLoading,filteredData[2]);
//       // doApiGetCubeByTypeAndSysLesson(cubeType.summary, systemLessonId, setSummeryCubeAr, 1, 10000, setLoading,filteredData[3]);
//       doApiGetSystemLessonGoalBySyslessonId(
//         systemLessonId,
//         setSystemLessonGolas
//       );
//     }
//   }, [systemLessonId]);

//   useEffect(() => {
//     const params = location.state;
//     if (params)
//       // updateClassLesson(classLessonId,{})
//       console.log("*****", params);
//   });

  
    function extractIds(result) {
        const ids = [];
        if (result.body) ids.push(result.body.id);
        if (result.intriguing_opening) ids.push(result.intriguing_opening.id);
        if (result.purchase) ids.push(result.purchase.id);
        if (result.summery) ids.push(result.summery.id);
        return ids;
    }





    function findIdCube(obj, idCubes = []) {
        // If the object has id_cube, push to the array
        if (obj && obj.id) {
            idCubes.push(obj.id);
        }

        // Recursively search through the object
        for (let key in obj) {
            if (typeof obj[key] === 'object') {
                findIdCube(obj[key], idCubes);
            }
        }

        return idCubes;
    }

    function filterCubeData(cubeData) {
        return {
            cube_id: cubeData.cube_id,
            data: {
                type: cubeData.data.type,
                title: cubeData.data.title,
                subtitle: cubeData.data.subtitle,
                body: cubeData.data.body,
                level_1: cubeData.data.level_1,
                level_2: cubeData.data.level_2,
                level_3: cubeData.data.level_3,
                book: cubeData.data.book ? cubeData.data.book.name : '',
                start_page: cubeData.data.start_page,
                end_page: cubeData.data.end_page,
                link: cubeData.data.link,
                file: cubeData.data.file,
                id_system_lesson: cubeData.data.id_system_lesson ? cubeData.data.id_system_lesson.id : ''
            },
        };
    }


    async function fetchAndLogClassLesson(id) {
        try {
            const result = await doApiGetCLassLesson(classLessonId);

            // Extract all id_cube values
            const idCubes = extractIds(result);
            if (result.system_lesson) {
                setSystemLessonId(result.system_lesson.id);
            }
            const allCubeData = [];
            doApiGetSystemLessonGoalBySyslessonId(result.system_lesson.id, setSystemLessonGolas)

            // Fetch data for each cube_id in idCubes
            for (let cube_id of idCubes) {
                const cubeData = await doApiGetCubeByIdRead(cube_id);
                // Push each cubeData to the allCubeData array
                allCubeData.push({
                    cube_id: cube_id,
                    data: cubeData
                });
            }

            const filteredCubeDataArray = allCubeData.map(filterCubeData);

            setFilteredData(filteredCubeDataArray);

        } catch (error) {
            console.error('Error:', error);
        }
    }


    useEffect(() => {

        // Check if filteredData has data
        if (systemLessonId) {
            console.log("Filtered data is available for the first time: ", filteredData);
            doApiGetCubeByTypeAndSysLesson(cubeType.body, systemLessonId, setBodyCubeAr, 1, 10000, setLoading, filteredData[0]);
            doApiGetCubeByTypeAndSysLesson(cubeType.opening, systemLessonId, setOpeningCubeAr, 1, 10000, setLoading, filteredData[1]);
            doApiGetCubeByTypeAndSysLesson(cubeType.purchase, systemLessonId, setPurchaseCubeAr, 1, 10000, setLoading, filteredData[2]);
            doApiGetCubeByTypeAndSysLesson(cubeType.summary, systemLessonId, setSummeryCubeAr, 1, 10000, setLoading, filteredData[3]);
        }
    }, [filteredData]); // Runs whenever filteredData changes


    useEffect(() => {
        const fetchData = async () => {
            if (classLessonId) {
    
                fetchAndLogClassLesson(); // Call the fetchData function
            }
          
            if (!classLessonId) {
               
                doApiGetCubeByType(cubeType.body, setBodyCubeAr, 1, 10000, setLoading);
                doApiGetCubeByType(cubeType.opening, setOpeningCubeAr, 1, 10000, setLoading);
                doApiGetCubeByType(cubeType.purchase, setPurchaseCubeAr, 1, 10000, setLoading);
                doApiGetCubeByType(cubeType.summary, setSummeryCubeAr, 1, 10000, setLoading);
                doApiGetAmountCubeByType(cubeType.opening, setSizeOpening);
                doApiGetAmountCubeByType(cubeType.purchase, setSizePurchase);
                doApiGetAmountCubeByType(cubeType.body, setSizeBody);
                doApiGetAmountCubeByType(cubeType.summary, setSizeSummery);
                
                // if (id) {
                }
            };

            fetchData()
    }, [classLessonId]);



    // return (
    //     <div className='container-fluid aa  '  >
    //         {systemLessonGolas.length > 0 && <div className='' style={{ padding: "10px 30px", backgroundColor: "#FDFFF4", marginBottom: "10px", borderRadius: "10px" }}>
    //             {systemLessonGolas.map((s, i) => (
    //                 <div key={i} style={{ fontSize: "16px" }}>
    //                     {'מטרה' + ' ' + (i + 1) + ' : '} {s.description}
    //                 </div>
    //             ))}

    //         </div>}
    //         <div className='w-100 bgcolor1 rounded-4 mb-2'>

    //             <button className='btn ' onClick={handleToggleOpeningCube}>


    //                 <span className='fw-bold px-5'>פתיחה מסקרנת
    //                 </span>
    //                 <span >  | 5-7 דקות - יצירת עניין לנושא הספציפי שילמד בשיעור</span>
    //                 {!showOpeningCubeAr ?
    //                     <EmojiComponent emojiName={"arrow_down"}></EmojiComponent> :
    //                     <EmojiComponent emojiName={"arrow_up"}></EmojiComponent>
    //                 }
    //             </button>
    //             {showOpeningCubeAr && openingCubeAr.length > 0 && (

    //                 <CubePreview sysLessonId={systemLessonId} CubeAr={openingCubeAr} setPage={setPageOpening} page={pageOpening} doApi={doApiGetCubeByTypeAndSysLesson} setAr={setOpeningCubeAr} type={cubeType.opening} total_size={sizeOpening}
    //                     cubeIds={cubeIds}
    //                     setCubeIds={setCubeIds}
    //                     setLoading={setLoading}
    //                     loading={loading}
    //                     priorityQube={filteredData[1]}

    //                 />
    //             )}
    //         </div>
    //         <div className='w-100 bgcolor1 rounded-4 mb-2'>

    //             <button className='btn ' onClick={handleTogglePurchaseCube}>

    //                 <span className='fw-bold px-5'>הקניה</span>
    //                 <span style={{ paddingRight: "74px" }}>|  7-10 דקות - הוראת חומר חדש</span>
    //                 {!showPurchaseCube ?
    //                     <EmojiComponent emojiName={"arrow_down"}></EmojiComponent> :
    //                     <EmojiComponent emojiName={"arrow_up"}></EmojiComponent>
    //                 }
    //             </button>

    //             {showPurchaseCube && purchaseCubeAr.length > 0 ? (
    //                 <CubePreview sysLessonId={systemLessonId} CubeAr={purchaseCubeAr} setPage={setPagePurchase} page={pagePurchase} doApi={doApiGetCubeByType} setAr={setPurchaseCubeAr}
    //                     total_size={sizePurchase}
    //                     type={cubeType.purchase}
    //                     cubeIds={cubeIds}
    //                     setCubeIds={setCubeIds}
    //                     setLoading={setLoading}
    //                     loading={loading}
    //                     priorityQube={filteredData[2]}
    //                 />
    //             ) : ""}
    //         </div>
    //         <div className='w-100 bgcolor1 rounded-4 mb-2'>

    //             <button className='btn ' onClick={handleToggleBodyCube}>

    //                 <span className='fw-bold px-5'>גוף שיעור</span>

    //                 <span style={{ paddingRight: "44px" }}>   |  20-25 דקות - תרגול דיפרנציאלי של ההקניה</span>
    //                 {!showBodyCubeAr ?
    //                     <EmojiComponent emojiName={"arrow_down"}></EmojiComponent> :
    //                     <EmojiComponent emojiName={"arrow_up"}></EmojiComponent>
    //                 }
    //             </button>
    //             {showBodyCubeAr && bodyCubeAr.length > 0 && (

    //                 <CubePreview sysLessonId={systemLessonId} CubeAr={bodyCubeAr} setPage={setPageBody} page={pageBody}
    //                     doApi={doApiGetCubeByType}
    //                     total_size={sizeBody}
    //                     setAr={setBodyCubeAr} type={cubeType.body}
    //                     cubeIds={cubeIds}
    //                     setCubeIds={setCubeIds}
    //                     setLoading={setLoading}
    //                     loading={loading}
    //                     priorityQube={filteredData[0]}
    //                 />


    //             )}
    //         </div>
    //         <div className='w-100 bgcolor1 rounded-4 mb-2'>

    //             <button className='btn' onClick={handleToggleSummeryCube}>
    //                 <span className='fw-bold px-5'>
    //                     סיכום
    //                 </span>
    //                 <span style={{ paddingRight: "74px" }}>
    //                     |  7-10 דקות - ווידוא הבנה
    //                 </span>
    //                 {!showSummeryCubeAr ?
    //                     <EmojiComponent emojiName={"arrow_down"}></EmojiComponent> :
    //                     <EmojiComponent emojiName={"arrow_up"}></EmojiComponent>
    //                 }
    //             </button>
    //             {showSummeryCubeAr && summeryCubeAr.length > 0 && (
    //                 <CubePreview sysLessonId={systemLessonId} CubeAr={summeryCubeAr} setPage={setPageSummery} page={pageSummery} doApi={doApiGetCubeByType} setAr={setSummeryCubeAr}
    //                     total_size={sizeSummery}
    //                     type={cubeType.summary}
    //                     cubeIds={cubeIds}
    //                     setCubeIds={setCubeIds}
    //                     setLoading={setLoading}
    //                     loading={loading}
    //                     priorityQube={filteredData[3]}

    //                 />
    //             )}
    //         </div>
    //         <div className='d-flex justify-content-start' style={{ direction: 'ltr' }} >

    //             <button className='btn  btn-primary  mt-4' style={{ width: '120px', marginLeft: '50px', height: '40px' }}
    //                 onClick={() => {
    //                     cubeIds.sort((a, b) => {
    //                         return a.Cube.type - b.Cube.type; // Assuming "type" is a numeric value
    //                     });
    //                     const userIdString = localStorage.getItem(USER); // Retrieve the value from localStorage
    //                     const currentDate = new Date();
    //                     const formattedDate = format(currentDate, 'yyyy-MM-dd');
    //                     if (userIdString) {

    //                         const body = {
    //                             // schduler_date: "2023-08-27",//TODO: should be the right schduler_date
    //                             // created_at: formattedDate,
    //                             // order: 1,//TODO: should be the right order
    //                             // user: parseInt(userIdString, 10),
    //                             intriguing_opening: cubeIds[cubeType.opening]?.Cube?.id,
    //                             purchase: cubeIds[cubeType.purchase]?.Cube?.id,
    //                             body: cubeIds[cubeType.body]?.Cube?.id,
    //                             summery: cubeIds[cubeType.summary]?.Cube?.id,
    //                             // system_lesson: systemLessonId,////TODO: should be the right system_lesson
    //                             // study_period_class: 1 ////TODO: should be the right study_period_class
    //                         }
    //                         // console.log(body); // Parse the string to an integer with base 10
    //                         if (updateClassLesson(classLessonId, body)) {
    //                             alert("data saved successfully")
    //                         } else {
    //                             alert("Error, please try again later")
    //                         }
    //                     } else {
    //                         console.log('USER value not found in localStorage');
    //                         return null;
    //                     }


    //                 }}
    //             >הפק שיעור</button>
    //             <button className='btn  btn-primary  mt-4' style={{ width: '120px', height: '40px', fontSize: '14px', margin: '0px 30px' }} onClick={() => downloadDataAsWord(filteredData)}> הורד למסמך</button>

    //         </div>



    //     </div >
    // )

    return (
        <div style={{width:"93%", margin:"auto"}}>
          <div>
          <div className="" >
            {systemLessonGolas.length > 0 &&
              systemLessonGolas.map((s, i) => (
                <div style={{ fontSize: "14px", borderRadius: "6px",
                border: "0.2px solid rgba(132, 125, 125, 1)",
                // border: "0.1px solid #847D7D",
                 boxShadow:"1px #0000001A",               
                 backgroundColor:"#FDFFF4", padding: "0px 10px", marginBottom: "5px"}}>
                  <span style={{color:"rgba(132, 125, 125, 1)"}}>
                    {"מטרה" + " " + (i + 1) + " : "} 
                    </span>
                    {s.description}
                </div>
              ))}
          </div>
          <div className="bgcolor1 rounded-4 mb-2">
            <button className="btn " onClick={handleToggleOpeningCube}>
              <span className="fw-bold">11פתיחה מסקרנת</span>
              <span> | 5-7 דקות - יצירת עניין לנושא הספציפי שילמד בשיעור</span>
              {!showOpeningCubeAr ? (
                <EmojiComponent emojiName={"arrow_down"}></EmojiComponent>
              ) : (
                <EmojiComponent emojiName={"arrow_up"}></EmojiComponent>
              )}
            </button>
            {/* {showOpeningCubeAr && openingCubeAr.length < 1 ? (<div>
             <EmptyCube></EmptyCube>
            </div>) : ("")} */}
            {showOpeningCubeAr && (
              <CubePreview
                sysLessonId={systemLessonId}
                CubeAr={openingCubeAr}
                setPage={setPageOpening}
                page={pageOpening}
                doApi={doApiGetCubeByTypeAndSysLesson}
                setAr={setOpeningCubeAr}
                type={cubeType.opening}
                total_size={sizeOpening}
                cubeIds={cubeIds}
                setCubeIds={setCubeIds}
                setLoading={setLoading}
                loading={loading}
                priorityQube={filteredData[1]}
              />
            )}
          </div>
          <div className="bgcolor1 rounded-4 mb-2 ">
            <button className="btn " onClick={handleTogglePurchaseCube}>
              <span className="fw-bold">הקניה</span>
              <span style={{ paddingRight: "74px" }}>
                | 7-10 דקות - הוראת חומר חדש
              </span>
              {!showPurchaseCube ? (
                <EmojiComponent emojiName={"arrow_down"}></EmojiComponent>
              ) : (
                <EmojiComponent emojiName={"arrow_up"}></EmojiComponent>
              )}
            </button>
            {/* {showPurchaseCube && purchaseCubeAr.length < 1 ? (<div>
             <EmptyCube></EmptyCube>
            </div>) : ("")} */}
            {showPurchaseCube ? (
              <CubePreview
                sysLessonId={systemLessonId}
                CubeAr={purchaseCubeAr}
                setPage={setPagePurchase}
                page={pagePurchase}
                doApi={doApiGetCubeByType}
                setAr={setPurchaseCubeAr}
                total_size={sizePurchase}
                type={cubeType.purchase}
                cubeIds={cubeIds}
                setCubeIds={setCubeIds}
                setLoading={setLoading}
                loading={loading}
                priorityQube={filteredData[2]}
              />
            ) : (
              ""
            )}
          </div>
          <div className="bgcolor1 rounded-4 mb-2"> 
            <button className="btn " onClick={handleToggleBodyCube}>
              <span className="fw-bold">גוף שיעור</span>
    
              <span style={{ paddingRight: "44px" }}>
                {" "}
                | 20-25 דקות - תרגול דיפרנציאלי של ההקניה
              </span>
              {!showBodyCubeAr ? (
                <EmojiComponent emojiName={"arrow_down"}></EmojiComponent>
              ) : (
                <EmojiComponent emojiName={"arrow_up"}></EmojiComponent>
              )}
            </button>
            {/* {showBodyCubeAr && bodyCubeAr.length < 1 ? (<div>
             <EmptyCube></EmptyCube>
            </div>) : ("")} */}
            {showBodyCubeAr && (
              <CubePreview
                sysLessonId={systemLessonId}
                CubeAr={bodyCubeAr}
                setPage={setPageBody}
                page={pageBody}
                doApi={doApiGetCubeByType}
                total_size={sizeBody}
                setAr={setBodyCubeAr}
                type={cubeType.body}
                cubeIds={cubeIds}
                setCubeIds={setCubeIds}
                setLoading={setLoading}
                loading={loading}
                priorityQube={filteredData[0]}
              />
            )}
          </div>
          <div className="bgcolor1 rounded-4 mb-2">
            <button className="btn" onClick={handleToggleSummeryCube}>
              <span className="fw-bold">סיכום</span>
              <span style={{ paddingRight: "74px" }}>
                | 7-10 דקות - ווידוא הבנה
              </span>
              {!showSummeryCubeAr ? (
                <EmojiComponent emojiName={"arrow_down"}></EmojiComponent>
              ) : (
                <EmojiComponent emojiName={"arrow_up"}></EmojiComponent>
              )}
            </button>
            {/* {showSummeryCubeAr && summeryCubeAr.length < 1 ? (<div>
             <EmptyCube systemLesson={systemLessonId} type={cubeType.opening}></EmptyCube>
            </div>) : ("")} */}
            {showSummeryCubeAr && (
              <CubePreview
                sysLessonId={systemLessonId}
                CubeAr={summeryCubeAr}
                setPage={setPageSummery}
                page={pageSummery}
                doApi={doApiGetCubeByType}
                setAr={setSummeryCubeAr}
                total_size={sizeSummery}
                type={cubeType.summary}
                cubeIds={cubeIds}
                setCubeIds={setCubeIds}
                setLoading={setLoading}
                loading={loading}
                priorityQube={filteredData[3]}
              />
            )}
          </div>
          <div
            className="d-flex justify-content-start"
            style={{ direction: "ltr" }}
          >
            <button
              className="btn  btn-primary  mt-4"
              disabled={cubeIds.length < 1}
              style={{ width: "120px", marginLeft: "50px", height: "40px" }}
              onClick={() => {
                cubeIds.sort((a, b) => {
                  return a.Cube.type - b.Cube.type; // Assuming "type" is a numeric value
                });
                const userIdString = localStorage.getItem(USER); // Retrieve the value from localStorage
                const currentDate = new Date();
                const formattedDate = format(currentDate, "yyyy-MM-dd");
                if (userIdString) {
                  const body = {
                    // schduler_date: "2023-08-27",//TODO: should be the right schduler_date
                    // created_at: formattedDate,
                    // order: 1,//TODO: should be the right order
                    // user: parseInt(userIdString, 10),
                    intriguing_opening: cubeIds[cubeType.opening]?.Cube?.id,
                    purchase: cubeIds[cubeType.purchase]?.Cube?.id,
                    body: cubeIds[cubeType.body]?.Cube?.id,
                    summery: cubeIds[cubeType.summary]?.Cube?.id,
                    // system_lesson: systemLessonId,////TODO: should be the right system_lesson
                    // study_period_class: 1 ////TODO: should be the right study_period_class
                  };
                  // console.log(body); // Parse the string to an integer with base 10
                  if (updateClassLesson(classLessonId, body)) {
                    alert("data saved successfully");
                  } else {
                    alert("Error, please try again later");
                  }
                } else {
                  console.log("USER value not found in localStorage");
                  return null;
                }
              }}
            >
              הפק שיעור
            </button>
            <button
              className="btn  btn-primary  mt-4"
              disabled={cubeIds.length < 1}
              style={{
                width: "120px",
                height: "40px",
                fontSize: "14px",
                margin: "30px",
              }}
              onClick={() => downloadDataAsWord(filteredData, location)}
            >
              {" "}
              הורד למסמך
            </button>
          </div>
          </div>
        </div>
      );
}


export default CreateLesson;
