

import { useState } from 'react';

import { BASE_URL, makeRequest } from '../api_service/api_func';

const useTasks = () => {
  const [tasks, setTasks] = useState([]);
  // const [is_done, setIs_done] = useState(true);



  const fetchTasks = async ({ classLesson, userId }) => {
    try {

      let url = `${BASE_URL}/task`;
      if (classLesson) {
        url += `/${classLesson}`;
      } else if (userId) {
        url += `/user/${userId}`;
      }
      const fetchedTasks = await makeRequest('GET', url);
      console.log('\n\n\n fetchedTasks', fetchedTasks, '\n\n\n ');

      setTasks(fetchedTasks);
    } catch (error) {
      console.error("Failed to create task:", error);
    }
  };

  // const createTask = async (task) => {
  //   try {
  //     const newTask = await makeRequest('POST', `${BASE_URL}/task`, {  "task": task });
  //     setTasks(prevTasks => [...prevTasks, newTask]);
  //     console.log("Task created successfully!");
  //   } catch (error) {
  //     console.error("Failed to create task:", error);
  //   }
  // };
  const createTask = async (task, user, classLesson) => {
    try {
      console.log('\n\n\n user3', user, '\n\n\n ');
      console.log('\n\n\n classLesson3', classLesson, '\n\n\n ');



      const newTask = await makeRequest('POST', `${BASE_URL}/task`,
        {
          "task": task,
          "user": user,
          "is_done": false,
          "classLesson": classLesson,
        }
      );
      setTasks(prevTasks => [...prevTasks, newTask]);
      console.log("Task created successfully!");
    } catch (error) {
      console.error("Failed to create task:", error);
    }
  };

  // const updateTask = async (id,   {
  //   "task": task,
  //   "user": 5,
  //   "is_done": done,
  // }) => {
  //   console.log("updateTask");
  //   makeRequest(`${BASE_URL}/tasks/${id}`, { "task": task }, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   })
  //     .then(response => setTasks(prevTodos => prevTodos.map(todo => todo.id === id ? response.data : todo)))
  //     .catch(error => {
  //       console.error('An error occurred while adding the todo:', error.message);
  //     });;
  // };

  const updateTask = async (id, task, isDone) => {
    try {
      const response = await makeRequest('PUT', `${BASE_URL}/task/${id}`, {
        task,
        is_done: isDone,
      });
      setTasks(prevTasks => prevTasks.map(todo => todo.id === id ? { ...todo, task, is_done: isDone } : todo));
    } catch (error) {
      console.error('An error occurred while updating the task:', error.message);
    }
  };



  const deleteTask = async (id) => {
    makeRequest(`${BASE_URL}/task/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(() => setTasks(prevTodos => prevTodos.filter(todo => todo.id !== id)))
      .catch(error => console.error('There was an error!', error));
  };

  return {
    // fetchNextTasks,
    tasks,
    setTasks,
    fetchTasks,
    createTask,
    updateTask,
    deleteTask,
  };
};

export default useTasks;
